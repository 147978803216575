import { useState, useCallback, useEffect, useMemo, useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api, messages, webDB } from 'server';
import { Message, GlobalEvent, MessageType, MessageUser, MessageState } from 'models';
import store from 'store';
import { NotificationCenter, Calculator } from 'utils';
import { Loading } from 'components';
import { analysisLogEvent, AnalysisEventName } from 'analysis';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

const duplicateMessages = (value: Message[]) => {
    // 去重
    var newValue: Message[] = []
    value.forEach((item) => {
        if (newValue.findIndex((newItem) => newItem.msg_id === item.msg_id) < 0) {
            newValue.push(item)
        }
    })
    return newValue
}

const resortMessages = (value: Message[]) => {
    var newValue: Message[] = [...value]
    // 时间排序
    newValue.sort((item1, item2) => {
        if (item1.sent_at && item2.sent_at && item1.sent_at < item2.sent_at) {
            return -1
        }
        return 1
    })
    // 修改时间
    let lastMessageAt = 0
    for (var i = 0; i < newValue.length; i++) {
        let item = newValue[i]
        if (item && item.sent_at && item.sent_at - lastMessageAt > 5 * 60) {
            lastMessageAt = item.sent_at
        } else {
            let obj = { ...newValue[i] }
            obj.sent_at = undefined
            newValue[i] = obj
        }
    }
    return newValue
}

function useDirect() {
    const params = useParams()
    const [user, setUser] = useState<MessageUser | undefined>(undefined)
    const [dataSource, setDataSource] = useState<Message[]>([])
    const [text, setText] = useState<string>('')
    const list = useMemo(() => { return resortMessages(dataSource) }, [dataSource])
    const fileRef = useRef(null)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        messages.directPeerId = Number(params.id)
        return () => {
            messages.directPeerId = 0
        }
    }, [params])

    // 初始化
    useLayoutEffect(() => {
        const id = (store.getState().account.value as any).id
        if (params.id && !Number.isNaN(params.id)) {
            webDB.queryUser(Number(params.id)).then((user) => {
                setUser(user)
            })
            // 从数据库中获取消息
            const getMessages = async () => {
                try {
                    let receivedMessage = await webDB.queryMessageByAssociationId(Number(params.id), id)
                    let sentMessages = await webDB.queryMessageByAssociationId(id, Number(params.id))
                    let newDataSource = duplicateMessages([...receivedMessage, ...sentMessages])
                    setDataSource(newDataSource)
                    setTimeout(() => {
                        document.documentElement.scrollTop = document.body.scrollHeight
                        setLoading(false)
                    }, 100)
                } catch {
                    setLoading(false)
                }
            }
            getMessages()
        }
    }, [params.id, setDataSource])

    // 监听新增消息
    useLayoutEffect(() => {
        const listener = (value: any) => {
            let oldMessages = dataSource.slice()
            let insertMessages = (value as any).detail as [Message]
            let newMessages = duplicateMessages([...oldMessages, ...insertMessages])
            setDataSource(newMessages)
            setTimeout(() => {
                if (document.body.scrollHeight - document.documentElement.scrollTop - document.body.offsetHeight <= 0) {
                    document.documentElement.scrollTop = document.body.scrollHeight
                }
            })
        }
        NotificationCenter.addObserver(GlobalEvent.messageDidUpdate, listener)
        return (() => {
            NotificationCenter.removeObserver(GlobalEvent.messageDidUpdate, listener)
        })
    }, [dataSource, setDataSource])

    // 监听删除消息
    useLayoutEffect(() => {
        const listener = (value: any) => {
            let deleteMessages = (value as any).detail as [Message]
            let newMessages = dataSource.slice()
            newMessages = newMessages.filter((item) => {
                return deleteMessages.findIndex((deleteItem) => deleteItem.msg_id === item.msg_id) < 0
            })
            setDataSource(newMessages)
        }
        NotificationCenter.addObserver(GlobalEvent.messageDidDelete, listener)
        return (() => {
            NotificationCenter.removeObserver(GlobalEvent.messageDidDelete, listener)
        })
    }, [dataSource, setDataSource])

    // 点击发送文本消息
    const handleSendTextMessage = useCallback(async (value?: string) => {
        if (!text && !value) {
            return
        }
        let textValue = value ?? text
        analysisLogEvent(AnalysisEventName.message_text_send)
        try {
            let message: Message = {
                msg_id: uuidv4(),
                sender_id: Calculator.userId,
                sent_at: Math.ceil(new Date().getTime() / 1000),
                to: Number(params.id),
                content: {
                    uuid: uuidv4(),
                    type: MessageType.text,
                    text: textValue
                }
            }
            messages.sendMessage(message, () => {
                setTimeout(() => {
                    document.documentElement.scrollTop = document.body.scrollHeight
                })
            })
        } catch { }
        setText("")
    }, [params, text, setText])

    const handleReSendMessage = useCallback(async (item: Message) => {
        let message = await webDB.queryMessage(item.msg_id)
        messages.sendMessage(message)
    }, [])

    const handleSendImageMessage = useCallback(async () => {
        analysisLogEvent(AnalysisEventName.message_image_send)
        let inp = document.getElementById("image-file") as HTMLInputElement
        if (inp && inp.files !== null && inp.files.length > 0) {
            let loading = Loading.show()
            try {
                // 上传到OSS
                let uploadResponse = await api.upload({
                    value: inp.files[0],
                    fileName: uuidv4()
                }) as { "url": string }
                const url = uploadResponse["url"]
                Loading.dismiss(loading)
                inp.value = ""
                // 发送消息
                let message: Message = {
                    msg_id: uuidv4(),
                    sender_id: Calculator.userId,
                    sent_at: Math.ceil(new Date().getTime() / 1000),
                    to: Number(params.id),
                    content: {
                        uuid: uuidv4(),
                        type: MessageType.image,
                        url: url
                    }
                }
                messages.sendMessage(message, () => {
                    setTimeout(() => {
                        document.documentElement.scrollTop = document.body.scrollHeight
                    })
                })
            } catch {
                inp.value = ""
                Loading.dismiss(loading)
            }
        }
    }, [params.id])


    const handleTranslate = useCallback(async (item: Message) => {
        let newSource = [...dataSource]
        let findIndex = dataSource.findIndex((arg0) => arg0.msg_id === item.msg_id)
        if (findIndex >= 0) {
            if (newSource[findIndex].content?.original_text) {
                newSource[findIndex].state = MessageState.success
                newSource[findIndex].content!.text = newSource[findIndex].content?.original_text
                newSource[findIndex].content!.original_text = undefined
                setDataSource(newSource)
            } else {
                newSource[findIndex].state = MessageState.translating
                setDataSource(newSource)
                try {
                    let response = await api.translate({
                        q: item.content?.text ?? "",
                        target: new Intl.Locale(navigator.language).language
                    })
                    let text = response.r
                    let newSource = [...dataSource]
                    let findIndex = dataSource.findIndex((arg0) => arg0.msg_id === item.msg_id)
                    if (findIndex >= 0) {
                        newSource[findIndex].state = MessageState.success
                        newSource[findIndex].content!.original_text = newSource[findIndex].content!.text
                        newSource[findIndex].content!.text = text
                    }
                    setDataSource(newSource)
                } catch {
                    let newSource = [...dataSource]
                    let findIndex = dataSource.findIndex((arg0) => arg0.msg_id === item.msg_id)
                    if (findIndex >= 0) {
                        newSource[findIndex].state = MessageState.success
                    }
                    setDataSource(newSource)
                }
            }
        }

    }, [dataSource, setDataSource])

    return { user, list, text, setText, handleSendTextMessage, handleReSendMessage, handleSendImageMessage, fileRef, loading, handleTranslate }
}

export default useDirect