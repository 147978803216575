import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import './Action.scss';

const ActionAlert = React.memo((props: {
    title?: string;
    message?: string;
    visible?: boolean;
    actions?: { title: string, handler: () => void, style?: CSSProperties }[]
    onClose?: () => void;
}) => {
    const { title, message, actions, visible, onClose } = props
    return (
        <div className={classNames('action-alert-components', !visible && 'hide')} >
            <div className='mask' onClick={() => onClose && onClose()}></div>
            <div className='content-view'>
                <div className='title'>{title}</div>
                <div className='message'>{message}</div>
                <div className='action-wrapper'>
                    {
                        actions?.map((item) => {
                            return (
                                <div key={item.title} style={item.style} className='action-button touch-soft-highlight' onClick={() => item.handler()}>{item.title}</div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
})

export default ActionAlert