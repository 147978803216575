import { MessageUser, Message } from 'models'

const webDB: {
    db?: IDBDatabase | undefined,
    name?: string | undefined,
    open: (name: string, version: number) => Promise<void>,
    deleteDB: (name: string) => void,
    insertUser: (user: MessageUser) => Promise<void>,
    insertUsers: (users: MessageUser[]) => Promise<void>,
    queryUser: (id: number) => Promise<MessageUser>,
    queryUsers: (ids: number[]) => Promise<MessageUser[]>,
    queryUsersAll: () => Promise<MessageUser[]>
    insertMessage: (message: Message) => Promise<void>,
    insertMessages: (messages: Message[]) => Promise<void>,
    queryMessage: (id: string) => Promise<Message>,
    queryMessageByAssociationId: (sender_id: number, to: number) => Promise<Message[]>,
    deleteMessage: (id: string) => Promise<void>,
    deleteMessages: (ids: string[]) => Promise<void>,
} = {
    open: (name: string, version: number) => {
        return new Promise<void>((resolve, reject) => {
            if (webDB.name === name) {
                reject()
            } else {
                var req = indexedDB.open(name, version)
                req.onsuccess = function (e) {
                    webDB.db = (e.target as any).result as IDBDatabase
                    webDB.name = name
                    resolve()
                }
                req.onerror = function () {
                    reject()
                }
                req.onupgradeneeded = function (e) {
                    webDB.db = (e.target as any).result as IDBDatabase
                    // 创建表
                    if (!webDB.db.objectStoreNames.contains('user')) {
                        // 不存在user表，则创建user表，主键为id
                        webDB.db.createObjectStore("user", { keyPath: "id" });
                    }
                    if (!webDB.db.objectStoreNames.contains('messages')) {
                        // 不存在messages表，则创建messages表，主键为msg_id
                        let store = webDB.db.createObjectStore("messages", { keyPath: "msg_id" });
                        store.createIndex('associationId', ['sender_id', 'to'], { unique: false })
                    }
                }
            }
        })
    },
    deleteDB: (name: string) => {
        indexedDB.deleteDatabase(name)
    },
    /// 写入用户数据
    insertUser: (user: MessageUser) => {
        return new Promise<void>(async (resolve, reject) => {
            let addHandler = () => {
                var req = webDB.db?.transaction(['user'], 'readwrite').objectStore('user').add(user)
                if (req) {
                    req.onsuccess = function () {
                        resolve()
                    }
                    req.onerror = function () {
                        reject()
                    }
                } else {
                    reject()
                }
            }
            let putHandler = () => {
                var req = webDB.db?.transaction(['user'], 'readwrite').objectStore('user').put(user)
                if (req) {
                    req.onsuccess = function () {
                        resolve()
                    }
                    req.onerror = function () {
                        reject()
                    }
                } else {
                    reject()
                }
            }
            try {
                let result = await webDB.queryUser(user.id)
                if (result) {
                    putHandler()
                } else {
                    addHandler()
                }
            } catch {
                addHandler()
            }
        })
    },
    /// 批量用户数据
    insertUsers: (users: MessageUser[]) => {
        return new Promise<void>((reslove) => {
            let index = 0
            users.forEach(async (item) => {
                webDB.insertUser(item).then(() => {
                    index += 1
                    if (index === users.length - 1) {
                        reslove()
                    }
                }).catch(() => {
                    index += 1
                    if (index === users.length - 1) {
                        reslove()
                    }
                })
            })
            reslove()
        })
    },
    /// 查询用户数据
    queryUser: (id: number) => {
        return new Promise<MessageUser>((reslove, reject) => {
            var req = webDB.db?.transaction(['user'], 'readwrite')
                .objectStore('user')
                .get(id)
            if (req) {
                req.onsuccess = function (res) {
                    let user = (res.target as any).result as MessageUser
                    if (user) {
                        reslove(user)
                    } else {
                        reject()
                    }
                }
                req.onerror = function () {
                    reject()
                }
            } else {
                reject()
            }
        })
    },
    /// 批量查询用户数据
    queryUsers: async (ids: number[]) => {
        return new Promise<MessageUser[]>((reslove) => {
            let users: MessageUser[] = []
            let index = 0
            if (ids.length > 0) {
                ids.forEach(async (item) => {
                    webDB.queryUser(item).then((item) => {
                        users.push(item)
                        index += 1
                        if (index > ids.length - 1) {
                            reslove(users)
                        }
                    }).catch(() => {
                        index += 1
                        if (index > ids.length - 1) {
                            reslove(users)
                        }
                    })
                })
            } else {
                reslove([])
            }
        })
    },
    /// 批量查询用户数据
    queryUsersAll: async () => {
        return new Promise<MessageUser[]>((reslove, reject) => {
            var req = webDB.db?.transaction(['user'], 'readwrite')
                .objectStore('user')
                .getAll()
            if (req) {
                req.onsuccess = function (res) {
                    let user = (res.target as any).result as MessageUser[]
                    if (user) {
                        reslove(user)
                    } else {
                        reject()
                    }
                }
                req.onerror = function () {
                    reject()
                }
            } else {
                reject()
            }
        })
    },
    insertMessage: (message: Message) => {
        return new Promise<void>(async (resolve, reject) => {
            let addHandler = () => {
                var req = webDB.db?.transaction(['messages'], 'readwrite').objectStore('messages').add(message)
                if (req) {
                    req.onsuccess = function () {
                        resolve()
                    }
                    req.onerror = function () {
                        reject()
                    }
                } else {
                    reject()
                }
            }
            let putHandler = () => {
                var req = webDB.db?.transaction(['messages'], 'readwrite').objectStore('messages').put(message)
                if (req) {
                    req.onsuccess = function () {
                        resolve()
                    }
                    req.onerror = function () {
                        reject()
                    }
                } else {
                    reject()
                }
            }
            try {
                let result = await webDB.queryMessage(message.msg_id)
                if (result) {
                    putHandler()
                } else {
                    addHandler()
                }
            } catch {
                addHandler()
            }
        })
    },
    insertMessages: (messages: Message[]) => {
        return new Promise<void>((reslove) => {
            let index = 0
            messages.forEach(async (item) => {
                webDB.insertMessage(item).then(() => {
                    index += 1
                    if (index >= messages.length) {
                        reslove()
                    }
                }).catch(() => {
                    index += 1
                    if (index >= messages.length) {
                        reslove()
                    }
                })
            })
            reslove()
        })
    },
    queryMessage: (id: string) => {
        return new Promise<Message>((reslove, reject) => {
            var req = webDB.db?.transaction(['messages'], 'readwrite')
                .objectStore('messages')
                .get(id)
            if (req) {
                req.onsuccess = function (res) {
                    let user = (res.target as any).result as Message
                    if (user) {
                        reslove(user)
                    } else {
                        reject()
                    }
                }
                req.onerror = function () {
                    reject()
                }
            } else {
                reject()
            }
        })
    },
    queryMessageByAssociationId: (sender_id: number, to: number) => {
        return new Promise<Message[]>((reslove, reject) => {
            var req = webDB.db?.transaction(['messages'], 'readwrite')
                .objectStore('messages')
                .index('associationId')
                .openCursor(IDBKeyRange.only([sender_id, to]))
            if (req) {
                const list: Message[] = []
                req.onsuccess = function (res) {
                    let result = (res.target as any).result
                    if (result) {
                        list.push(result.value as Message)
                        result.continue()
                    } else {
                        reslove(list)
                    }
                }
                req.onerror = function () {
                    reject()
                }
            } else {
                reject()
            }
        })
    },
    deleteMessage: (id: string) => {
        return new Promise<void>((reslove, reject) => {
            var req = webDB.db?.transaction(['messages'], 'readwrite')
                .objectStore('messages')
                .delete(id)
            if (req) {
                req.onsuccess = function () {
                    reslove()
                }
                req.onerror = function () {
                    reject()
                }
            } else {
                reject()
            }
        })
    },
    deleteMessages: (ids: string[]) => {
        return new Promise<void>((reslove) => {
            let index = 0
            ids.forEach(async (item) => {
                webDB.deleteMessage(item).then(() => {
                    index += 1
                    if (index >= ids.length) {
                        reslove()
                    }
                }).catch(() => {
                    index += 1
                    if (index >= ids.length) {
                        reslove()
                    }
                })
            })
        })
    },
}

export default webDB
