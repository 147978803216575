import { createSlice } from '@reduxjs/toolkit';
import { CustomRtmMessage } from 'models';

const initialState: {
    value: any,
    publishedDate?: Date,
    muted?: boolean,
    remoteMuted?: boolean,
    messages: CustomRtmMessage[],
    notifyRecharge?: {
        expireDate: Date,
        recharge: any,
    }
} = {
    value: null,
    publishedDate: undefined,
    muted: undefined,
    remoteMuted: undefined,
    messages: [],
    notifyRecharge: undefined
}

// Slice
const slice = createSlice({
    name: 'conversation',
    initialState: initialState,
    reducers: {
        updateConversation: (state, action) => {
            state.value = { ...action.payload };
        },
        clearConversation: (state) => {
            state.value = null;
            state.publishedDate = undefined;
            state.muted = undefined
            state.remoteMuted = undefined
            state.messages = []
            state.notifyRecharge = undefined
        },
        updateConversationPublishedDate: (state, action) => {
            state.publishedDate = action.payload;
        },
        updateConversationMuted: (state, action) => {
            state.muted = action.payload;
        },
        updateConversationRemoteMuted: (state, action) => {
            state.remoteMuted = action.payload;
        },
        addConversationMessage: (state, action) => {
            state.messages = [...state.messages, action.payload]
        },
        replaceConversationMessage: (state, action) => {
            state.messages = action.payload
        },
        updateConversationNotifyRecharge: (state, action) => {
            state.notifyRecharge = action.payload;
        },
    },
});

export default slice.reducer

export const updateConversation = (payload: any) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateConversation(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const clearConversation = () => async (dispatch: (arg0: { payload: any; type: string; }) => any) => {
    try {
        return dispatch(slice.actions.clearConversation())
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateConversationPublishedDate = (payload: any) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateConversationPublishedDate(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateConversationMuted = (payload: any) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateConversationMuted(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateConversationRemoteMuted = (payload: any) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateConversationRemoteMuted(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const addConversationMessage = (payload: CustomRtmMessage) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.addConversationMessage(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const replaceConversationMessage = (payload: CustomRtmMessage[]) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.replaceConversationMessage(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateConversationNotifyRecharge = (payload: any) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateConversationNotifyRecharge(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}
