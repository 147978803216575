import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Header } from 'components';
import { useEdit } from 'hooks';
import '../EditPage.scss';

const EditNicknamePage = React.memo(() => {
    const { t } = useTranslation()
    const { handleSave } = useEdit()
    const [nickname, setNickname] = useState("");
    const account = useSelector(state => (state as any).account).value
    useEffect(() => {
        setNickname(account.profile.nickname)
    }, [account])
    return (
        <div className='edit-other-page'>
            <Header title={t('personal.nickname')}><button className='save-button touch-opacity' onClick={() => handleSave({ nickname: nickname })}>{t('common.save')}</button></Header>
            <div className='text-wrapper'>
                <input className='input' placeholder={t('edit.personal.nickname.placeholder')} value={nickname} onChange={(e) => setNickname(e.target.value)} autoComplete="true"></input>
            </div>
        </div>
    );
})

export default EditNicknamePage;