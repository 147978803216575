
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import classNames from 'classnames';
import { Header, Spinner, select } from 'components';
import { useSignup } from 'hooks';
import { Gender, PagePath } from 'models';
import './SignupPage.scss';

const SignupPage = React.memo(() => {
  const { t } = useTranslation()
  const { account, step, setStep, email, setEmail, password, setPassword, nickname, setNickname, gender, setGender, age, setAge, avatar, emailLoading, isEmailStepEnabled, handleVerifyEmail, handleAddAvatar, handleSignup } = useSignup()
  const genderOptions = [
    { value: Gender.male, label: t('personal.gender-male') },
    { value: Gender.female, label: t('personal.gender-female') }
  ]
  const ageOptions = select.ageOptions
  return (
    <div className='signup-page'>
      <Header title={t('signup.title')}></Header>
      {/* 步骤1: 邮箱密码 */}
      {step === 0 &&
        <div className='step'>
          <div className='index-title'>{t('signup.step.email-index-title')}</div>
          <div className='title'>{t('signup.step.email-title')}</div>
          <form className='signup-form'>
            <div className='input-wrapper email'>
              <input className='input with-icon' placeholder={t('signup.step.email-placholder')} value={email} onChange={(value) => setEmail(value.target.value)}></input>
              <div className='icon email'></div>
            </div>
            <div className='input-wrapper password'>
              <input className='input with-icon' placeholder={t('signup.step.password-placholder')} type='password' value={password} onChange={(value) => setPassword(value.target.value)} autoComplete="true"></input>
              <div className='icon password'></div>
            </div>
          </form>
          <button className={classNames('signup-button', 'touch-highlight', emailLoading && 'disabled', !isEmailStepEnabled && 'disabled-opacity')} onClick={() => handleVerifyEmail()}>
            {emailLoading ? <Spinner color="var(--system-white)" /> : t('signup.step.next-button')}
          </button>
          {!account && <div className='toggle-label'>{t('signup.toggle-description')} <Link to={PagePath.accounts_login} className='underline touch-opacity' replace>{t('signup.toggle-login')}</Link></div>}
        </div>
      }
      {/* 步骤2: 昵称 */}
      {step === 1 &&
        <div className='step'>
          <div className='index-title'>{t('signup.step.nickname-index-title')}</div>
          <div className='title'>{t('signup.step.nickname-title')}</div>
          <form className='signup-form'>
            <div className='input-wrapper'>
              <input className='input' placeholder={t('signup.step.nickname-placholder')} value={nickname} onChange={(value) => setNickname(value.target.value)}></input>
            </div>
          </form>
          <div className='select-warpper'>
            <Select
              styles={select.styleConfig}
              className='select-wrapper'
              value={genderOptions.find((item) => { return item.value === gender }) ?? genderOptions[0]}
              options={genderOptions}
              onChange={(value) => {
                let obj = value as any
                let gender = obj.value as Gender
                setGender(gender)
              }}
            />
            <Select
              styles={select.styleConfig}
              className='select-wrapper'
              value={ageOptions.find((item) => { return item.value === age }) ?? ageOptions[0]}
              options={ageOptions}
              onChange={(value) => {
                let obj = value as any
                let age = obj.value as number
                setAge(age)
              }}
            />
          </div>
          <button className={classNames('signup-button', 'touch-highlight', nickname.length <= 0 && 'disabled-opacity')} onClick={() => setStep(2)}>{t('signup.step.next-button')}</button>
        </div>
      }
      {/* 步骤3: 头像 */}
      {step === 2 &&
        <div className='step'>
          <div className='index-title'>{t('signup.step.avatar-index-title')}</div>
          <div className='title'>{t('signup.step.avatar-title')}</div>
          <div className='avatar-button'>
            <input type="file" name="file" id="avatar-file" accept="image/*" className='picker' onChange={() => handleAddAvatar()} />
            {avatar ?
              <img className='selected-avatar' src={avatar} alt="" />
              :
              <div className='default-avatar'></div>
            }
            <div className='avatar-camera'></div>
          </div>
          <button className={classNames('signup-button', 'touch-highlight')} onClick={() => handleSignup()}>{t('signup.step.next-button')}</button>
        </div>
      }
    </div>
  );
})

export default SignupPage;