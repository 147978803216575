import React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Avatar, List } from 'components';
import { api } from 'server';
import { PagePath, Viewer } from 'models';
import { Calculator } from 'utils';
import '../HistoryPage.scss';

const ViewHistoryPage = React.memo(() => {
    const onFetch = (page: number, pageSize: number, startFetch: (data: Viewer[]) => void, abortFetch: (error?: Error) => void) => {
        api.userViews({
            per_page: pageSize,
            page: page
        }).then((items) => {
            startFetch(items)
        }).catch(() => {
            abortFetch()
        })
    }

    return (
        <List
            className='list'
            onFetch={onFetch}
            renderItem={(item, index) => <ViewHistoryListCellItem key={index} item={item} ></ViewHistoryListCellItem>}
        ></List>
    );
})

const ViewHistoryListCellItem = React.memo((props: { item: Viewer }) => {
    const { item } = props
    const navigate = useNavigate()
    return (
        <div className='history-list-cell-item'>
            <div className='touch touch-soft-highlight' onClick={() => navigate(PagePath.anchor_detail + `/${item.id}`)}></div>
            <Avatar className='avatar' src={item.profile?.head}></Avatar>
            <div className='main-wrapper'>
                <p className='nickname'>{item.profile.nickname}</p>
                <div className='time'>
                    <div className='view-icon'></div>
                    <p className='time-label'>{dayjs((item.last_viewed_at ?? 0) * 1000).format(Calculator.defaultDateFormat)}</p>
                </div>
            </div>
        </div>
    )
})

export default ViewHistoryPage;