import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import classNames from 'classnames';
import Spinner from '../spinner/Spinner';
import { Log } from '../../utils';
import './List.scss';
// @ts-ignore
import PullToRefresh from 'pulltorefreshjs';

interface Props<Item> {
    className?: string;
    pageSize?: number;
    autoFetch?: boolean;
    headerComponent?: () => JSX.Element;
    footerComponent?: () => JSX.Element;
    emptyComponent?: () => JSX.Element;
    renderItem: (item: Item, index: number) => JSX.Element;
    onFetch: (page: number, pageSize: number, startFetch: (data: Item[]) => void, abortFetch: (error?: Error) => void) => void;
}

interface State<Item> {
    dataSource: Item[];
    pageState: "loading" | "error" | "clear" | 'empty',
    loadState: "loading" | "noMoreData" | "clear"
}

class List<Item = any> extends React.PureComponent<Props<Item>, State<Item>> {

    state: State<Item> = {
        dataSource: [],
        pageState: 'clear',
        loadState: 'clear'
    };
    page: number = 1;
    get pageSize(): number {
        if (this.props.pageSize) {
            return this.props.pageSize
        }
        return 30
    };
    isLoadMoreEnabled: boolean = true;

    componentDidMount() {
        if (this.props.autoFetch !== false) {
            try { this.handleInitFetch({ loading: true }) } catch { }
        }
        window.addEventListener("scroll", this.handleOnScroll)
        PullToRefresh.init({
            mainElement: '.list-component',
            onRefresh: (async () => {
                try {
                    await this.handleInitFetch({ loading: false })
                } catch { }
            }),
            instructionsPullToRefresh: i18n.t('common.list.pull'),
            instructionsReleaseToRefresh: i18n.t('common.list.release'),
            instructionsRefreshing: i18n.t('common.list.refreshing')
        });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleOnScroll)
        PullToRefresh.destroyAll();
    }

    // 滚动监听
    handleOnScroll = (e: any) => {
        if (e.target.body.scrollHeight - document.documentElement.scrollTop - e.target.body.offsetHeight <= 100 && this.isLoadMoreEnabled && this.state.pageState !== 'loading' && this.state.loadState !== 'noMoreData') {
            this.handleLoadMoreFetch()
        }
    }

    // 首次加载
    handleInitFetch = (props?: { loading: boolean }) => {
        return new Promise<void>((resolve, reject) => {
            if (props?.loading !== false) {
                this.setState({ pageState: 'loading' })
            }
            this.props.onFetch(1, this.pageSize, (data) => {
                this.page = 1
                if (data.length === 0) {
                    this.setState({
                        pageState: 'empty',
                        loadState: 'noMoreData',
                        dataSource: data
                    }, () => {
                        resolve()
                    })
                } else {
                    this.setState({
                        pageState: 'clear',
                        loadState: data.length < this.pageSize ? 'noMoreData' : 'clear',
                        dataSource: data
                    }, () => {
                        resolve()
                    })
                }
            }, () => {
                this.setState({
                    pageState: 'error',
                    loadState: 'clear'
                }, () => {
                    reject()
                })
            })
        })
    }

    // 加载更多
    handleLoadMoreFetch = () => {
        Log.print("触发加载更多...")
        this.isLoadMoreEnabled = false
        this.setState({ loadState: 'loading' }, () => {
            this.props.onFetch(this.page + 1, this.pageSize, (data) => {
                if (data.length < this.pageSize) {
                    this.setState({
                        loadState: 'noMoreData',
                        dataSource: [...this.state.dataSource, ...data]
                    }, () => {
                        this.isLoadMoreEnabled = true
                    })
                } else {
                    this.setState({
                        loadState: 'clear',
                        dataSource: [...this.state.dataSource, ...data]
                    }, () => {
                        this.isLoadMoreEnabled = true
                    })
                }
                this.page += 1
            }, () => {
                this.setState({
                    loadState: 'clear',
                }, () => {
                    this.isLoadMoreEnabled = true
                })
            })
        })
    }

    render() {
        const { className, renderItem, headerComponent, footerComponent, emptyComponent } = this.props;
        const { dataSource, pageState, loadState } = this.state;
        return (
            <div className={classNames("list-component", className)}>
                {
                    pageState === 'clear'
                        ?
                        <>
                            {headerComponent && headerComponent()}
                            {
                                Array.isArray(dataSource) && dataSource.map((item, index) => {
                                    return renderItem(item, index)
                                })
                            }
                            {footerComponent ? footerComponent() : <ListFooterView loadState={loadState} />}
                        </>
                        :
                        (pageState === 'loading' ? <ListLoadingView /> : (pageState === 'error' ? <ListErrorView retry={() => {
                            this.handleInitFetch({ loading: true })
                        }} /> : emptyComponent ? emptyComponent() : <ListEmptyView />))
                }
            </div>
        );
    }
}

export const ListLoadingView = React.memo(() => {
    return (
        <div className='list-loading-view'>
            <Spinner></Spinner>
        </div>
    )
})

export const ListErrorView = React.memo((props: { retry?: () => void }) => {
    const { t } = useTranslation()
    return (
        <div className='list-error-view'>
            <div className='error-icon'></div>
            <p className='error-title'>{t('server.error-empty')}</p>
            <p className='error-message'>{t('server.error-empty-message')}</p>
            {props.retry && <div className='error-retry touch-opacity' onClick={() => {
                props.retry && props.retry()
            }}>
                <div className='error-retry-icon'></div>
                <div>{t('common.retry')}</div>
            </div>}
        </div>
    )
})

export const ListEmptyView = React.memo(() => {
    const { t } = useTranslation()
    return (
        <div className='list-empty-view'>
            <div className='empty-icon'></div>
            <p className='empty-title'>{t('server.list-empty')}</p>
            <p className='empty-message'>{t('server.list-empty-message')}</p>
        </div>
    )
})

export const ListFooterView = React.memo((props: { loadState: "loading" | "noMoreData" | "clear" }) => {
    const { loadState } = props;
    const { t } = useTranslation()
    return (
        <div className='list-footer-view'>
            {
                loadState === 'loading'
                    ?
                    <Spinner></Spinner>
                    :
                    (
                        loadState === 'noMoreData'
                            ?
                            <div className='no-more-data'>{t('server.no-more-data')}</div>
                            :
                            null
                    )
            }
        </div>
    )
})

export default List;