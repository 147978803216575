import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Recharge, NativeMessageType } from 'models';
import './RechargeModal.scss';
import { LiveWebAdapter } from 'utils';
import { analysisLogEvent, AnalysisEventName } from 'analysis';
import { updateUpdateVisible } from 'store/app';
import store from 'store'
import config from 'config';

const RechargeModal = React.memo((props: {
    visible?: boolean;
    onClose?: () => void;
}) => {
    const { t } = useTranslation()
    const { visible, onClose } = props
    const account = useSelector(state => (state as any).account).value
    const list = useSelector(state => (state as any).rechargeList).value as Recharge[]
    const [show, setShow] = React.useState<undefined | boolean>(false)

    React.useLayoutEffect(() => {
        setTimeout(() => {
            setShow(visible)
        })
        if (visible) {
            analysisLogEvent(AnalysisEventName.recharge_modal_enter)
        }
    }, [visible, setShow])

    const close = React.useCallback(() => {
        setShow(false)
        setTimeout(() => {
            typeof onClose === 'function' && onClose()
        }, 250)
    }, [onClose])

    if (!visible) {
        return null
    }

    return (
        <div className={classNames('recharge-modal', !show && 'hide')}>
            <div className='mask' onClick={() => close()}></div>
            <div className='content-view'>
                <div className='content-header'>
                    <div className='title'>{t('recharge.title')}</div>
                    <div className='header-token-view'>
                        <div className='token-icon'></div>
                        <div className='token-label'>{account && account.balance ? account.balance : 0}</div>
                    </div>
                </div>
                <div className='list'>
                    {
                        list.map((item) => {
                            return (
                                <RechargeItemView item={item} key={item.code} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
})

const RechargeItemView = React.memo((props: {
    item: Recharge
}) => {
    const { item } = props;
    const { t } = useTranslation()
    let level = React.useMemo(() => {
        if (item.token_amount) {
            if (item.token_amount >= 14000) {
                return 7
            }
            if (item.token_amount >= 6900) {
                return 6
            }
            if (item.token_amount >= 3700) {
                return 5
            }
            if (item.token_amount >= 2800) {
                return 4
            }
            if (item.token_amount >= 1200) {
                return 3
            }
            if (item.token_amount >= 600) {
                return 2
            }
        }
        return 1
    }, [item])

    let extraToken = React.useMemo(() => {
        let original_token_amount = Number(item.original_token_amount)
        let token_amount = Number(item.token_amount)
        if (original_token_amount && token_amount) {
            return token_amount - original_token_amount
        }
        return 0
    }, [item])

    return (
        <div className='recharge-item-view touch-without-feedback'
            onClick={() => {
                analysisLogEvent(AnalysisEventName.recharge_modal_item_click)
                if (config.needUpdate === true) {
                    updateUpdateVisible(true)(store.dispatch)
                } else {
                    LiveWebAdapter.postMessage({
                        type: NativeMessageType.recharge,
                        content: item
                    })
                }
            }}
        >
            <div className='content'>
                <div className={classNames('token-icon', `level-${level}`)}></div>
                <div className='token-label'>{(item.token_amount ?? 0) - extraToken}</div>
                <div className='price'>${item.price}</div>
                {extraToken && extraToken > 0 ? <div className='extra-token'>{t('recharge.extra')} {extraToken}</div> : <></>}
            </div>

        </div>
    )
})

export default RechargeModal