import { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import { call, api, messages } from 'server';
import { PagePath, User, NetworkError, Rank } from 'models';
import { Loading, Toast } from 'components';
import { analysisLogEvent, AnalysisEventName } from 'analysis';

function useDetail() {
    const params = useParams()
    const navigate = useNavigate()
    const [user, setUser] = useState<User | undefined>(undefined)
    const [pageState, setPageState] = useState<"loading" | "error" | "clear">("loading")
    const [videos, setVideos] = useState<any[]>([])
    const [lovers, setLovers] = useState<Rank[]>([])
    useEffect(() => {
        let id = params.id
        analysisLogEvent(AnalysisEventName.detail_enter)
        if (id) {
            setPageState("loading")
            api.getUserDetail(id)
                .then((user) => {
                    setUser(user)
                    setPageState("clear")
                    // 更新消息用户
                    messages.updateUser(user)
                    analysisLogEvent(AnalysisEventName.detail_load_success)
                })
                .catch(() => {
                    setPageState("error")
                    analysisLogEvent(AnalysisEventName.detail_load_failure)
                })
        } else {
            setPageState("error")
            analysisLogEvent(AnalysisEventName.detail_load_failure)
        }
    }, [])

    useEffect(() => {
        let id = params.id
        analysisLogEvent(AnalysisEventName.detail_enter)
        if (id) {
            api.getRank({
                per_page: 3,
                page: 1,
                range: 3,
                type: 4,
                anchor_id: id
            }).then((items) => {
                setLovers(items)
            }).catch(() => { })
        }
    }, [])

    useEffect(() => {
        let id = params.id
        if (id) {
            // 请求视频列表
            api.videoList({
                "per_page": 100,
                "page": 1,
                "filter[user_id]": id
            }).then((value) => {
                setVideos(value)
            })
        }
    }, [])

    const handleCall = useCallback(() => {
        if (Number(params.id)) {
            analysisLogEvent(AnalysisEventName.detail_call_click)
            call.handleRechargeCall(Number(params.id), user, (error) => {
                if (error && error.code === 2001) {
                    analysisLogEvent(AnalysisEventName.detail_call_insufficient)
                }
            })
        }
    }, [params, user])

    const handleNavigateMessage = useCallback(() => {
        analysisLogEvent(AnalysisEventName.detail_message_click)
        navigate(PagePath.message_direct + `/${params.id}`)
    }, [navigate, params])

    const handleFollow = useCallback(() => {
        analysisLogEvent(AnalysisEventName.detail_like_click)
        if (user?.id) {
            // 已经关注，取关操作
            if (user?.is_following === true) {
                let loading = Loading.show()
                api.userUnfollow(user.id).then(() => {
                    let newUser = { ...user }
                    newUser.is_following = false
                    setUser(newUser)
                    Loading.dismiss(loading)
                }).catch((err) => {
                    let error = err as NetworkError
                    Loading.dismiss(loading)
                    Toast.show({ content: error.message })
                })
            } else {
                let loading = Loading.show()
                api.userFollow(user.id).then(() => {
                    let newUser = { ...user }
                    newUser.is_following = true
                    setUser(newUser)
                    Loading.dismiss(loading)
                }).catch((err) => {
                    let error = err as NetworkError
                    Loading.dismiss(loading)
                    Toast.show({ content: error.message })
                })
            }
        }
    }, [user, setUser])

    const handleBlock = useCallback(() => {
        if (user?.id) {
            if (user?.is_blocked === true) {
                // 已经拉黑，取消拉黑
                let loading = Loading.show()
                api.userUnblock(user.id).then(() => {
                    let newUser = { ...user }
                    newUser.is_blocked = false
                    setUser(newUser)
                    Loading.dismiss(loading)
                    Toast.show({ content: i18n.t('detail.unblock-success-toast') })
                }).catch((err) => {
                    let error = err as NetworkError
                    Loading.dismiss(loading)
                    Toast.show({ content: error.message })
                })
            } else {
                // 拉黑
                let loading = Loading.show()
                api.userBlock(user.id).then(() => {
                    let newUser = { ...user }
                    newUser.is_blocked = true
                    setUser(newUser)
                    Loading.dismiss(loading)
                    Toast.show({ content: i18n.t('detail.block-success-toast') })
                }).catch((err) => {
                    let error = err as NetworkError
                    Loading.dismiss(loading)
                    Toast.show({ content: error.message })
                })
            }
        }
    }, [user])

    return { user, pageState, handleCall, handleNavigateMessage, handleFollow, handleBlock, videos, lovers }
}

export default useDetail