import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { PagePath } from 'models';
import * as Pages from 'pages';
import { UpdateModal, DeviceModal, Tab, Authing, RechargeModal, FirstRechargeModal, FirstRechargeButton, CheckinModal, RechargeCallModal, BusyModal } from 'components';
import { useApp, usePage } from 'hooks';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import { updateUpdateVisible, updateDeveceVisible, updateRechargeVisible, updateFirstRechargeVisible, updateCheckinVisible, updateRechargeCall, updateHotGirls } from 'store/app';
import store from 'store';
import config from '../config'

export const history = createBrowserHistory();

const AuthRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const account = useSelector(state => (state as any).account).value
  if (!account) {
    return <Navigate to={PagePath.accounts_welcome} replace />
  }
  return children as JSX.Element;
}

const NoAuthRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const account = useSelector(state => (state as any).account).value
  if (account) {
    return <Navigate to={PagePath.root} replace />
  }
  return children as JSX.Element;
}

const SignupRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const account = useSelector(state => (state as any).account).value
  if (account && account.email) {
    return <Navigate to={PagePath.root} replace />
  }
  return children as JSX.Element;
}

const App = React.memo(() => {
  const account = useSelector(state => (state as any).account).value
  const { authing, handleAuth } = useApp()
  useEffect(() => {
    handleAuth()
  }, [handleAuth])

  if (config.independent === true) {
    return <></>
  }


  if (authing) {
    return <Authing></Authing>
  }
  return (
    <>
      <Routes>
        <Route path="/" element={account ? <Pages.ExplorePage /> : <Pages.WelcomePage />}></Route>
        {/* Account */}
        <Route path={PagePath.accounts_welcome} element={<NoAuthRoute><Pages.WelcomePage /></NoAuthRoute>} />
        <Route path={PagePath.accounts_login} element={<NoAuthRoute><Pages.LoginPage /></NoAuthRoute>} />
        <Route path={PagePath.accounts_signup} element={<SignupRoute><Pages.SignupPage /></SignupRoute>} />
        <Route path={PagePath.accounts_profile} element={<AuthRoute><Pages.ProfilePage /></AuthRoute>} />
        <Route path={PagePath.accounts_edit} element={<AuthRoute><Pages.EditPage /></AuthRoute>} />
        <Route path={PagePath.accounts_edit_nickname} element={<AuthRoute><Pages.EditNicknamePage /></AuthRoute>} />
        <Route path={PagePath.accounts_edit_age} element={<AuthRoute><Pages.EditAgePage /></AuthRoute>} />
        <Route path={PagePath.accounts_edit_about} element={<AuthRoute><Pages.EditAboutPage /></AuthRoute>} />
        <Route path={PagePath.accounts_edit_height} element={<AuthRoute><Pages.EditHeightPage /></AuthRoute>} />
        <Route path={PagePath.accounts_edit_weight} element={<AuthRoute><Pages.EditWeightPage /></AuthRoute>} />
        <Route path={PagePath.accounts_edit_hair} element={<AuthRoute><Pages.EditHairPage /></AuthRoute>} />
        <Route path={PagePath.accounts_edit_body} element={<AuthRoute><Pages.EditBodyPage /></AuthRoute>} />
        <Route path={PagePath.accounts_edit_belong} element={<AuthRoute><Pages.EditBelongPage /></AuthRoute>} />
        <Route path={PagePath.accounts_edit_constellation} element={<AuthRoute><Pages.EditConstellationPage /></AuthRoute>} />
        <Route path={PagePath.accounts_blacklist} element={<AuthRoute><Pages.BlackListPage /></AuthRoute>} />
        <Route path={PagePath.accounts_following} element={<AuthRoute><Pages.FollowPage /></AuthRoute>} />
        <Route path={PagePath.accounts_follower} element={<AuthRoute><Pages.FollowPage /></AuthRoute>} />
        <Route path={PagePath.accounts_view_history} element={<AuthRoute><Pages.HistoryPage /></AuthRoute>} />
        {/* Anchor */}
        <Route path={PagePath.anchor_explore} element={<AuthRoute><Pages.ExplorePage /></AuthRoute>} />
        <Route path={PagePath.anchor_hot_following} element={<AuthRoute><Pages.HotPage /></AuthRoute>} />
        <Route path={PagePath.anchor_hot_new} element={<AuthRoute><Pages.HotPage /></AuthRoute>} />
        <Route path={PagePath.anchor_hot_top} element={<AuthRoute><Pages.HotPage /></AuthRoute>} />
        <Route path={PagePath.anchor_detail + "/:id"} element={<AuthRoute><Pages.DetailPage /></AuthRoute>} />
        <Route path={PagePath.anchor_ranking_anchor + "/:range"} element={<AuthRoute><Pages.RankingPage /></AuthRoute>} />
        <Route path={PagePath.anchor_ranking_gold + "/:range"} element={<AuthRoute><Pages.RankingPage /></AuthRoute>} />
        <Route path={PagePath.anchor_ranking_gift + "/:range"} element={<AuthRoute><Pages.RankingPage /></AuthRoute>} />
        <Route path={PagePath.anchor_ranking_lover + "/:range"} element={<AuthRoute><Pages.RankingPage /></AuthRoute>} />
        <Route path={PagePath.anchor_lover + "/:id"} element={<AuthRoute><Pages.LoverPage /></AuthRoute>} />
        {/* Legal */}
        <Route path={PagePath.legal_privacy_policy} element={<Pages.PrivacyPolicyPage />} />
        <Route path={PagePath.legal_terms_of_service} element={<Pages.TermsOfServicePage />}></Route>
        {/* System */}
        <Route path={PagePath.system_settings} element={<AuthRoute><Pages.SettingsPage /></AuthRoute>} />
        <Route path={PagePath.system_feedback} element={<AuthRoute><Pages.FeedbackPage /></AuthRoute>} />
        <Route path={PagePath.system_report + "/:id"} element={<AuthRoute><Pages.ReportPage /></AuthRoute>} />
        {/* Finance */}
        <Route path={PagePath.finance_expenses} element={<AuthRoute><Pages.ExpensesPage /></AuthRoute>} />
        <Route path={PagePath.finance_expenses_token} element={<AuthRoute><Pages.ExpensesTokenPage /></AuthRoute>} />
        <Route path={PagePath.finance_recharge} element={<AuthRoute><Pages.RechargePage /></AuthRoute>} />
        {/* Live */}
        <Route path={PagePath.live_call} element={<AuthRoute><Pages.CallPage /></AuthRoute>} />
        <Route path={PagePath.live_conversation} element={<AuthRoute><Pages.ConversationPage /></AuthRoute>} />
        <Route path={PagePath.live_rating} element={<AuthRoute><Pages.RatingPage /></AuthRoute>} />
        {/* messages */}
        <Route path={PagePath.message_inbox} element={<AuthRoute><Pages.InboxPage /></AuthRoute>} />
        <Route path={PagePath.message_call_history} element={<AuthRoute><Pages.InboxPage /></AuthRoute>} />
        <Route path={PagePath.message_direct + "/:id"} element={<AuthRoute><Pages.DirectPage /></AuthRoute>} />
        <Route path="*" element={<Pages.NotFoundPage />} />
      </Routes>
      <AppTab />
      <AppModals />
      <AppViews />
    </>
  );
})

const AppTab = React.memo(() => {
  const { matchTab } = usePage()
  return (
    <>
      {matchTab && <Tab />}
    </>
  )
})

const AppModals = React.memo(() => {
  const rechargeVisible = useSelector(state => (state as any).app).rechargeVisible
  const rechargeCall = useSelector(state => (state as any).app).rechargeCall
  const firstRechargeVisible = useSelector(state => (state as any).app).firstRechargeVisible
  const checkinVisible = useSelector(state => (state as any).app).checkinVisible
  const hotGirls = useSelector(state => (state as any).app).hotGirls
  const deveceVisible = useSelector(state => (state as any).app).deveceVisible
  const updateVisible = useSelector(state => (state as any).app).updateVisible
  return (
    <>
      <RechargeCallModal visible={rechargeCall.visible} onClose={() => { updateRechargeCall({ visible: false, userInfo: undefined })(store.dispatch) }} />
      <RechargeModal visible={rechargeVisible} onClose={() => { updateRechargeVisible(false)(store.dispatch) }} />
      <FirstRechargeModal visible={firstRechargeVisible} onClose={() => { updateFirstRechargeVisible(false)(store.dispatch) }} />
      <CheckinModal visible={checkinVisible} onClose={() => updateCheckinVisible(false)(store.dispatch)} />
      <BusyModal visible={Array.isArray(hotGirls) && hotGirls.length > 0} onClose={() => updateHotGirls(undefined)(store.dispatch)}></BusyModal>
      <DeviceModal visible={deveceVisible} onClose={() => updateDeveceVisible(false)(store.dispatch)}></DeviceModal>
      <UpdateModal visible={updateVisible} onClose={() => updateUpdateVisible(false)(store.dispatch)} />
    </>
  )
})

const AppViews = React.memo(() => {
  return (
    <>
      <FirstRechargeButton />
    </>
  )
})

export default App