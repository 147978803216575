import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { List, AnchorCell } from 'components';
import { api } from 'server';
import { PagePath, User, ADBanner } from 'models';
import { analysisLogEvent, AnalysisEventName } from 'analysis';
import { useSelector } from 'react-redux';
import './ExplorePage.scss';

const ExplorePage = React.memo(() => {
    const { t } = useTranslation()
    const account = useSelector(state => (state as any).account).value
    return (
        <div className='explore-page'>
            <div className='explore-header'>
                <div className='explore-header-content'>
                    <div className='explore-title'>{t('explore.foryou')}</div>
                    <Link className='header-token-view touch-without-feedback' to={PagePath.finance_recharge}>
                        <div className='token-icon'></div>
                        <div className='token-label'>{account.balance ?? 0}</div>
                        <div className='add-icon'></div>
                    </Link>
                    <Link className='rank-button touch-opacity' to={PagePath.anchor_ranking_anchor + "/week"}></Link>
                </div>
            </div>
            <ExploreListView quick="hot"></ExploreListView>
        </div>
    );
})

const ExploreListView = React.memo((props: { quick: "hot" | "new" | "rank" }) => {
    const { quick } = props;
    const navigate = useNavigate()
    const [adList, setAdList] = useState<ADBanner[]>([])

    const onFetch = (page: number, pageSize: number, startFetch: (data: User[]) => void, abortFetch: (error?: Error) => void) => {
        api.getAnchorList({
            page: page,
            per_page: pageSize,
            "filter[quick]": quick
        }).then((items) => {
            startFetch(items)
        }).catch(() => {
            abortFetch()
        })
    }

    useEffect(() => {
        api.getAdList({ "filter[pid]": 1 }).then((list) => {
            setAdList(list)
        }).catch(() => { })
    }, [setAdList])

    return (
        <div className='page-container'>
            {quick === 'hot' && adList.length > 0 ?
                (
                    <div className='list-banner-wrapper'>
                        <Swiper
                            style={{
                                // @ts-ignore
                                "--swiper-pagination-color": "#fff"
                            }}
                            autoplay={{
                                delay: 5000
                            }}
                            loop
                            className='list-banner'
                            pagination={{
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                        >
                            {
                                adList.map((item, index) => {
                                    return (
                                        <SwiperSlide
                                            key={index}
                                            className='list-banner-item'
                                        >
                                            <div className='image' style={{
                                                backgroundImage: `url(${item.media_url})`
                                            }} onClick={() => {
                                                analysisLogEvent(AnalysisEventName.explore_banner_item_click)
                                                if (item.link?.type === 1 && item.link?.user_id) {
                                                    navigate(PagePath.anchor_detail + "/" + item.link?.user_id)
                                                } else if (item.link?.type === 2 && item.link?.url) {
                                                    window.open(item.link?.url)
                                                }
                                            }}></div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                )
                : null
            }
            <List
                pageSize={18}
                className='list'
                onFetch={onFetch}
                renderItem={(item, index) => <AnchorCell key={index} item={item} onClick={() => {
                    analysisLogEvent(AnalysisEventName.explore_user_item_click)
                }}></AnchorCell>}
            ></List>
        </div>
    );
})

export default ExplorePage;