
import { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { network, api } from 'server';
import { Toast } from 'components';
import { NetworkError } from 'models';
import { useDispatch } from 'react-redux';
import { PagePath } from 'models';
import { login } from 'store/account';
import { analysisLogEvent, AnalysisEventName } from 'analysis';

function useLogin() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // 邮箱   
  const [email, setEmail] = useState("");
  // 密码
  const [password, setPassword] = useState("");
  // 加载状态
  const [loading, setLoading] = useState(false);
  // 是否可以点击
  const isEnabled = useMemo(() => {
    return email.length > 0 && password.length > 0
  }, [email, password])
  // 点击登录
  const handleLogin = useCallback(async () => {
    setLoading(true)
    try {
      // 获取token
      let apiToken = await api.login({ email, password })
      network.apiToken = apiToken
      // 获取用户数据
      const account = await api.getAccount()
      await login(account)(dispatch)
      setLoading(false)
      navigate(PagePath.root, { replace: true })
      analysisLogEvent(AnalysisEventName.account_login_success)
    } catch (err) {
      // 失败
      network.apiToken = ""
      let error = err as NetworkError
      setLoading(false)
      Toast.show({ content: error.message })
      analysisLogEvent(AnalysisEventName.account_login_failure)
    }
  }, [email, password, setLoading, navigate, dispatch]);

  return { email, setEmail, password, setPassword, handleLogin, loading, isEnabled }
}

export default useLogin