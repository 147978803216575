import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PagePath } from 'models';
import Avatar from '../avatar/Avatar';
import './BusyModal.scss';

const BusyModal = React.memo((props: {
    visible?: boolean;
    onClose?: () => void;
}) => {
    const { visible, onClose } = props
    const [show, setShow] = React.useState<undefined | boolean>(false)
    const hotGirls = useSelector(state => (state as any).app).hotGirls
    const navigate = useNavigate()
    React.useLayoutEffect(() => {
        setTimeout(() => {
            setShow(visible)
        })
    }, [visible, setShow])

    const close = React.useCallback(() => {
        setShow(false)
        setTimeout(() => {
            typeof onClose === 'function' && onClose()
        }, 250)
    }, [onClose])

    if (!visible) {
        return null
    }

    return (
        <div className={classNames('busy-modal', !show && 'hide')}>
            <div className='mask' onClick={() => close()}></div>
            <div className='content-view'>
                <div className='title'>She might be busy…</div>
                <div className='sub-title'>Check out who is available now!</div>
                <div className='line'></div>
                <div className='list'>
                    {
                        Array.isArray(hotGirls) && hotGirls.map((item, index) => {
                            return (
                                <div className='list-item-view' key={index} onClick={() => {
                                    close()
                                    navigate(PagePath.anchor_detail + `/${item.id}`)
                                }}>
                                    <div className='list-item-view-content'>
                                        <Avatar className='avatar' src={item.profile.head}></Avatar>
                                        <p className='nickname'>{item.profile.nickname}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
})

export default BusyModal