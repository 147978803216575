import i18n from 'i18next';
import dayjs from 'dayjs';
import { Right, Role, Message, MessageType } from 'models';

const Calculator = {
    userId: 0,
    defaultDateFormat: "MM/DD/YYYY HH:mm",
    getPrice(user?: any) {
        if (user && user?.role === Role.anchor && user?.anchor) {
            return user?.anchor?.price as number
        } else if (user && user?.role === Role.user && user?.user) {
            return user?.user?.price as number
        }
        return undefined
    },
    getDurationFromDate(date1: Date, date2: Date) {
        let distance = Math.abs(date1.getTime() - date2.getTime());
        return Calculator.getDuration(distance)
    },
    getDuration(duration: number) {
        let distance = Math.abs(duration);
        const hours = Math.floor(distance / 3600000);
        distance -= hours * 3600000;
        const minutes = Math.floor(distance / 60000);
        distance -= minutes * 60000;
        const seconds = Math.floor(distance / 1000);
        return `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
    },
    getPeerId(message: Message) {
        if (Calculator.userId === message.to) {
            return message.sender_id
        } else if (Calculator.userId === message.sender_id) {
            return message.to
        }
    },
    getMessageDateFormat(time: number) {
        let nowDate = new Date()
        let date = new Date(time * 1000)
        // 今天
        if (nowDate.getFullYear() === date.getFullYear() && nowDate.getMonth() === date.getMonth() && nowDate.getDate() === date.getDate()) {
            return "HH:mm"
        } else if (nowDate.getFullYear() === date.getFullYear()) {
            return "MM/DD HH:mm"
        } else {
            return "MM/DD/YYYY HH:mm"
        }
    },
    getMessageDateString(time?: number) {
        if (time) {
            return (dayjs((time ?? 0) * 1000).format(Calculator.getMessageDateFormat(time)))
        }
        return ''
    },
    getMessageDesc(message: Message) {
        if (message.content?.type === MessageType.text) {
            return message.content?.text
        } else if (message.content?.type === MessageType.gift) {
            return i18n.t('inbox.content.gift')
        } else if (message.content?.type === MessageType.voice) {
            return i18n.t('inbox.content.voice')
        } else if (message.content?.type === MessageType.call) {
            return i18n.t('inbox.content.call')
        } else if (message.content?.type === MessageType.image) {
            return i18n.t('inbox.content.image')
        } else {
            return message.content?.text
        }
    },
    checkAvailabled(right: Right) {
        let now = new Date().getTime() / 1000
        if (right.available_at && right.expired_at) {
            return right.available_at < now && right.expired_at > now
        }
        return false
    }
}

export default Calculator