import AgoraRTM, { RtmClient, RtmMessage } from 'agora-rtm-sdk'
import config from 'config';
import { api } from 'server';
import { CustomRtmMessage } from 'models';
import store from 'store';
import { addConversationMessage } from 'store/conversation';
import { Log } from 'utils';

const rtm: {
    client: RtmClient | undefined,
    token: string | undefined,
    uid: string | undefined,
    isLoading: boolean,
    isEnabled: boolean,
    login: () => void,
    logout: () => void,
    sendMessage: (message: CustomRtmMessage, peerId: string, completion: (isSuccess: boolean) => void) => void,
    keep: () => void,
} = {
    client: undefined,
    token: undefined,
    uid: undefined,
    isLoading: false,
    isEnabled: false,
    login: () => {
        if (!rtm.client) {
            rtm.client = AgoraRTM.createInstance(config.server.agora_id)
            rtm.keep()
        }
        if (rtm.token && rtm.uid) {
            Log.print("[RTM]开始登录", rtm.uid, rtm.token)
            rtm.client.login({
                uid: `${rtm.uid}`,
                token: rtm.token,
            })
        }
    },
    logout: () => {
        rtm.token = undefined
        rtm.client?.logout()
    },
    sendMessage: async (message: CustomRtmMessage, peerId: string, completion: (isSuccess: boolean) => void) => {
        try {
            Log.print("[RTM]发送消息", message, peerId)
            if (rtm.client) {
                const sendResult = await rtm.client.sendMessageToPeer(
                    { text: JSON.stringify(message) },
                    peerId,
                )
                Log.print("[RTM]发送消息结果", sendResult)
                if (sendResult.hasPeerReceived) {
                    completion(true)
                } else {
                    completion(false)
                }
            } else {
                completion(false)
            }
        } catch {
            completion(false)
        }
    },
    keep: () => {
        // 执行每秒检测token是否过期
        setInterval(async () => {
            if (!rtm.token && !rtm.isLoading && rtm.isEnabled) {
                rtm.isLoading = true
                let chatToken = await api.chatToken()
                rtm.token = chatToken
                rtm.isLoading = false
                rtm.login()
            }
        }, 1 * 1000)
        // 监听token过期
        rtm.client?.on("TokenExpired", () => {
            rtm.token = undefined
        })
        // 收到对方消息
        rtm.client?.on("MessageFromPeer", (message: RtmMessage, peerId: string) => {
            Log.print("[RTM]收到点对点消息", message, peerId)
            let conversation = store.getState().conversation.value
            if (message.text && conversation && conversation.peer && conversation.peer && conversation.peer.id && `${conversation.peer.id}` === peerId) {
                let customMessage = JSON.parse(message.text) as CustomRtmMessage
                if (customMessage) {
                    addConversationMessage(customMessage)(store.dispatch)
                }
            }
        })
    },
}

export default rtm;