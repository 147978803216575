import { createSlice } from '@reduxjs/toolkit';
import { api } from 'server';
import { Recharge } from 'models';

const initialState: {
    value: Recharge[],
    state: 'loading' | 'error' | 'clear'
} = {
    value: [],
    state: 'clear'
}

// Slice
const slice = createSlice({
    name: 'rechargeList',
    initialState,
    reducers: {
        updateRechargeList: (state, action) => {
            state.value = [...action.payload];
        },
        updateState: (state, action) => {
            state.state = action.payload;
        },
        clearRechargeList: (state) => {
            state.value = [];
            state.state = 'clear'
        },
    },
});

export default slice.reducer

export const updateRechargeListRemote = () => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateState('loading'));
        const list = await api.getTokenList()
        dispatch(slice.actions.updateRechargeList(list));
        dispatch(slice.actions.updateState('clear'));
    } catch (e) {
        dispatch(slice.actions.updateState('error'));
        return console.error((e as any).message);
    }
}

export const clearRechargeList = () => async (dispatch: (arg0: { payload: any; type: string; }) => any) => {
    try {
        return dispatch(slice.actions.clearRechargeList())
    } catch (e) {
        return console.error((e as any).message);
    }
}