import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'components';
import { MessageUser, Role, MessageType, PagePath } from 'models';
import { Calculator } from 'utils';
import './UserPage.scss';
import classNames from 'classnames';
import { messages } from 'server';

const UsersPage = React.memo(() => {
    let users = (useSelector(state => (state as any).users).value as MessageUser[]).slice()
    users = users.filter((item) => !(item.role !== Role.support && item.message === undefined))
    for (var i = 0; i < users.length - 1; i++) {
        for (var j = 0; j < users.length - 1 - i; j++) {
            let currentSent = users[j].message?.sent_at
            let nextSent = users[j + 1].message?.sent_at
            if (currentSent && nextSent && currentSent < nextSent) {
                var temp = users[j];
                users[j] = users[j + 1];
                users[j + 1] = temp;
            }
        }
    }

    let findIndex = users.findIndex((item) => { return item.role === Role.support })
    if (findIndex > 0) {
        users.unshift(users.splice(findIndex, 1)[0]);
    }

    React.useEffect(() => {
        messages.updateFromRemote()
    }, [])

    return (
        <>
            {
                Array.isArray(users) && users.map((item) => {
                    return <UsersItemView key={item.id} item={item} />
                })
            }
        </>
    );
})

const UsersItemView = React.memo((props: { item: MessageUser }) => {
    const { item } = props;
    const { message } = item;
    const navigate = useNavigate()
    return (
        <div className='users-item-view touch-soft-highlight' onClick={() => {
            messages.clearBadge(item.id)
            navigate(PagePath.message_direct + `/${item.id}`)
        }}>
            <Avatar className='avatar' src={item.profile.head}></Avatar>
            {item.badge && item.badge > 0 ? <div className='badge'>{item.badge}</div> : <></>}
            <div className='text-wrapper'>
                <div className='nickname-wrapper'>
                    <div className='nickname-label'>{item.profile.nickname}</div>
                    <div className='time-label'>{Calculator.getMessageDateString(item.message?.sent_at)}</div>
                </div>
                <div className={classNames('message-label', message?.content?.type === MessageType.call && 'call')}>{message && Calculator.getMessageDesc(message)}</div>
            </div>
        </div>
    );
})

export default UsersPage