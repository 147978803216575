
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { api } from 'server';
import { Toast, Loading } from 'components';
import { updateAccountRemote } from 'store/account';
import { NetworkError } from 'models';
import i18n from 'i18next';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

function useEdit() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // 保存数据
    const handleSave = useCallback(async (props: any) => {
        let loading = Loading.show()
        try {
            await api.updateUser({ ...props })
            await updateAccountRemote()(dispatch)
            Loading.dismiss(loading)
            navigate(-1)
        } catch (err) {
            // 失败
            let error = err as NetworkError
            Loading.dismiss(loading)
            Toast.show({ content: error.message })
        }
    }, [dispatch, navigate])

    // 保存头像
    const handleAddAvatar = useCallback(async () => {
        let inp = document.getElementById("avatar-file") as HTMLInputElement
        if (inp && inp.files !== null && inp.files.length > 0) {
            let loading = Loading.show()
            try {
                // 上传到OSS
                let uploadResponse = await api.upload({
                    value: inp.files[0],
                    fileName: uuidv4()
                }) as { "url": string }
                const url = uploadResponse["url"]
                inp.value = ""
                try {
                    await api.updateUser({
                        head: url
                    })
                    await updateAccountRemote()(dispatch)
                    Loading.dismiss(loading)
                    Toast.show({ content: i18n.t('edit.save.success.toast') })
                } catch (err) {
                    // 失败
                    let error = err as NetworkError
                    Loading.dismiss(loading)
                    Toast.show({ content: error.message })
                }
                Loading.dismiss(loading)
            } catch {
                inp.value = ""
                Loading.dismiss(loading)
            }
        }
    }, [dispatch])

    return { handleSave, handleAddAvatar }
}

export default useEdit