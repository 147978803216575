import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, EventParams } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC3T8p79443MktjRJrRtyRJcPOzyzo_LQ8",
    authDomain: "liveweb-8578e.firebaseapp.com",
    projectId: "liveweb-8578e",
    storageBucket: "liveweb-8578e.appspot.com",
    messagingSenderId: "206313201457",
    appId: "1:206313201457:web:9e280ae17389a8982c1a10",
    measurementId: "G-D4XVYWV31V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export enum AnalysisEventName {
    /// [欢迎]点击游客开始
    account_start_guest_click = "account_start_guest_click",
    /// [欢迎]点击游客开始 - 成功
    account_start_guest_success = "account_start_guest_success",
    /// [欢迎]点击游客开始 - 失败
    account_start_guest_failure = "account_start_guest_failure",
    /// [欢迎]点击登录
    account_start_signin_click = "account_start_signin_click",
    /// [欢迎]点击注册
    account_start_signup_click = "account_start_signup_click",
    /// [注册]成功
    account_signup_success = "account_signup_success",
    /// [注册]失败
    account_signup_failure = "account_signup_failure",
    /// [资料补充]成功
    account_supplement_success = "account_supplement_success",
    /// [资料补充]失败
    account_supplement_failure = "account_supplement_failure",
    /// [登录]成功
    account_login_success = "account_login_success",
    /// [登录]失败
    account_login_failure = "account_login_failure",
    /// [TabBar]点击Explore
    tabbar_explore_click = "tabbar_explore_click",
    /// [TabBar]点击shop
    tabbar_shop_click = "tabbar_videos_click",
    /// [TabBar]点击Inbox
    tabbar_inbox_click = "tabbar_inbox_click",
    /// [TabBar]点击Profile
    tabbar_profile_click = "tabbar_profile_click",
    /// [TabBar]点击Hot
    tabbar_hot_click = "tabbar_hot_click",
    /// [首页]点击主播列表项
    explore_user_item_click = "explore_user_item_click",
    /// [首页]点击主播列表项
    explore_top_user_item_click = "explore_top_user_item_click",
    /// [首页]点击广告列表项
    explore_banner_item_click = "explore_banner_item_click",
    /// [辣妹]选中following
    hot_following_click = "hot_following_click",
    /// [辣妹]选中new
    hot_new_click = "hot_new_click",
    /// [辣妹]选中Top
    hot_top_click = "hot_top_click",
    /// [详情]加载详情页
    detail_enter = "detail_enter",
    /// [详情]初始化成功
    detail_load_success = "detail_load_success",
    /// [详情]初始化失败
    detail_load_failure = "detail_load_failure",
    /// [详情]点击消息
    detail_message_click = "detail_message_click",
    /// [详情]点击喜欢
    detail_like_click = "detail_like_click",
    /// [详情]点击呼叫
    detail_call_click = "detail_call_click",
    /// [详情]点击呼叫 - 余额不足
    detail_call_insufficient = "detail_call_insufficient",
    /// [接听]点击取消
    answer_cancel = "answer_cancel",
    /// [接听]点击接听
    answer_accept = "answer_accept",
    /// [通话]进入通话
    stream_enter = "stream_enter",
    /// [通话]开始加入
    stream_join_begin = "stream_join_begin",
    /// [通话]加入成功
    stream_join_success = "stream_join_success",
    /// [通话]监听对方published事件
    stream_user_published = "stream_user_published",
    /// [通话]点击退出按钮
    stream_exit_click = "stream_exit_click",
    /// [通话]点击退出按钮 - 确认
    stream_exit_click_confirm = "stream_exit_click_confirm",
    /// [通话]点击退出按钮 - 取消
    stream_exit_click_cancel = "stream_exit_click_cancel",
    /// [通话]点击打开礼物按钮
    stream_gift_click = "stream_gift_click",
    /// [通话]点击赠送礼物
    stream_gift_send = "stream_gift_send",
    /// [通话]点击赠送礼物 - 成功
    stream_gift_send_success = "stream_gift_send_success",
    /// [通话]点击赠送礼物 - 失败
    stream_gift_send_failure = "stream_gift_send_failure",
    /// [通话]点击前/后摄像头反转按钮
    stream_reverse_click = "stream_reverse_click",
    /// [通话]点击关闭/打开摄像头按钮
    stream_camera_click = "stream_camera_click",
    /// [通话]检测到通话结束
    stream_endcall_trigger = "stream_endcall_trigger",
    /// [通话]余额不足充值点击
    stream_insufficient_recharge_click = "stream_insufficient_recharge_click",
    /// [主播评价]点击关闭
    rating_close_click = "rating_close_click",
    /// [主播评价]点击发送
    rating_send_click = "rating_send_click",
    /// [收件箱]切换到通话
    inbox_call_click = "inbox_call_click",
    /// [消息]点击文字发送
    message_text_send = "message_text_send",
    /// [消息]点击图片发送
    message_image_send = "message_image_send",
    /// [充值列表]进入页面
    recharge_list_enter = "recharge_list_enter",
    /// [充值列表]点击记录
    recharge_list_record_click = "recharge_list_record_click",
    /// [充值列表]点击列表项
    recharge_list_item_click = "recharge_list_item_click",
    /// [充值弹窗]进入页面
    recharge_modal_enter = "recharge_modal_enter",
    /// [充值弹窗]点击列表项
    recharge_modal_item_click = "recharge_modal_item_click",
    /// [首充]进入页面
    first_recharge_enter = "first_recharge_enter",
    /// [首充]点击首充按钮
    first_recharge_button_click = "first_recharge_button_click",
    /// [首充]点击呼叫首充按钮
    first_call_recharge_button_click = "first_call_recharge_button_click"
}

export const analysisLogEvent = (eventName: AnalysisEventName, eventParams: EventParams = {}) => {
    logEvent(analytics, eventName, eventParams)
}