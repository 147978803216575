import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useMatch } from 'react-router-dom';
import { PagePath } from 'models';
import classNames from 'classnames';
import CallHistoryPage from './page/CallHistoryPage';
import UsersPage from './page/UsersPage';
import './InboxPage.scss';
import { analysisLogEvent, AnalysisEventName } from 'analysis';

const InboxPage = React.memo(() => {
    const account = useSelector(state => (state as any).account).value
    const { t } = useTranslation()
    const matchCalHistory = useMatch(PagePath.message_call_history) != null;
    const matchInBox = useMatch(PagePath.message_inbox) != null;
    return (
        <div className='inbox-page'>
            <div className='segmented-control'>
                <Link className={classNames('item', 'touch-opacity', (matchInBox) && 'active')} to={PagePath.message_inbox} replace>
                    <span className='label'>{t('inbox.title.message')}</span>
                </Link>
                <Link className={classNames('item', 'touch-opacity', matchCalHistory && 'active')} to={PagePath.message_call_history} replace
                    onClick={() => {
                        analysisLogEvent(AnalysisEventName.inbox_call_click)
                    }}
                >
                    <span className='label'>{t('inbox.title.call')}</span>
                </Link>
                <Link className='header-token-view touch-without-feedback' to={PagePath.finance_recharge}>
                    <div className='token-icon'></div>
                    <div className='token-label'>{account.balance ?? 0}</div>
                    <div className='add-icon'></div>
                </Link>
            </div>
            <div className='page-container '>
                {matchInBox && <UsersPage></UsersPage>}
                {matchCalHistory && <CallHistoryPage></CallHistoryPage>}
            </div>
        </div>
    );
})

export default InboxPage;