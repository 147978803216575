import React from 'react';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Header, ActionAlert } from 'components';
import { PagePath } from 'models';
import { useSettings } from 'hooks';
import config from '../../../config';
import './SettingsPage.scss';

const SettingsPage = React.memo(() => {
    const { t } = useTranslation()
    const { alertVisible, setAlertVisible, handleLogout } = useSettings()
    const navigate = useNavigate()
    return (
        <div className='settings-page'>
            <Header title={t('settings.title')}></Header>
            <div className='section-label'>{t('settings.section-legal')}</div>
            <SettingsCellItem title={t('settings.item.terms')} showArrow onClick={() => { navigate(PagePath.legal_terms_of_service) }}></SettingsCellItem>
            <SettingsCellItem title={t('settings.item.privacy')} showArrow onClick={() => { navigate(PagePath.legal_privacy_policy) }}></SettingsCellItem>
            <div className='section-label'>{t('settings.section-support')}</div>
            <SettingsCellItem title={t('settings.item.feedback')} showArrow onClick={() => { navigate(PagePath.system_feedback) }}></SettingsCellItem>
            <div className='section-label'>{t('settings.section-general')}</div>
            <SettingsCellItem title={t('settings.item.version')} detail={config.version} onClick={() => {

            }}></SettingsCellItem>
            <SettingsCellItem title={t('settings.item.logout')} onClick={() => {
                setAlertVisible(true)
            }}></SettingsCellItem>
            <ActionAlert visible={alertVisible} title={t('settings.item.logout-alert-title')} actions={[
                {
                    title: t('common.cancel'), style: { color: 'var(--system-blue)', fontWeight: 'var(--font-weight-system-semibold)' }, handler: () => { setAlertVisible(false) }
                },
                {
                    title: t('settings.item.logout-alert-comfirm'), style: { color: 'var(--system-red)' }, handler: () => {
                        handleLogout()
                        setAlertVisible(false)
                    }
                }
            ]}></ActionAlert>
        </div>
    );
})


const SettingsCellItem = React.memo((props: {
    title?: string;
    detail?: string;
    showArrow?: Boolean;
    onClick?: MouseEventHandler<HTMLDivElement>
}) => {
    return (
        <div className='cell-item touch-soft-highlight' onClick={props.onClick}>
            <div className='text-label'>{props.title}</div>
            <div className='detail-label'>{props.detail}</div>
            {props.showArrow && <div className='arrow'></div>}
        </div>
    )
})

export default SettingsPage;