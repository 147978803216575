import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Header, select } from 'components';
import { useEdit } from 'hooks';
import '../EditPage.scss';

const EditAgePage = React.memo(() => {
    const { t } = useTranslation()
    const { handleSave } = useEdit()
    const [age, setAge] = useState(0);
    const account = useSelector(state => (state as any).account).value
    useEffect(() => {
        setAge(account.profile.age)
    }, [account])
    const ageOptions = select.ageOptions
    return (
        <div className='edit-other-page'>
            <Header title={t('personal.age')}><button className='save-button touch-opacity' onClick={() => handleSave({ age: age })}>{t('common.save')}</button></Header>
            <Select
                styles={select.styleConfig}
                className='select-wrapper'
                value={ageOptions.find((item) => { return item.value === age }) ?? ageOptions[0]}
                options={ageOptions}
                onChange={(value) => {
                    let obj = value as any
                    let age = obj.value as number
                    setAge(age)
                }}
            />
        </div>
    );
})

export default EditAgePage;