import { createSlice } from '@reduxjs/toolkit';
import { api } from 'server';
import { Gift } from 'models';

const initialState: {
    value: Gift[],
    state: 'loading' | 'error' | 'clear'
} = {
    value: [],
    state: 'clear'
}

// Slice
const slice = createSlice({
    name: 'gifts',
    initialState,
    reducers: {
        updateGifts: (state, action) => {
            state.value = [...action.payload];
        },
        updateState: (state, action) => {
            state.state = action.payload;
        },
        clearGifts: (state) => {
            state.value = [];
            state.state = 'clear'
        },
    },
});

export default slice.reducer

export const updateGiftsRemote = () => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateState('loading'));
        const giftList = await api.giftGiftList()
        dispatch(slice.actions.updateGifts(giftList));
        dispatch(slice.actions.updateState('clear'));
    } catch (e) {
        dispatch(slice.actions.updateState('error'));
        return console.error((e as any).message);
    }
}

export const clearGifts = () => async (dispatch: (arg0: { payload: any; type: string; }) => any) => {
    try {
        return dispatch(slice.actions.clearGifts())
    } catch (e) {
        return console.error((e as any).message);
    }
}