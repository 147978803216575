import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PagePath } from 'models';
import './NotFoundPage.scss';

const NotFoundPage = React.memo(() => {
    const { t } = useTranslation()
    return (
        <div className='not-found-page'>
            <div className='title'>{t('notfound.title')}</div>
            <div className='desc'>{t('notfound.desc')} <Link to={PagePath.root} className='go-button'>{t('notfound.go-back')}</Link></div>
        </div>
    )
})

export default NotFoundPage