import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import './i18n/config';
import store from './store';
import App, { history } from './pages/App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import './analysis';
import SwiperCore, { Autoplay } from 'swiper';
SwiperCore.use([Autoplay])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  (
    <>
      <HistoryRouter history={history}>
        <Provider store={store}>
          <App />
        </Provider>
      </HistoryRouter>
    </>
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
