import React from 'react';
import './Viewer.scss'

const Viewer = React.memo((props: {
    src?: string,
    onClose?: () => void
}) => {
    const { src, onClose } = props;
    return (
        <div className="viewer">
            <img style={src !== undefined && src?.length > 0 ? { backgroundImage: `url(${src})` } : undefined} alt="" className='image' />
            <div className='close-button touch-opacity' onClick={() => typeof onClose === 'function' && onClose()}></div>
        </div>
    )
})

export default Viewer