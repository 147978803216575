import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'components';
import './HistoryPage.scss';
import ViewHistoryPage from './page/ViewHistoryPage';

const HistoryPage = React.memo(() => {
    const { t } = useTranslation()
    return (
        <div className='history-page'>
            <Header title={t('history.title')}></Header>
            <ViewHistoryPage />
        </div>
    );
})

export default HistoryPage;