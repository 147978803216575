import React from "react";
import { useTranslation } from 'react-i18next';
import { List, AnchorCell, ListFooterView } from 'components';
import { api } from 'server';
import { User } from 'models';
import '../HotPage.scss';

const defaultPageSize = 10

const HotFollowingPage = React.memo(() => {
    const { t } = useTranslation()
    const list = React.useRef(null)
    const [isFollowingEmpty, setIsFollowingEmpty] = React.useState(false)
    const [isFollowingNoMore, setIsFollowingNoMore] = React.useState(false)

    const onFetchFollowing = (page: number, pageSize: number, startFetch: (data: User[]) => void, abortFetch: (error?: Error) => void) => {
        api.userFollowing({
            per_page: pageSize,
            page: page
        }).then((items) => {
            startFetch(items)
            if (page === 1 && items.length === 0) {
                setIsFollowingEmpty(true)
            } else {
                setIsFollowingEmpty(false)
            }
            if (page >= 1 && items.length < defaultPageSize) {
                setIsFollowingNoMore(true)
            } else {
                setIsFollowingNoMore(false)
            }
        }).catch(() => {
            abortFetch()
        })
    }

    const onFetchRecommend = (page: number, pageSize: number, startFetch: (data: User[]) => void, abortFetch: (error?: Error) => void) => {
        api.getAnchorList({
            page: page,
            per_page: pageSize,
            "filter[quick]": "recommend"
        }).then((items) => {
            startFetch(items)
        }).catch(() => {
            abortFetch()
        })
    }

    return (
        <div className="hot-page-container following">
            {
                isFollowingEmpty
                &&
                <div className="hot-page-following-empty">
                    <div className="hot-page-following-empty-icon"></div>
                    <div className="hot-page-following-empty-label">{t('hot.following-empty')}</div>
                </div>
            }
            {
                !isFollowingEmpty
                &&
                <List
                    ref={list}
                    pageSize={defaultPageSize}
                    className='list'
                    onFetch={onFetchFollowing}
                    renderItem={(item, index) => <AnchorCell key={index} item={item} ></AnchorCell>}
                    emptyComponent={() => {
                        return <></>
                    }}
                    footerComponent={true ? () => {
                        if (list && list.current && (list.current as any).state.loadState) {
                            let loadState = (list.current as any).state.loadState as "loading" | "noMoreData" | "clear"
                            if (loadState === 'noMoreData') {
                                return <></>
                            }
                            return <ListFooterView loadState={loadState} />
                        }
                        return <></>
                    } : undefined}
                ></List>
            }
            {
                (isFollowingNoMore || isFollowingEmpty) &&
                <>
                    <div className="hot-page-following-also-title">You my also like</div>
                    <List
                        ref={list}
                        pageSize={defaultPageSize}
                        className='list'
                        onFetch={onFetchRecommend}
                        renderItem={(item, index) => <AnchorCell key={index} item={item} ></AnchorCell>}
                        emptyComponent={() => {
                            return <></>
                        }}
                    ></List>
                </>
            }
        </div >
    )
})

export default HotFollowingPage;