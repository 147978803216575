import React from 'react';
import { Header, List } from 'components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RankingListCellItem } from './RankingPage'
import { Rank } from 'models';
import { api } from 'server';

const LoverPage = React.memo(() => {
    const { t } = useTranslation()
    const params = useParams()
    const onFetch = (page: number, pageSize: number, startFetch: (data: Rank[]) => void, abortFetch: (error?: Error) => void) => {
        api.getRank({
            per_page: pageSize,
            page: page,
            range: 3,
            type: 4,
            anchor_id: params.id
        }).then((items) => {
            startFetch(items)
        }).catch(() => {
            abortFetch()
        })
    }

    return (
        <div className='ranking-page lover'>
            <Header title={t('lover.title')}></Header>
            <div className='list-view'>
                <List
                    className='list'
                    onFetch={onFetch}
                    renderItem={(item, index) => <RankingListCellItem key={index} item={item} index={index} type={'lover'} />}
                ></List>
            </div>
        </div>
    );
})

export default LoverPage