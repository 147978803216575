import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import account from './account'
import call from './call'
import conversation from './conversation'
import gifts from './gifts'
import rating from './rating'
import users from './users'
import app from './app'
import rechargeList from './rechargeList'
import right from './right'
import checkin from './checkin'
import config from 'config';
// @ts-ignore
import { logger } from 'redux-logger';

const reducer = combineReducers({
    account,
    call,
    conversation,
    gifts,
    rating,
    users,
    app,
    rechargeList,
    right,
    checkin
})

const store = configureStore({
    reducer: reducer,
    middleware: config.debug === true ? [logger] : []
})

export default store