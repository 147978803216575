import network from "./network"
import pathname from './pathname';
import config from '../config';
import { ADBanner, Right, Gender, Account, Black, Expense, User, Viewer, CallHistory, Answer, Gift, Message, MessageUser, Recharge, Rank, CheckIns } from '../models';
// @ts-ignore
import md5 from 'md5';

const api = {
    // 系统入口检查
    init_check: () => {
        return new Promise<object>((resolve, reject) => {
            network.fetch({
                pathname: pathname.init_check,
                method: 'POST',
                parameters: {
                    "app_id": config.server.app_id,
                    "version": config.version,
                    "device": config.device,
                    "source": { "media": "organic" },
                }
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 预登陆
    loginPre: () => {
        return new Promise<boolean>((resolve, reject) => {
            network.fetch({
                pathname: pathname.login_pre,
                method: 'POST',
                parameters: {
                    "app_id": config.server.app_id,
                    "udid": config.udid
                }
            }).then((response) => {
                resolve(response.method.guest as boolean)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 登录
    login: (parameters: {
        email: string,
        password: string
    }) => {
        return new Promise<string>((resolve, reject) => {
            network.fetch({
                pathname: pathname.login,
                method: 'POST',
                parameters: {
                    "app_id": config.server.app_id,
                    "udid": config.udid,
                    "email": parameters.email.toLowerCase(),
                    "password": md5(parameters.password),
                    "device": config.device
                }
            }).then((response) => {
                resolve(response["api_token"])
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 验证邮箱
    email: (parameters: {
        email: string
    }) => {
        return new Promise<object>((resolve, reject) => {
            network.fetch({
                pathname: pathname.email,
                method: 'GET',
                parameters: {
                    "account": parameters.email
                }
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 注册
    guestSignup: () => {
        return new Promise<string>((resolve, reject) => {
            network.fetch({
                pathname: pathname.login,
                method: 'POST',
                parameters: {
                    "app_id": config.server.app_id,
                    "udid": config.udid,
                    "profile": {
                        "gender": Gender.male
                    }
                }
            }).then((response) => {
                // 注册成功
                resolve(response["api_token"])
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 注册
    signup: (parameters: {
        email: string,
        password: string,
        gender: Gender,
        age: number,
        nickname: string
    }) => {
        return new Promise<string>((resolve, reject) => {
            network.fetch({
                pathname: pathname.login,
                method: 'POST',
                parameters: {
                    "app_id": config.server.app_id,
                    "udid": config.udid,
                    "email": parameters.email.toLowerCase(),
                    "password": md5(parameters.password),
                    "profile": {
                        "gender": parameters.gender,
                        "age": parameters.age,
                        "nickname": parameters.nickname
                    },
                    "device": config.device
                }
            }).then((response) => {
                // 注册成功
                resolve(response["api_token"])
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 上传文件
    upload: (parameters: { value: string | Blob, fileName: string }) => {
        return new Promise<object>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", parameters.value, parameters.fileName);
            network.fetch({
                pathname: pathname.upload,
                method: 'POST',
                parameters: formData
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 获取用户
    getAccount: () => {
        return new Promise<Account>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user,
                method: 'GET'
            }).then((response) => {
                let account = response as Account
                account.token = network.apiToken
                resolve(account)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 更新用户
    updateUser: (parameters: object) => {
        return new Promise<Account>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user,
                method: 'POST',
                parameters: parameters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 登录
    logout: () => {
        return new Promise<string>((resolve, reject) => {
            network.fetch({
                pathname: pathname.logout,
                method: 'POST'
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 黑名单
    userBlackList: (parameters: object) => {
        return new Promise<[Black]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_black_list,
                method: 'GET',
                parameters: parameters
            }).then((response) => {
                let blacks = response.blacks as [Black]
                if (blacks) {
                    resolve(blacks)
                } else {
                    reject()
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },

    // 拉黑
    userBlock: (id: number) => {
        return new Promise<any>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_block,
                method: 'POST',
                parameters: {
                    "user_id": id
                }
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 取消拉黑
    userUnblock: (id: number) => {
        return new Promise<any>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_unblock,
                method: 'POST',
                parameters: {
                    "user_id": id
                }
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 消费记录
    userExpends: (parameters: object) => {
        return new Promise<[Expense]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_expends,
                method: 'GET',
                parameters: parameters
            }).then((response) => {
                let expends = response.expends as [Expense]
                if (expends) {
                    resolve(expends)
                } else {
                    reject()
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 粉丝列表
    userFollowers: (parameters: object) => {
        return new Promise<User[]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_followers,
                method: 'GET',
                parameters: parameters
            }).then((response) => {
                let followers = response.followers as User[]
                if (followers) {
                    resolve(followers)
                } else {
                    reject()
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 关注列表
    userFollowing: (parameters: object) => {
        return new Promise<User[]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_followings,
                method: 'GET',
                parameters: parameters
            }).then((response) => {
                let following = response.followings as User[]
                if (following) {
                    resolve(following)
                } else {
                    reject()
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 粉丝列表
    userFollower: (parameters: object) => {
        return new Promise<User[]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_followers,
                method: 'GET',
                parameters: parameters
            }).then((response) => {
                let following = response.followers as User[]
                if (following) {
                    resolve(following)
                } else {
                    reject()
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 关注
    userFollow: (id: number) => {
        return new Promise<any>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_follow,
                method: 'POST',
                parameters: {
                    "user_id": id
                }
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 取消关注
    userUnfollow: (id: number) => {
        return new Promise<any>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_unfollow,
                method: 'POST',
                parameters: {
                    "user_id": id
                }
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 浏览记录
    userViews: (parameters: object) => {
        return new Promise<[Viewer]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_views,
                method: 'GET',
                parameters: parameters
            }).then((response) => {
                let views = response.views as [Viewer]
                if (views) {
                    resolve(views)
                } else {
                    reject()
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 获取通话记录
    callList: (parameters: object) => {
        return new Promise<[CallHistory]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.call_list,
                method: 'GET',
                parameters: parameters
            }).then((response) => {
                let calls = response.calls as [CallHistory]
                if (calls) {
                    resolve(calls)
                } else {
                    reject()
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 反馈
    feedback: (content: string) => {
        return new Promise<any>((resolve, reject) => {
            network.fetch({
                pathname: pathname.feedback,
                method: 'POST',
                parameters: {
                    "content": content
                }
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 举报
    report: (content: string, user_id: number) => {
        return new Promise<any>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_report,
                method: 'POST',
                parameters: {
                    "content": content,
                    "user_id": user_id
                }
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 绑定客户端
    pusherBind: (parameters: object) => {
        return new Promise<any>((resolve, reject) => {
            network.fetch({
                pathname: pathname.pusher_bind,
                method: 'POST',
                parameters: parameters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 呼叫
    call: (parameters: { callee_id: number, type: number }) => {
        return new Promise<Answer>((resolve, reject) => {
            network.fetch({
                pathname: pathname.call,
                method: 'POST',
                parameters: parameters
            }).then((response) => {
                resolve(response as Answer)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 挂断
    callHangup: (call_id: number) => {
        return new Promise<any>((resolve, reject) => {
            network.fetch({
                pathname: pathname.call_hangup,
                method: 'POST',
                parameters: { call_id: call_id }
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 接听
    callAnswer: (call_id: number) => {
        return new Promise<Answer>((resolve, reject) => {
            network.fetch({
                pathname: pathname.call_answer,
                method: 'POST',
                parameters: { call_id: call_id }
            }).then((response) => {
                resolve(response as Answer)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 接听
    getCall: (call_id: number) => {
        return new Promise<number>((resolve, reject) => {
            network.fetch({
                pathname: pathname.call,
                method: 'GET',
                parameters: { call_id: call_id }
            }).then((response) => {
                resolve(response.status as number)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 获取礼物列表
    giftGiftList: () => {
        return new Promise<[Gift]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.gift_list,
                method: 'GET'
            }).then((response) => {
                resolve(response.gifts as [Gift])
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 赠送礼物
    callGift: (parameters: any) => {
        return new Promise<object>((resolve, reject) => {
            network.fetch({
                pathname: pathname.call_gift,
                method: 'POST',
                parameters: parameters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 获取聊天凭证
    chatToken: () => {
        return new Promise<string>((resolve, reject) => {
            network.fetch({
                pathname: pathname.chat_token,
                method: 'GET',
            }).then((response) => {
                resolve(response.rtm_token as string)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 获取聊天凭证
    callComment: (parameters: object) => {
        return new Promise<string>((resolve, reject) => {
            network.fetch({
                pathname: pathname.call_comment,
                method: 'POST',
                parameters: parameters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 获取消息列表
    chatMessageList: (parameters: object) => {
        return new Promise<Message[]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.chat_message_list,
                method: 'GET',
                parameters: parameters
            }).then((response) => {
                resolve(response.messages as Message[])
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 批量获取用户
    userSummaryList: (parameters: object) => {
        return new Promise<MessageUser[]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_summary_list,
                method: 'POST',
                parameters: parameters
            }).then((response) => {
                resolve(response.users as MessageUser[])
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 获取用户详情
    getUserDetail: (user_id: string) => {
        return new Promise<User>((resolve, reject) => {
            network.fetch({
                pathname: pathname.user_detail,
                method: 'GET',
                parameters: { user_id: user_id }
            }).then((response) => {
                resolve(response as User)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 发送消息
    sendMessage: (parameters: object) => {
        return new Promise<string>((resolve, reject) => {
            network.fetch({
                pathname: pathname.chat_message,
                method: 'POST',
                parameters: parameters
            }).then((response) => {
                resolve(response.msg_id as string)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 获取客服
    getSupport: () => {
        return new Promise<MessageUser>((resolve, reject) => {
            network.fetch({
                pathname: pathname.support,
                method: 'GET'
            }).then((response) => {
                resolve(response as MessageUser)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 获取主播列表
    getAnchorList: (parameters: object) => {
        return new Promise<[User]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.anchor_list,
                method: 'GET',
                parameters: parameters
            }).then((response) => {
                resolve(response.anchors as [User])
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 获取充值项
    getTokenList: () => {
        return new Promise<[Recharge]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.token_list,
                method: 'GET'
            }).then((response) => {
                let findIndex = response.tokens.findIndex((item: { gid: number; }) => item.gid === (config.platform === 'android' ? 2 : 1))
                if (findIndex >= 0) {
                    resolve(response.tokens[findIndex].items as [Recharge])
                } else {
                    reject()
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 获取权益
    getRight: () => {
        return new Promise<[Right]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.right,
                method: 'GET'
            }).then((response) => {
                resolve(response.rights as [Right])
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 获取排行榜
    getRank: (parameters: object) => {
        return new Promise<[Rank]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.rank,
                method: 'GET',
                parameters: parameters
            }).then((response) => {
                resolve(response.rank as [Rank])
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 获取签到
    getCheckin: () => {
        return new Promise<[CheckIns]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.checkin,
                method: 'GET',
            }).then((response) => {
                resolve(response as [CheckIns])
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 签到
    postCheckin: (parameters?: object) => {
        return new Promise<any>((resolve, reject) => {
            network.fetch({
                pathname: pathname.checkin,
                method: 'POST',
                parameters: parameters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 获取广告
    getAdList: (parameters?: object) => {
        return new Promise<[ADBanner]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.ad_list,
                method: 'GET',
                parameters: parameters
            }).then((response) => {
                resolve(response.ads as [ADBanner])
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 领取权益
    applyRight: (parameters?: object) => {
        return new Promise<any>((resolve, reject) => {
            network.fetch({
                pathname: pathname.right_apply,
                method: 'POST',
                parameters: parameters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 上传文件
    translate: (parameters: object) => {
        return new Promise<any>((resolve, reject) => {
            network.fetch({
                pathname: pathname.trans,
                method: 'POST',
                parameters: parameters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    /// 视频列表
    videoList: (parameters: object) => {
        return new Promise<any[]>((resolve, reject) => {
            network.fetch({
                pathname: pathname.video_list,
                method: 'GET',
                parameters: parameters
            }).then((response) => {
                resolve(response.videos as any[])
            }).catch((error) => {
                reject(error)
            })
        })
    },

}

export default api