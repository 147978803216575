import { api } from 'server';
import { StorageKey, Message, MessageUser, GlobalEvent, WebSocketEvent, WebSocketCode, MessageState, Role, User, NetworkError, MessageType, Account } from 'models';
import { Calculator, Storage } from 'utils';
import store from 'store';
import { Notification, Toast } from 'components';
import { updateUsers } from 'store/users';
import { updateBadge } from 'store/app';
import { NotificationCenter } from 'utils';
import { updateRechargeVisible } from 'store/app';
import webDB from './webDB';

const messages = {
    userId: 0,
    directPeerId: 0,
    sendingIds: new Set(),
    isUpdating: false,
    hiddenTime: 0,
    sendMessage: async (value: Message, beforeSendHandler?: () => void, completion?: (isSuccess: boolean) => void) => {
        let message = { ...value }
        try {
            message.state = MessageState.error
            messages.sendingIds.add(message.msg_id)
            let newMessage = { ...message }
            // 先插入
            await messages.handleInsertMessages([message])
            beforeSendHandler && beforeSendHandler()
            // 发送数据
            const msg_id = await api.sendMessage({
                to: message.to,
                to_type: 1,
                content: message.content
            })
            // 删除旧消息
            messages.sendingIds.delete(message.msg_id)
            newMessage.state = MessageState.success
            newMessage.msg_id = msg_id
            await messages.handleDeleteMessages([message])
            // 插入新消息
            await messages.handleInsertMessages([newMessage])
            // 插入新消息
            completion && completion(true)
        } catch (err) {
            messages.sendingIds.delete(message.msg_id)
            await messages.handleInsertMessages([message])
            if (err) {
                let error = err as NetworkError
                if (error && error.code === 2001) {
                    Toast.show({ content: error.message })
                    updateRechargeVisible(true)(store.dispatch)
                }
            }
            completion && completion(false)
        }
    },
    updateFromRemote: async (completion?: (isSuccess: boolean) => void) => {
        if (messages.isUpdating === true) {
            completion && completion(false)
            return
        }
        try {
            messages.isUpdating = true
            // 判断请求条件
            let start_at = Storage.getItem(StorageKey.message_startat)
            let parameters: any = {}
            if (start_at && !isNaN(Number(start_at))) {
                parameters["filter[start_at]"] = Number(start_at) + 1
            }
            // 从服务端获取最新消息
            let items = await api.chatMessageList(parameters)
            items = items.filter((item) => {
                if (item.content?.type === MessageType.image && !item.content.url) {
                    return false
                }
                return true
            })
            // 插入消息
            await messages.handleInsertMessages(items)
            // 更新最近消息时间
            messages.handleUpateLastMessageTime(items)
            completion && completion(true)
            messages.isUpdating = false
        } catch {
            completion && completion(false)
            messages.isUpdating = false
        }
    },
    handleInsertMessages: async (value: Message[]) => {
        let items = [...value]
        return new Promise<void>(async (resolve, reject) => {
            // 获取消息对应的用户ids
            let uids = Array.from(new Set(items.map(item => Calculator.getPeerId(item)).filter((element): element is number => {
                return element !== null;
            })))
            // 计算出已经存在的消息用户
            let existedUsers = await webDB.queryUsers(uids)
            // 计算出需要请求的消息用户
            let needFetchUserIds = uids.filter((item) => {
                return existedUsers.findIndex(e => e.id === item) === -1
            })
            // 设置user处理函数
            let blockHandler = async (value: MessageUser[]) => {
                try {
                    let users = messages.remakeUsers(value, items)
                    // 插入用户数据
                    await webDB.insertUsers(users)
                    // 触发user redux更新
                    await updateUsers(users)(store.dispatch)
                    // 插入消息数据
                    await webDB.insertMessages(items)
                    // 触发通知
                    NotificationCenter.post(GlobalEvent.messageDidUpdate, items)
                    // 更新badge
                    messages.updateBadge(store.getState().users.value)
                    let account = store.getState().account.value as unknown as Account
                    // 触发通知弹窗
                    if (users.length > 0) {
                        let findIndex = items.findIndex((item) => item.msg_id === users[0].message?.msg_id)
                        if (findIndex >= 0 && account && users[0].id != account.id && messages.directPeerId !== users[0].id && items[findIndex].sent_at && ((new Date().getTime()) / 1000 - items[findIndex].sent_at! < 60)) {
                            Notification.show(items[findIndex], users[0])
                        }
                    }
                    resolve()
                } catch {
                    reject()
                }
            }
            if (needFetchUserIds.length === 0) {
                blockHandler(existedUsers)
            } else {
                api.userSummaryList({
                    ids: needFetchUserIds
                }).then((remoteUsers) => {
                    blockHandler([...existedUsers, ...remoteUsers,].filter((element): element is MessageUser => { return element !== null; }))
                }).catch(() => {
                    reject()
                })
            }
        })
    },
    handleDeleteMessages: async (items: Message[]) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                let ids = items.map((item) => item.msg_id)
                // 删除消息数据
                await webDB.deleteMessages(ids)
                // 触发通知
                NotificationCenter.post(GlobalEvent.messageDidDelete, items)
                // 完成数据更新
                resolve()
            } catch {
                reject()
            }
        })
    },
    handleUpateLastMessageTime: (items: Message[]) => {
        if (items.length > 0) {
            let sent_at: number | undefined = undefined
            items.forEach((item) => {
                if (!sent_at) {
                    sent_at = item.sent_at
                } else if (item.sent_at && sent_at && item.sent_at > sent_at) {
                    sent_at = item.sent_at
                }
            })
            if (sent_at) {
                Storage.setItem(StorageKey.message_startat, sent_at)
            }
        }
    },
    remakeUsers: (users: MessageUser[], items: Message[], updateBadge: boolean = true) => {
        var newUsers = users
        newUsers.forEach((user, userIndex) => {
            items.forEach((message) => {
                if (user.id === Calculator.getPeerId(message)) {
                    // 更新最新消息
                    if (!user.message) {
                        newUsers[userIndex].message = message
                    } else if (user.message && user.message.sent_at && message.sent_at && message.sent_at > user.message.sent_at) {
                        newUsers[userIndex].message = message
                    }
                    // 更新角标
                    if (updateBadge && Storage.getItem(StorageKey.message_startat) && Calculator.userId !== message.sender_id && messages.directPeerId !== message.sender_id) {
                        let badge = newUsers[userIndex].badge
                        if (badge) {
                            newUsers[userIndex].badge = badge + 1
                        } else {
                            newUsers[userIndex].badge = 1
                        }
                    }
                }
            })
        })
        return newUsers
    },
    keep: () => {
        NotificationCenter.addObserver(GlobalEvent.websocket, (value) => {
            let event = (value as any).detail as WebSocketEvent
            if (event.event_code === WebSocketCode.message) {
                messages.updateFromRemote()
            }
        })
        document.addEventListener('visibilitychange', function () {
            if (store.getState().account.value) {
                if (document.visibilityState === 'hidden') {
                    messages.hiddenTime = new Date().getTime()	//记录页面隐藏时间
                } else {
                    let visibleTime = new Date().getTime();
                    let account = store.getState().account.value
                    if (account && messages.hiddenTime && (visibleTime - messages.hiddenTime) / 1000 > 2) {
                        messages.updateFromRemote()
                    }
                }
            }
        });
    },
    clearBadge: async (uid: number) => {
        try {
            let users = await webDB.queryUsersAll()
            let findIndex = users.findIndex((item) => item.id === uid)
            if (findIndex >= 0) {
                users[findIndex].badge = 0
            }
            // 插入用户数据
            webDB.insertUsers(users)
            // 触发user redux更新
            updateUsers(users)(store.dispatch)
            // 更新badge
            messages.updateBadge(users)
        } catch { }
    },
    updateBadge: async (values?: MessageUser[]) => {
        try {
            let users = values ? values : await webDB.queryUsersAll()
            let badges = 0
            users.forEach((item) => {
                badges += item.badge ?? 0
            })
            // 更新badge
            updateBadge(badges)(store.dispatch)
        } catch { }
    },
    updateUser: async (value: User) => {
        if (value.id) {
            webDB.queryUser(value.id)
                .then(async (user) => {
                    try {
                        // 查询成功
                        let newValue = { ...user }
                        newValue.profile = value.profile
                        // 插入用户数据
                        await webDB.insertUsers([newValue])
                        // 触发user redux更新
                        await updateUsers([newValue])(store.dispatch)
                    } catch { }
                }).catch(async () => {
                    // 查询失败
                    try {
                        if (value.id && value.role) {
                            let newValue: MessageUser = {
                                id: value.id,
                                role: value.role,
                                profile: value.profile,
                            }
                            // 插入用户数据
                            await webDB.insertUsers([newValue])
                            // 触发user redux更新
                            await updateUsers([newValue])(store.dispatch)
                        }
                    } catch { }
                })
        }
    },
    checkSupport: async () => {
        try {
            let users = store.getState().users.value
            let account = store.getState().account.value as any
            let findIndex = users.findIndex((item) => item.role === Role.support)
            if (findIndex < 0) {
                let support = await api.getSupport()
                if (support.id && account.id) {
                    let values = await webDB.queryMessageByAssociationId(support.id, account.id)
                    let result = messages.remakeUsers([support], values, false)
                    if (result.length > 0) {
                        support = result[0]
                    }
                }
                // 插入用户数据
                await webDB.insertUsers([support])
                // 触发user redux更新
                await updateUsers([support])(store.dispatch)
            }
        } catch { }
    }
}

messages.keep()

export default messages