import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Header } from 'components';
import { useEdit } from 'hooks';
import '../EditPage.scss';
// @ts-ignore
import autosize from "autosize";

const EditAboutPage = React.memo(() => {
    const textarea = useRef(null)
    const { t } = useTranslation()
    const { handleSave } = useEdit()
    const [mood, setMood] = useState("");
    const account = useSelector(state => (state as any).account).value
    useEffect(() => {
        setMood(account.profile.mood)
        autosize(textarea.current)
    }, [account, textarea])

    return (
        <div className='edit-other-page'>
            <Header title={t('personal.about')}><button className='save-button touch-opacity' onClick={() => handleSave({ mood: mood })}>{t('common.save')}</button></Header>
            <div className='text-wrapper'>
                <textarea
                    ref={textarea}
                    className='textarea'
                    placeholder={t('edit.personal.about.placeholder')}
                    value={mood}
                    onChange={(e) => setMood(e.target.value)}
                ></textarea>
            </div>
        </div>
    );
})

export default EditAboutPage;