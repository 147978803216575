import React from "react";
import { List, AnchorCell } from 'components';
import { api } from 'server';
import { User } from 'models';
import '../HotPage.scss';

const HotTopPage = React.memo(() => {

    const onFetch = (page: number, pageSize: number, startFetch: (data: User[]) => void, abortFetch: (error?: Error) => void) => {
        api.getAnchorList({
            page: page,
            per_page: pageSize,
            "filter[quick]": "rank"
        }).then((items) => {
            startFetch(items)
        }).catch(() => {
            abortFetch()
        })
    }

    return (
        <div className="hot-page-container top">
            <List
                className='list'
                onFetch={onFetch}
                renderItem={(item, index) => <AnchorCell key={index} item={item} ></AnchorCell>}
            ></List>
        </div>
    )
})

export default HotTopPage;