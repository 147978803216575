import React from 'react';
import classNames from 'classnames';
import './Avatar.scss';

const Avatar = React.memo((props: {
    src?: string;
    style?: React.CSSProperties;
    className?: string;
}) => {
    const { src, style, className } = props;
    return (
        <div className={classNames('avatar-component', className)} style={style}>
            <div className='img' style={src !== undefined && src?.length > 0 ? { backgroundImage: `url(${src})` } : undefined} />
        </div>
    )
})

export default Avatar;