import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Header, ListLoadingView, ListErrorView, Avatar, ActionSheet, VideoViwer } from 'components';
import { useDetail } from 'hooks';
import { Gender, PagePath, Role } from 'models';
import './DetailPage.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import classNames from 'classnames';

const DetailPage = React.memo(() => {
    const account = useSelector(state => (state as any).account).value
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { user, pageState, videos, lovers, handleCall, handleNavigateMessage, handleFollow, handleBlock } = useDetail()
    const [bannerIndex, setBannerIndex] = useState<number>(0)
    const [moreVisible, setMoreVisible] = useState<boolean>(false)
    const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined)
    const price = user?.anchor && user?.anchor.price ? user?.anchor.price : undefined
    const rating = user?.anchor && user?.anchor.comment && user?.anchor.comment.rating ? user?.anchor.comment.rating : undefined
    const isNormal = user?.role !== Role.support && user?.id !== account.id
    const isIOS = !!navigator.platform.match(/iPhone|iPod|iPad/)
    return (
        <div className='detail-page'>
            <Header title={user?.profile.nickname}>
                {pageState === "clear" && isNormal && <div className='more-button touch-opacity' onClick={() => setMoreVisible(true)}></div>}
            </Header>
            <ActionSheet
                visible={moreVisible}
                onClose={() => setMoreVisible(false)}
                actions={[
                    {
                        title: user?.is_blocked === true ? t('detail.unblock') : t('detail.block'), handler: () => {
                            handleBlock()
                        }
                    },
                    {
                        title: t('detail.report'), handler: () => {
                            navigate(PagePath.system_report + "/" + user?.id)
                        }
                    }
                ]} />
            {
                pageState === "error" ?

                    <div className='page-state'>
                        <ListErrorView></ListErrorView>
                    </div>
                    :
                    pageState === "loading" ?

                        <div className='page-state'>
                            <ListLoadingView></ListLoadingView>
                        </div>
                        :
                        <>
                            {/* banner */}
                            {
                                user?.photos && user?.photos?.length > 0 ?

                                    <div className='banner'>
                                        {/* banner位置指示器 */}
                                        {
                                            user?.photos?.length && user?.photos?.length > 0 ?
                                                <div className='page-indicator'>{bannerIndex + 1}/{user?.photos?.length}</div>
                                                :
                                                <></>
                                        }
                                        {/* 轮播 */}
                                        {
                                            <Swiper
                                                autoplay={false}
                                                className='content-view'
                                                onSlideChange={(swiper) => setBannerIndex(swiper.activeIndex)}
                                            >
                                                {
                                                    user?.photos?.map((item, index) => {
                                                        return (
                                                            <SwiperSlide className='banner-item' key={index}>
                                                                <div className='image' style={{
                                                                    backgroundImage: `url(${item})`
                                                                }}></div>
                                                            </SwiperSlide>
                                                        )
                                                    })
                                                }
                                            </Swiper>
                                        }
                                    </div> :
                                    <div className='banner-avatar'>
                                        <div className='banner-avatar-container'>
                                            <Avatar className='avatar'></Avatar>
                                        </div>
                                    </div>
                            }
                            {/* headline */}
                            <div className='headline'>
                                {/* 名字头像区域 */}
                                <div className='section-1'>
                                    <Avatar className='avatar' src={user?.profile.head}></Avatar>
                                    <div className='text-container'>
                                        <div className='nickname-label'>{user?.profile.nickname}</div>
                                        <div className='id-label'>ID: {user?.id}</div>
                                    </div>
                                    {price && <div className='price-view'>
                                        <div className='price-image'></div>
                                        <div className='price-label'>{price}/min</div>
                                    </div>}
                                </div>
                                {/* 评分 */}
                                {
                                    rating && rating > 0 &&
                                    <div className='section-2'>
                                        {rating >= 1 && <div className='star'></div>}
                                        {rating >= 2 && <div className='star'></div>}
                                        {rating >= 3 && <div className='star'></div>}
                                        {rating >= 4 && <div className='star'></div>}
                                        {rating >= 5 && <div className='star'></div>}
                                        <div className='star-label'>{rating}</div>
                                    </div>
                                }
                                {/* 消息关注 */}
                                {
                                    isNormal &&
                                    <div className='section-3'>
                                        <div className='message-button touch-without-feedback' onClick={() => { handleNavigateMessage() }}>
                                            <div className='message-image'></div>
                                            <div className='label'>{t('detail.message')}</div>
                                        </div>
                                        <div className='follow-button touch-without-feedback' onClick={() => { handleFollow() }}>
                                            {user?.is_following === false && <div className='follow-image'></div>}
                                            <div className='label'>{user?.is_following === false ? t('detail.follow') : t('detail.following')}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* 视频秀 */}
                            {
                                Array.isArray(videos) && videos.length > 0 ?
                                    <div className='videos'>
                                        <div className='videos-title'>{t('detail.videos')}</div>
                                        <div className='video-list'>
                                            {
                                                Array.isArray(videos) && videos.map((item, index) => {
                                                    return (
                                                        <div className='video-list-item' key={index} style={{
                                                            backgroundImage: `url(${item.cover})`
                                                        }}
                                                            onClick={!isIOS ? () => setVideoUrl(item.file.url) : undefined}
                                                        >
                                                            {isIOS && <video src={item.file.url} controls autoPlay className='video'></video>}
                                                        </div>
                                                    )
                                                })
                                            }
                                            {

                                            }
                                            {!isIOS && videoUrl && <VideoViwer src={videoUrl} onClose={() => setVideoUrl(undefined)}></VideoViwer>}
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                            {/* 简介 */}
                            {
                                user?.profile.mood &&
                                <div className='about'>
                                    <div className='about-title'>{t('detail.about')}</div>
                                    <div className='about-label'>{user?.profile.mood}</div>
                                </div>
                            }
                            {/* 排行榜 */}
                            {
                                lovers.length > 0 ?
                                    <div className='rank'>
                                        <div className='rank-header'>
                                            <div className='rank-title'>{t('detail.rank')}</div>
                                            <Link className='rank-more-button touch-opacity' to={PagePath.anchor_lover + "/" + user?.id}>
                                                <div className='rank-move-label'>{t('detail.rank-more')}</div>
                                                <div className='rank-move-arrow'></div>
                                            </Link>
                                        </div>
                                        <div className='rank-container'>
                                            {
                                                lovers.map((item, index) => {
                                                    return (
                                                        <div className='rank-container-item'>
                                                            <div className={`rank-top top${index + 1}`}></div>
                                                            <div className={`rank-avatar top${index + 1}`}>
                                                                <div className='rank-container-item-main'>
                                                                    <Avatar className='rank-container-item-avatar' src={item.profile?.head}></Avatar>
                                                                </div>
                                                            </div>
                                                            <div className='rank-label'>{item.score}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                            {/* 隐私 */}
                            <div className='personal'>
                                <div className='personal-title'>{t('detail.personal')}</div>
                                <div className='personal-list'>
                                    {user?.profile.age ? <DetailPersonalItemView type="age" text={`${t('personal.age')}: ${user?.profile.age}`} /> : <></>}
                                    {user?.profile.gender === Gender.male ? <DetailPersonalItemView type="gender-male" text={`${t('personal.gender')}: ${t('personal.gender-male')}`} /> : <></>}
                                    {user?.profile.age === Gender.female ? <DetailPersonalItemView type="gender-female" text={`${t('personal.gender')}: ${t('personal.gender-female')}`} /> : <></>}
                                    {user?.profile.attrs.height && user?.profile.attrs.height > 0 ? <DetailPersonalItemView type="height" text={`${t('personal.height')}: ${Number(user?.profile.attrs.height)}cm`} /> : <></>}
                                    {user?.profile.attrs.weight && user?.profile.attrs.weight > 0 ? <DetailPersonalItemView type="weight" text={`${t('personal.weight')}: ${Number(user?.profile.attrs.weight)}kg`} /> : <></>}
                                    {user?.profile.attrs.hair_color ? <DetailPersonalItemView type="hair" text={`${t('personal.hair')}: ${user?.profile.attrs.hair_color}`} /> : <></>}
                                    {user?.profile.attrs.body_type ? <DetailPersonalItemView type="body" text={`${t('personal.body')}: ${user?.profile.attrs.body_type}`} /> : <></>}
                                    {user?.profile.attrs.belong ? <DetailPersonalItemView type="belong" text={`${t('personal.belong')}: ${user?.profile.attrs.belong}`} /> : <></>}
                                    {user?.profile.attrs.constellation ? <DetailPersonalItemView type="constellation" text={`${t('personal.constellation')}: ${user?.profile.attrs.constellation}`} /> : <></>}
                                </div>
                            </div>
                            {
                                isNormal &&
                                <div className='footer-view'>
                                    <div className={classNames(
                                        'touch-without-feedback',
                                        'content-view',
                                        user?.is_online === true && user?.is_busy !== true && 'online',
                                        user?.is_online === false && 'offline',
                                        user?.is_busy === true && 'busy'
                                    )}
                                        onClick={() => { handleCall() }}
                                    >
                                        <div className='icon'></div>
                                        <div className='label'>{
                                            user?.is_busy === true ?
                                                t('detail.busy')
                                                :
                                                (
                                                    user?.is_online === false ?
                                                        t('detail.offline')
                                                        :
                                                        t('detail.call')
                                                )
                                        }</div>
                                    </div>
                                </div>
                            }
                        </>
            }
        </div>
    );
})


const DetailPersonalItemView = React.memo((props: {
    type: string,
    text?: string
}) => {
    return (
        <div className='personal-item-view'>
            <div className={`item-icon ${props.type}`}></div>
            <div className='item-label'>{props.text}</div>
        </div>
    )
})

export default DetailPage;