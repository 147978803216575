import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import jp from './jp.json';
import zhHant from './zh-Hant.json';

const resources = {
    en: {
        translation: en,
    },
    jp: {
        translation: jp,
    },
    zhHant: {
        translation: zhHant,
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: (() => {
        if (navigator.language.indexOf("zh") >= 0) {
            return "zhHant"
        } else if (navigator.language.indexOf("jp") >= 0) {
            return "jp"
        }
        return "en"
    })(),
    interpolation: {
        escapeValue: false,
    },
});