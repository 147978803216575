import React from "react";
import { PagePath, User } from 'models';
import { Link } from "react-router-dom";
import './AnchorCell.scss';

const AnchorCell = React.memo((props: { item: User, onClick?: () => void }) => {
    const { item, onClick } = props
    const rating = item.anchor && item.anchor.comment && item.anchor.comment.rating ? item.anchor.comment.rating : undefined
    return (
        <Link className='anchor-cell' to={PagePath.anchor_detail + `/${item.id}`}
            onClick={() => {
                typeof onClick === 'function' && onClick()
            }} >
            <div className='image' style={item.profile.head ? { backgroundImage: `url(${item.profile.head})` } : undefined}></div>
            <div className='mask'></div>
            <div className='bottom-content'>
                <div className='nickname-label'>{item.profile.nickname}</div>
            </div>
            {
                rating && rating > 0 &&
                <div className='rate-view'>
                    <div className='rate-image'></div>
                    <div className='rate-label'>{rating}</div>
                </div>
            }
        </Link>
    )
})

export default AnchorCell