import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Header, select } from 'components';
import { useEdit } from 'hooks';
import '../EditPage.scss';

const EditConstellationPage = React.memo(() => {
    const { t } = useTranslation()
    const { handleSave } = useEdit()
    const [constellation, setConstellation] = useState("");
    const account = useSelector(state => (state as any).account).value
    useEffect(() => {
        setConstellation(account.profile.attrs.constellation)
    }, [account])
    const constellationOptions = select.constellationOptions
    return (
        <div className='edit-other-page'>
            <Header title={t('personal.constellation')}><button className='save-button touch-opacity' onClick={() => handleSave({ attrs: { constellation: constellation } })}>{t('common.save')}</button></Header>
            <Select
                styles={select.styleConfig}
                className='select-wrapper'
                value={constellationOptions.find((item) => { return typeof (constellation) === 'string' ? item.value.toLowerCase() === constellation.toLowerCase() : undefined }) ?? constellationOptions[0]}
                options={constellationOptions}
                onChange={(value) => {
                    let obj = value as any
                    setConstellation(obj.value as string)
                }}
            />
        </div>
    );
})

export default EditConstellationPage;