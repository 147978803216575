
import { StylesConfig } from 'react-select';
import i18n from 'i18next';
import { HairColor, BodyType, Belong, Constellation } from '../../models/Enums';

const selectStyleConfig: StylesConfig = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'var(--system-pink)' : 'var(--tertiary-background)',
    fontSize: 'var(--body)'
  }),
  control: (provided, state) => ({
    ...provided,
    background: 'var(--background)',
    borderColor: 'var(--separator)',
    boxShadow: state.isFocused ? "0 0 0 1px var(--system-pink)" : "none"
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'var(--separator)'
  }),
  menu: provided => ({
    ...provided,
    background: 'var(--tertiary-background)'
  }),
  singleValue: provided => ({
    ...provided,
    color: 'var( --text-primary)',
    fontSize: 'var(--body)'
  })
}

const ageOptions = new Array(82).toString().split(',').map(function (item, index) {
  return { value: index + 18, label: `${index + 18}` };
});

const heightOptions = new Array(250).toString().split(',').map(function (item, index) {
  return { value: index + 100, label: `${index + 100}cm` };
});

const weightOptions = new Array(500).toString().split(',').map(function (item, index) {
  return { value: index + 30, label: `${index + 30}kg` };
});

const hairOptions = [
  { value: "", label: i18n.t('personal.none') },
  { value: HairColor.black, label: i18n.t('personal.hair-black') },
  { value: HairColor.blond, label: i18n.t('personal.hair-blond'), },
  { value: HairColor.brown, label: i18n.t('personal.hair-brown') },
  { value: HairColor.red, label: i18n.t('personal.hair-red') }
]

const bodyOptions = [
  { value: "", label: i18n.t('personal.none') },
  { value: BodyType.petite, label: i18n.t('personal.body-petite') },
  { value: BodyType.athletic, label: i18n.t('personal.body-athletic'), },
  { value: BodyType.curvy, label: i18n.t('personal.body-curvy') },
  { value: BodyType.bbw, label: i18n.t('personal.body-bbw') }
]

const belongOptions = [
  { value: "", label: i18n.t('personal.none') },
  { value: Belong.asian, label: i18n.t('personal.belong-asian') },
  { value: Belong.caucasian, label: i18n.t('personal.belong-caucasian'), },
  { value: Belong.ebony, label: i18n.t('personal.belong-ebony') },
  { value: Belong.latina, label: i18n.t('personal.belong-latina') }
]

const constellationOptions = [
  { value: "", label: i18n.t('personal.none') },
  { value: Constellation.aries, label: i18n.t('personal.constellation-aries') },
  { value: Constellation.taurus, label: i18n.t('personal.constellation-taurus') },
  { value: Constellation.gemini, label: i18n.t('personal.constellation-gemini') },
  { value: Constellation.cancer, label: i18n.t('personal.constellation-cancer') },
  { value: Constellation.leo, label: i18n.t('personal.constellation-leo') },
  { value: Constellation.virgo, label: i18n.t('personal.constellation-virgo') },
  { value: Constellation.libra, label: i18n.t('personal.constellation-libra') },
  { value: Constellation.scorpio, label: i18n.t('personal.constellation-scorpio') },
  { value: Constellation.sagittarius, label: i18n.t('personal.constellation-sagittarius') },
  { value: Constellation.capricorn, label: i18n.t('personal.constellation-capricorn') },
  { value: Constellation.aquarius, label: i18n.t('personal.constellation-aquarius') },
  { value: Constellation.pisces, label: i18n.t('personal.constellation-pisces') },
]

const select = {
  styleConfig: selectStyleConfig,
  ageOptions: ageOptions,
  heightOptions: heightOptions,
  weightOptions: weightOptions,
  hairOptions: hairOptions,
  bodyOptions: bodyOptions,
  belongOptions: belongOptions,
  constellationOptions: constellationOptions
}

export default select