import { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SVGA from 'svgaplayerweb';
import { Account, Channel, Gift, GiftPresent, CustomRtmMessage, WebSocketCode, WebSocketEvent, GlobalEvent, NetworkError } from 'models';
import { rtc, rtm, api } from 'server';
import { Calculator, Log, NotificationCenter } from 'utils';
import { Loading, Toast } from 'components';
import config from 'config';
import store from 'store';
import { updateGiftsRemote } from 'store/gifts';
import { addConversationMessage, replaceConversationMessage } from 'store/conversation';
import { updateRechargeVisible } from 'store/app';
import { analysisLogEvent, AnalysisEventName } from 'analysis';

// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

const playSVGAAnimation = (id: number) => {
    var player = new SVGA.Player('#gift-svga-player');
    var parser = new SVGA.Parser()
    parser.load(`${process.env.PUBLIC_URL}/svga/gift_${id}.svga`, function (videoItem) {
        player.setVideoItem(videoItem);
        player.loops = 1
        player.startAnimation();
    })
}

function useConversation() {
    const navigate = useNavigate()
    const conversationValue = useSelector(state => (state as any).conversation).value as Channel
    const publishedDate = useSelector(state => (state as any).conversation).publishedDate as Date
    const [peer, setPeer] = useState<any>(null)
    const [currentDate, setCurrentDate] = useState<Date>(new Date())
    const [text, setText] = useState<string>('')
    const [giftPresent, setGiftPresent] = useState<GiftPresent | undefined>(undefined)

    useEffect(() => {
        // 初始化页面，加入房间
        const channel = (store.getState().conversation as any).value as Channel
        if (channel) {
            rtc.join({
                appId: config.server.agora_id,
                channel: channel.channel_id,
                token: channel.channel_token,
            })
            setPeer(channel.peer)
        }
    }, [setPeer])

    useEffect(() => {
        // 执行每秒定时器
        let timerInterval = setInterval(() => {
            setCurrentDate(new Date())
        }, 1000)
        return () => {
            clearInterval(timerInterval)
        }
    }, [])

    useEffect(() => {
        // 监听余额不足
        const listener = (value: any) => {
            let event = (value as any).detail as WebSocketEvent
            if (event.event_code === WebSocketCode.balance && event.data && event.data.biz && event.data.biz.notify_recharge) {

            }
        }
        NotificationCenter.addObserver(GlobalEvent.websocket, listener)
        return (() => {
            NotificationCenter.removeObserver(GlobalEvent.websocket, listener)
        })
    }, [])

    useEffect(() => {
        // 监听收到对方礼物
        const listener = (value: any) => {
            let event = (value as any).detail as WebSocketEvent
            const conversation = (store.getState().conversation as any).value as Channel
            if (conversation && event.event_code === WebSocketCode.gift && typeof event.data.gift_id === 'number') {
                let message: CustomRtmMessage = {
                    senderid: `${conversation.peer.id}`,
                    senderhead: conversation.peer.profile?.head,
                    sendernickname: conversation.peer.profile?.nickname,
                    senderrole: conversation.peer.role,
                    sendergender: conversation.peer.profile?.gender,
                    senderage: conversation.peer.profile?.age,
                    messagetype: 6,
                    giftid: event.data.gift_id,
                    messageid: uuidv4(),
                    sendtime: Math.ceil(new Date().getTime() / 1000),
                }
                addConversationMessage(message)(store.dispatch)
                setGiftPresent({
                    create_date: new Date(),
                    gift_id: event.data.gift_id,
                    nickname: conversation.peer.profile?.nickname,
                    head: conversation.peer.profile?.head,
                })
                playSVGAAnimation(event.data.gift_id)
            }
        }
        NotificationCenter.addObserver(GlobalEvent.websocket, listener)
        return () => {
            NotificationCenter.removeObserver(GlobalEvent.websocket, listener)
        }
    }, [])

    useEffect(() => {
        if (!conversationValue) {
            navigate(-1)
        }
    }, [conversationValue, navigate])

    // 计算持续时间
    const duration = useMemo(() => {
        if (currentDate && publishedDate) {
            return Calculator.getDurationFromDate(currentDate, publishedDate)
        } else {
            return "00:00:00"
        }
    }, [currentDate, publishedDate])

    // 发送消息
    const handleSendText = useCallback(() => {
        const account = (store.getState().account as any).value as Account
        if (peer.id && text && account.id) {
            let message: CustomRtmMessage = {
                senderid: `${account.id}`,
                senderhead: account.profile?.head,
                sendernickname: account.profile?.nickname,
                senderrole: account.role,
                sendergender: account.profile?.gender,
                senderage: account.profile?.age,
                messagetype: 0,
                messageid: uuidv4(),
                message: text,
                sendtime: Math.ceil(new Date().getTime() / 1000),
            }
            addConversationMessage(message)(store.dispatch)
            rtm.sendMessage(message, `${peer.id}`, (isSuccess) => {
                Log.print(isSuccess, "消息是否发送成功")
            })
        }
        setText('')
    }, [text, setText, peer])

    // 点击退出
    const handleExit = useCallback(() => {
        const channel = (store.getState().conversation as any).value as Channel
        if (channel.call_id) {
            api.callHangup(channel.call_id)
        }
        rtc.leave()
    }, [])

    // 点击关注
    const handleFollow = useCallback(async () => {
        if (peer && peer.id) {
            let loading = Loading.show()
            try {
                await api.userFollow(peer.id)
                let newPeer = { ...peer }
                newPeer.is_following = true
                setPeer(newPeer)
                Loading.dismiss(loading)
            } catch {
                Loading.dismiss(loading)
            }
        }
    }, [peer, setPeer])

    // 切换前后摄像头
    const handleCameraToggle = useCallback(async () => {
        analysisLogEvent(AnalysisEventName.stream_reverse_click)
        rtc.switchCamera()
    }, [])

    // 切换是否禁用摄像头
    const handleMutedToggle = useCallback(async () => {
        analysisLogEvent(AnalysisEventName.stream_camera_click)
        rtc.togglMuted()
    }, [])

    // 点击礼物按钮
    const handleGiftButtonClick = useCallback(() => {
        if (store.getState().gifts.value.length <= 0) {
            updateGiftsRemote()(store.dispatch)
        }
    }, [])

    // 点击发送礼物
    const handleGiftSend = useCallback(async (gift: Gift) => {
        const channel = (store.getState().conversation as any).value as Channel
        const account = (store.getState().account as any).value as Account
        if (channel && channel.call_id && gift && gift.id) {
            analysisLogEvent(AnalysisEventName.stream_gift_send)
            let loading = Loading.show()
            try {
                await api.callGift({
                    call_id: channel.call_id,
                    gift_id: gift.id
                })
                Loading.dismiss(loading)
                let giftP = {
                    create_date: new Date(),
                    gift_id: gift.id,
                    nickname: account.profile?.nickname,
                    head: account.profile?.head,
                }
                setGiftPresent(giftP)
                // 插入礼物消息
                let message: CustomRtmMessage = {
                    senderid: `${account.id}`,
                    senderhead: account.profile?.head,
                    sendernickname: account.profile?.nickname,
                    senderrole: account.role,
                    sendergender: account.profile?.gender,
                    senderage: account.profile?.age,
                    messagetype: 6,
                    giftid: gift.id,
                    messageid: uuidv4(),
                    sendtime: Math.ceil(new Date().getTime() / 1000),
                }
                playSVGAAnimation(gift.id)
                addConversationMessage(message)(store.dispatch)
                analysisLogEvent(AnalysisEventName.stream_gift_send_success)
            } catch (err) {
                let error = err as NetworkError
                Loading.dismiss(loading)
                Toast.show({ content: error.message })
                Loading.dismiss(loading)
                if (error.code === 2001) {
                    updateRechargeVisible(true)(store.dispatch)
                }
                analysisLogEvent(AnalysisEventName.stream_gift_send_failure)
            }
        }
    }, [setGiftPresent])

    const handleTranslate = useCallback(async (item: CustomRtmMessage) => {
        let dataSource = [...store.getState().conversation.messages]
        let newSource = [...dataSource]
        let findIndex = dataSource.findIndex((arg0) => arg0.mediaid === item.mediaid)
        if (findIndex >= 0) {
            if (newSource[findIndex].original_text) {
                let newMessage = { ...newSource[findIndex] }
                newMessage.message = newSource[findIndex].original_text
                newMessage.original_text = undefined
                newSource[findIndex] = newMessage
                replaceConversationMessage(newSource)(store.dispatch)
            } else {
                let loading = Loading.show()
                try {
                    let response = await api.translate({
                        q: item.message ?? "",
                        target: new Intl.Locale(navigator.language).language
                    })
                    let text = response.r as string
                    let newSource = [...dataSource]
                    let findIndex = dataSource.findIndex((arg0) => arg0.mediaid === item.mediaid)
                    if (findIndex >= 0) {
                        let newMessage = { ...newSource[findIndex] }
                        newMessage.original_text = newSource[findIndex].message
                        newMessage.message = text
                        newSource[findIndex] = newMessage
                    }
                    Loading.dismiss(loading)
                    replaceConversationMessage(newSource)(store.dispatch)
                } catch {
                    Loading.dismiss(loading)
                    replaceConversationMessage(newSource)(store.dispatch)
                }
            }
        }

    }, [])

    return { peer, duration, text, setText, handleSendText, handleExit, handleFollow, handleCameraToggle, handleMutedToggle, handleGiftButtonClick, handleGiftSend, giftPresent, currentDate, handleTranslate }
}

export default useConversation