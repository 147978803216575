
import { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from 'i18next';
import { Loading, Toast } from 'components';
import { api } from 'server';

function useReport() {
    const params = useParams()
    const [text, setText] = useState('')
    const navigate = useNavigate()
    const handleFeedback = useCallback(async () => {
        let loading = Loading.show()
        try {
            await api.report(text, Number(params.id))
            Loading.dismiss(loading)
            navigate(-1)
            Toast.show({ content: i18n.t('report.success.toast') })
        } catch (err) {
            Loading.dismiss(loading)
        }
    }, [navigate, text, params])
    return { text, setText, handleFeedback }
}

export default useReport