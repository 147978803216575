
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import { Loading, Toast } from 'components';
import { api } from 'server';

function useFeedback() {
    const [text, setText] = useState('')
    const navigate = useNavigate()
    const handleFeedback = useCallback(async () => {
        let loading = Loading.show()
        try {
            await api.feedback(text)
            Loading.dismiss(loading)
            navigate(-1)
            Toast.show({ content: i18n.t('feedback.success.toast') })
        } catch (err) {
            Loading.dismiss(loading)
        }
    }, [navigate, text])
    return { text, setText, handleFeedback }
}

export default useFeedback