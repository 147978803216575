import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CheckIns, CheckItem, NetworkError } from 'models';
import { Loading, Toast } from 'components';
import { api } from 'server';
import store from 'store';
import { updateCheckinRemote } from 'store/checkin';
import classNames from 'classnames';
import './CheckinModal.scss';

const CheckinModal = React.memo((props: {
    visible?: boolean;
    onClose?: () => void;
}) => {
    const { t } = useTranslation()
    const { visible, onClose } = props
    const checkin = useSelector(state => (state as any).checkin).value as CheckIns
    const [show, setShow] = React.useState<undefined | boolean>(false)

    React.useLayoutEffect(() => {
        setTimeout(() => {
            setShow(visible)
        })
    }, [visible, setShow])

    const close = React.useCallback(() => {
        setShow(false)
        setTimeout(() => {
            typeof onClose === 'function' && onClose()
        }, 250)
    }, [onClose])

    if (!visible) {
        return null
    }

    return (
        <div className={classNames('checkin-modal', !show && 'hide')}>
            <div className='mask' onClick={() => close()}></div>
            <div className='content-view'>
                <div className='header'>
                    <div className='title'>{t('checkin.title')}</div>
                    <div className='sub-title'>{t('checkin.subtitle')}</div>
                    <div className='close-button touch-opacity' onClick={() => close()}></div>
                </div>
                <div className='main-container'>
                    <div className='list-view'>
                        {checkin && Array.isArray(checkin.checkins) && checkin.checkins.map((item, index) => {
                            return (
                                <CheckinListItem item={item} index={index} key={index} />
                            )
                        })}
                    </div>
                    <div className={classNames('checkin-button', checkin && checkin.today_checked === false ? 'touch-opacity' : 'disabled disabled-opacity')}
                        onClick={async () => {
                            let loading = Loading.show()
                            try {
                                await api.postCheckin({ double: false })
                                await updateCheckinRemote()(store.dispatch)
                                Loading.dismiss(loading)
                            } catch (err) {
                                let error = err as NetworkError
                                Loading.dismiss(loading)
                                Toast.show({ content: error.message })
                            }
                        }}
                    >{t('checkin.button')}</div>
                </div>
            </div>
        </div>
    )
})

const CheckinListItem = React.memo((props: { index: number, item: CheckItem }) => {
    const { index, item } = props;
    return (
        <div className={classNames('item', item.checked && 'checked')}>
            <div className='index-badge'></div>
            <div className='index-label'>{index + 1}</div>
            <div className='token'></div>
            <div className='label'>+{item.token_amount}</div>
        </div>
    )
})

export default CheckinModal