import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Avatar } from 'components';
import { PagePath } from 'models';
import { updateCheckinVisible } from 'store/app';
import { updateCheckinRemote } from 'store/checkin';
import './ProfilePage.scss';

const ProfilePage = React.memo(() => {
    const { t } = useTranslation()
    const account = useSelector(state => (state as any).account).value
    const isGuest = account && !account.email
    return (
        <div className='profile-page'>
            <ProfileHeader></ProfileHeader>
            <Link to={PagePath.accounts_edit}>
                <Avatar className='avatar' src={account.profile.head}></Avatar>
            </Link>
            <div className='nickname-label'>{account.profile.nickname}</div>
            <div className='id-label'>ID: {account.id}</div>
            <div className='mood-label'>{account.profile.mood}</div>
            {isGuest && <EmailCellItem title={t('profile.item.email')} classType={'email'} to={PagePath.accounts_signup}></EmailCellItem>}
            <ProfileCellItem title={t('profile.item.edit')} classType={'edit'} to={PagePath.accounts_edit}></ProfileCellItem>
            <ProfileCellItem title={t('profile.item.expenses')} classType={'expenses'} to={PagePath.finance_expenses}></ProfileCellItem>
            <ProfileCellItem title={t('profile.item.backlist')} classType={'backlist'} to={PagePath.accounts_blacklist}></ProfileCellItem>
            <ProfileCellItem title={t('profile.item.following')} classType={'following'} to={PagePath.accounts_following}></ProfileCellItem>
            <ProfileCellItem title={t('profile.item.follower')} classType={'follower'} to={PagePath.accounts_follower}></ProfileCellItem>
            <ProfileCellItem title={t('profile.item.history')} classType={'history'} to={PagePath.accounts_view_history}></ProfileCellItem>
            <ProfileCellItem title={t('profile.item.settings')} classType={'settings'} to={PagePath.system_settings}></ProfileCellItem>
            <div style={{ height: '64px' }}></div>
        </div>
    );
})

const ProfileHeader = React.memo(() => {
    const { t } = useTranslation()
    const checkin = useSelector(state => (state as any).checkin).value
    const dispatch = useDispatch()
    return (
        <div className='header'>
            <div className='title'>{t('profile.title')}</div>
            <div className='checkin-button touch-opacity' onClick={() => {
                updateCheckinVisible(true)(dispatch)
                if (!checkin) {
                    // 缺少签到数据，再次请求
                    updateCheckinRemote()(dispatch)
                }
            }}></div>
        </div>
    );
})

const EmailCellItem = React.memo((props: {
    classType?: string;
    title?: string;
    to: string;
}) => {
    const bindEmailRight = useSelector(state => (state as any).right).bindEmailRight
    return (
        <Link to={props.to} className='email-cell-item touch-soft-highlight'>
            <div className={classNames('icon', props.classType)}></div>
            <div className='text-label'>{props.title}</div>
            {
                bindEmailRight && bindEmailRight.is_used === false ?

                    <div className='token-view'>
                        <div className='token-icon'></div>
                        <div className='token-label'>{bindEmailRight.right.desc.token_amount}</div>
                    </div>
                    :
                    <>
                        <div className='arrow'></div>
                        <div className='badge'></div>
                    </>
            }
        </Link>
    )
})

const ProfileCellItem = React.memo((props: {
    classType?: string;
    title?: string;
    to: string;
}) => {
    return (
        <Link to={props.to} className='profile-cell-item touch-soft-highlight'>
            <div className={classNames('icon', props.classType)}></div>
            <div className='text-label'>{props.title}</div>
            <div className='arrow'></div>
        </Link>
    )
})

export default ProfilePage;