import AgoraRTC, { IAgoraRTCClient, IMicrophoneAudioTrack, ICameraVideoTrack, UID, IRemoteAudioTrack, IRemoteVideoTrack } from "agora-rtc-sdk-ng";
import { updateConversationPublishedDate, clearConversation, updateConversationRemoteMuted, updateConversationMuted, updateConversationNotifyRecharge } from "store/conversation";
import { Channel, WebSocketEvent, WebSocketCode, GlobalEvent, WSBalance, PagePath, Role } from 'models'
import { api } from 'server';
import { Log, NotificationCenter } from 'utils'
import { history } from 'pages/App'
import store from "store";
import { updateRating } from "store/rating";
import { analysisLogEvent, AnalysisEventName } from 'analysis';
import config from 'config';

if (config.debug === true) {
    AgoraRTC.setLogLevel(0)
} else {
    AgoraRTC.setLogLevel(4)
}

const rtc: {
    client: IAgoraRTCClient,
    localAudioTrack?: IMicrophoneAudioTrack | undefined,
    localVideoTrack?: ICameraVideoTrack | undefined,
    remoteAudioTrack?: IRemoteAudioTrack | undefined,
    remoteVideoTrack?: IRemoteVideoTrack | undefined,
    uid?: UID | undefined,
    deviceId?: string | undefined,
    balance?: WSBalance | undefined,
    join: (options: any) => void,
    leave: () => void,
    keep: () => void,
    switchCamera: () => void,
    togglMuted: () => void
} = {
    client: AgoraRTC.createClient({ mode: "rtc", codec: "vp8" }),
    join: async (options: any) => {
        if (rtc.localAudioTrack && rtc.localVideoTrack) {
            // 已经加入房间了, 通过id恢复音视频会话
            const localPlayerContainer = document.getElementById('local-video')
            if (localPlayerContainer) {
                rtc.localVideoTrack.play(localPlayerContainer as HTMLDivElement);
            }
            const remotePlayerContainer = document.getElementById('remote-video')
            if (remotePlayerContainer) {
                rtc.remoteVideoTrack?.play(remotePlayerContainer as HTMLDivElement);
            }
        } else {
            // 未加入房间
            analysisLogEvent(AnalysisEventName.stream_join_begin)
            await rtc.client.join(options.appId, options.channel, options.token, rtc.uid);
            rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
            await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
            const localPlayerContainer = document.getElementById('local-video')
            if (localPlayerContainer) {
                rtc.localVideoTrack.play(localPlayerContainer as HTMLDivElement);
            }
            analysisLogEvent(AnalysisEventName.stream_join_success)
        }
    },
    leave: async () => {
        const leaveHandler = async () => {
            rtc.localAudioTrack?.close();
            rtc.localVideoTrack?.close();
            rtc.remoteAudioTrack?.stop();
            rtc.remoteVideoTrack?.stop();
            rtc.localAudioTrack = undefined
            rtc.localVideoTrack = undefined
            rtc.remoteAudioTrack = undefined
            rtc.remoteVideoTrack = undefined
            rtc.deviceId = undefined;
            rtc.balance = undefined
            await rtc.client.leave();
            clearConversation()(store.dispatch)
        }
        const conversationValue = store.getState().conversation.value as Channel
        const publishedDate = store.getState().conversation.publishedDate as Date
        if (rtc.balance && rtc.balance.biz.token && rtc.balance.biz.token > 0 && conversationValue && publishedDate && conversationValue.peer.role === Role.anchor) {
            let object = {
                callId: conversationValue.call_id,
                duration: (new Date().getTime() - publishedDate.getTime()) / 1000,
                token: rtc.balance.biz.token,
                peer: conversationValue.peer
            }
            updateRating(object)(store.dispatch)
            history.replace(PagePath.live_rating)
            // 修复连续push问题
            setTimeout(() => { leaveHandler() }, 1000)
        } else {
            leaveHandler()
        }
    },
    keep: () => {
        // 监听对方published事件
        rtc.client.on("user-published", async (user, mediaType) => {
            await rtc.client.subscribe(user, mediaType);
            if (mediaType === "video") {
                rtc.remoteVideoTrack = user.videoTrack;
                const remotePlayerContainer = document.getElementById('remote-video')
                if (remotePlayerContainer) {
                    rtc.remoteVideoTrack?.play(remotePlayerContainer as HTMLDivElement);
                }
            }
            if (mediaType === "audio") {
                rtc.remoteAudioTrack = user.audioTrack;
                rtc.remoteAudioTrack?.play();
            }
            analysisLogEvent(AnalysisEventName.stream_user_published)
            updateConversationPublishedDate(new Date())(store.dispatch)
        });

        rtc.client.on("user-info-updated", (_, msg) => {
            if (msg === 'mute-video') {
                updateConversationRemoteMuted(true)(store.dispatch)
            } else if (msg === 'unmute-video') {
                updateConversationRemoteMuted(false)(store.dispatch)
            }
        })

        // 监听对方挂断
        NotificationCenter.addObserver(GlobalEvent.websocket, (value: any) => {
            let event = (value as any).detail as WebSocketEvent
            const channel = (store.getState().conversation as any).value as Channel
            if (event.event_code === WebSocketCode.hangup && event.data && channel && event.data.call_id === channel.call_id) {
                // 收到对方挂断
                rtc.leave()

            }
        })
        // 监听余额变更
        NotificationCenter.addObserver(GlobalEvent.websocket, (value: any) => {
            let event = (value as any).detail as WebSocketEvent
            if (event.event_code === WebSocketCode.balance) {
                let balance = event.data as WSBalance
                if (balance && balance.biz_code === 2) {
                    rtc.balance = balance
                }
            }
        })
        // 监听余额不足
        NotificationCenter.addObserver(GlobalEvent.websocket, (value: any) => {
            let event = (value as any).detail as WebSocketEvent
            if (event.event_code === WebSocketCode.balance && event.data && event.data.biz && event.data.biz.notify_recharge && !isNaN(event.data.biz.remind_duration) && event.data.biz.remind_duration === 0) {
                var curTime = new Date();
                updateConversationNotifyRecharge({
                    expireDate: new Date(curTime.setSeconds(curTime.getSeconds() + 60)),
                    recharge: event.data.biz.notify_recharge.recharge
                })(store.dispatch)
            }
        })
        // 执行每15秒活跃度检测方法
        setInterval(() => {
            const publishedDate = (store.getState().conversation as any).publishedDate as Date
            const channel = (store.getState().conversation as any).value as Channel
            if (publishedDate && channel.call_id) {
                api.getCall(channel.call_id).then((status) => {
                    if (status === 3) {
                        analysisLogEvent(AnalysisEventName.stream_endcall_trigger)
                        rtc.leave()
                    }
                }).catch((err) => {
                    Log.print(err)
                })
            }
        }, 15 * 1000)
    },
    switchCamera: () => {
        AgoraRTC.getCameras()
            .then((cameras) => {
                if (cameras.length <= 1) { return }
                if (!rtc.deviceId || rtc.deviceId === cameras[0].deviceId) {
                    rtc.localVideoTrack?.setDevice(cameras[1].deviceId)
                    rtc.deviceId = cameras[1].deviceId
                } else {
                    rtc.localVideoTrack?.setDevice(cameras[0].deviceId)
                    rtc.deviceId = cameras[0].deviceId
                }
            })
            .catch(() => { })

    },
    togglMuted: () => {
        let muted = rtc.localVideoTrack?.muted
        if (muted !== undefined) {
            rtc.localVideoTrack?.setMuted(!muted)
            updateConversationMuted(!muted)(store.dispatch)
        }
    }
}

rtc.keep()

export default rtc;