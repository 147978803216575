import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './UpdateModal.scss';
import config from 'config';

const UpdateModal = React.memo((props: {
    visible?: boolean;
    onClose?: () => void;
}) => {
    const { t } = useTranslation()
    const location = useLocation()
    const { visible, onClose } = props
    const [show, setShow] = React.useState<undefined | boolean>(false)
    const handleClick = React.useCallback(() => {
        if (config.updateUrlString) {
            window.location.href = config.updateUrlString
        }
    }, [location])

    React.useLayoutEffect(() => {
        setTimeout(() => {
            setShow(visible)
        })
    }, [visible, setShow])

    const close = React.useCallback(() => {
        setShow(false)
        setTimeout(() => {
            typeof onClose === 'function' && onClose()
        }, 250)
    }, [onClose])

    if (!visible) {
        return null
    }

    return (
        <div className={classNames('update-modal', !show && 'hide')}>
            <div className='mask' onClick={() => close()}></div>
            <div className='content-view'>
                <div className='close-button' onClick={() => close()}></div>
                <div className='image-logo'></div>
                <div className='title'>{t('update.title')}</div>
                <div className='desc'>{t('update.desc')}</div>
                <div className='next-button' onClick={() => handleClick()}>{t('update.next')}</div>
            </div>
        </div>
    )
})

export default UpdateModal