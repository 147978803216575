import React from 'react';
import Spinner from '../spinner/Spinner';
import './Authing.scss';

const Authing = React.memo(() => {
    return (
        <div className="authing-component">
            <Spinner></Spinner>
        </div>
    );
})

export default Authing;