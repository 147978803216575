import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import lottie from 'lottie-web';
import { Avatar, ActionAlert, ListErrorView, ListLoadingView } from 'components';
import { useConversation } from 'hooks';
import { Gift, Account, CustomRtmMessage, NativeMessageType } from 'models';
import { LiveWebAdapter } from 'utils';
import { updateRechargeVisible } from 'store/app';
import { updateGiftsRemote } from 'store/gifts';
import store from 'store';
import { analysisLogEvent, AnalysisEventName } from 'analysis';
import './ConversationPage.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { updateUpdateVisible } from 'store/app';
import config from 'config';

const ConversationPage = React.memo(() => {
    const { t } = useTranslation()
    const { peer, duration, text, setText, handleSendText, handleExit, handleFollow, handleMutedToggle, handleCameraToggle, handleGiftButtonClick, handleGiftSend, giftPresent, currentDate, handleTranslate } = useConversation()
    const muted = useSelector(state => (state as any).conversation).muted
    const remoteMuted = useSelector(state => (state as any).conversation).remoteMuted
    const [reverse, setReverse] = useState(false)
    const [inputVisible, setInputVisible] = useState(false)
    const [giftVisible, setGiftVisible] = useState(false)
    const [alertVisible, setAlertVisible] = useState(false)
    const giftRef = useRef(null)
    const giftLoverRef = useRef(null)
    const inputRef = useRef(null)

    useEffect(() => {
        analysisLogEvent(AnalysisEventName.stream_enter)
    }, [])

    useEffect(() => {
        let element = giftRef.current as any
        lottie.loadAnimation({
            container: element,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../../../assets/lottie/gift/Gift.json')
        });
    }, [giftRef])

    useEffect(() => {
        let element = giftLoverRef.current as any
        lottie.loadAnimation({
            container: element,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../../../assets/lottie/gift-lover/Heart.json')
        });
    }, [giftLoverRef])

    return (
        <div className='conversation-page'>
            <div className={reverse ? 'large-video-view' : 'small-video-view'} id='local-video'>
                {muted === true && <div className='disabled-video-camera'></div>}
            </div>
            <div className={reverse ? 'small-video-view' : 'large-video-view'} id='remote-video'>
                {remoteMuted === true && <div className='disabled-video-camera'></div>}
            </div>
            <Swiper className='swipe' initialSlide={1} autoplay={false}>
                <SwiperSlide className='view-page'></SwiperSlide>
                <SwiperSlide className='main'>
                    <div className='feature-buttons'>
                        <div className={classNames('camera-button', 'touch-opacity', !muted ? 'videotape' : 'videotape-disabled')} onClick={() => handleMutedToggle()}></div>
                        <div className='toggle-button touch-opacity' onClick={() => handleCameraToggle()}></div>
                    </div>
                    <div className='reverse-button' onClick={() => setReverse(!reverse)}></div>
                    <div className='header'>
                        <Avatar className='avatar' src={peer && peer.profile.head}></Avatar>
                        <div className='text-wrapper'>
                            <div className='nickname'>{peer && peer.profile.nickname}</div>
                            <div className='duration'>{duration}</div>
                        </div>
                        <div className={classNames('follow-button', peer && !peer.is_following && 'touch-opacity', peer && peer.is_following && 'followed')}
                            onClick={() => handleFollow()}></div>
                    </div>
                    <div className='close-button touch-opacity' onClick={() => {
                        analysisLogEvent(AnalysisEventName.stream_exit_click)
                        setAlertVisible(true)
                    }}></div>
                    <ConversationRechargeCountDownTipsView currentDate={currentDate}></ConversationRechargeCountDownTipsView>
                    <div className='footer'>
                        <div className='left' onClick={() => setInputVisible(true)}>
                            <div className='textField'>{t('conversation.textfiled.placeholder')}</div>
                        </div>
                        <ConversationRechargeCountDownView onMoreClick={() => updateRechargeVisible(true)(store.dispatch)} currentDate={currentDate} />
                        <div className='right'>
                            <div className='gift-button touch-opacity' ref={giftRef} onClick={() => { setGiftVisible(true); handleGiftButtonClick(); analysisLogEvent(AnalysisEventName.stream_gift_click) }}></div>
                        </div>
                    </div>
                    <ConversationMessageListView
                        translateClick={(item: CustomRtmMessage) => {
                            handleTranslate(item)
                        }} />
                    {
                        inputVisible &&
                        <div className='keyboard-mask'>
                            <div className='input-wrapper'>
                                <input
                                    enterKeyHint='send'
                                    type="text"
                                    className='input'
                                    autoFocus
                                    placeholder={t('conversation.textfiled.placeholder')}
                                    ref={inputRef}
                                    onBlur={() => setInputVisible(false)}
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSendText()
                                            setInputVisible(false)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    }
                    {
                        giftVisible &&
                        <ConversationGiftListView maskOnClick={() => setGiftVisible(false)} itemClick={(gift) => { setGiftVisible(false); handleGiftSend(gift) }} />
                    }
                    {
                        giftPresent && (new Date().getTime() - giftPresent.create_date.getTime() < 3 * 1000) &&
                        <div className='gift-banner'>
                            <Avatar className='avatar' src={giftPresent.head}></Avatar>
                            <div className='nickname'>{giftPresent.nickname}</div>
                            <img className='gift' src={require(`assets/image/gift_${giftPresent.gift_id}_65x65@3x.png`)} alt=""></img>
                        </div>
                    }
                    <div className='gift-button-lottie-lover' ref={giftLoverRef}></div>
                    <div className='gift-svga-player' id="gift-svga-player"></div>
                </SwiperSlide>
            </Swiper>

            <ActionAlert visible={alertVisible} title={t('conversation.exit.alert.title')} actions={[
                {
                    title: t('common.cancel'), style: { color: 'var(--system-blue)', fontWeight: 'var(--font-weight-system-semibold)' }, handler: () => {
                        setAlertVisible(false)
                        analysisLogEvent(AnalysisEventName.stream_exit_click_confirm)
                    }
                },
                {
                    title: t('common.confirm'), style: { color: 'var(--system-blue)' }, handler: () => {
                        setAlertVisible(false)
                        handleExit()
                        analysisLogEvent(AnalysisEventName.stream_exit_click)
                    }
                }
            ]}></ActionAlert>
        </div>
    );
})


const ConversationRechargeCountDownTipsView = React.memo((props: {
    currentDate: Date,
}) => {
    let [show, setShow] = useState(true);
    let { currentDate } = props;
    const { t } = useTranslation()
    const notifyRecharge = useSelector(state => (state as any).conversation).notifyRecharge
    if (!notifyRecharge) {
        return null
    }
    let expireDate = notifyRecharge && notifyRecharge.expireDate
    let time = Math.max(0, Math.round((expireDate && expireDate.getTime() - currentDate.getTime()) / 1000))
    if (time >= 40 || time <= 0) {
        return null
    }
    if (!show) {
        return null
    }
    return (
        <div className='tips-container'>
            <div className='tips'>
                <div className='tips-label'>{t('conversation.tips')}</div>
                <div className='tips-close-button touch-opacity' onClick={() => {
                    setShow(false)
                }}></div>
            </div>
        </div>
    )
})

const ConversationRechargeCountDownView = React.memo((props: {
    onMoreClick?: () => void,
    currentDate: Date,
}) => {
    let { onMoreClick, currentDate } = props;
    const notifyRecharge = useSelector(state => (state as any).conversation).notifyRecharge
    if (!notifyRecharge) {
        return null
    }
    let expireDate = notifyRecharge && notifyRecharge.expireDate
    let time = Math.max(0, Math.round((expireDate && expireDate.getTime() - currentDate.getTime()) / 1000))
    if (time <= 0) {
        return null
    }
    return (
        <div className='count-down-view'>
            <div className='count-down'>
                <div className={classNames('time-circle', time <= 20 && 'blink')}>
                    <div className='svg-container'>
                        <svg width="40" height="40" style={{ overflow: 'visible', transform: 'rotate(-90deg)' }}>
                            <circle
                                className="path"
                                cx="19"
                                cy="19"
                                r="20"
                                strokeWidth="2"
                                stroke="#D1D3D7"
                                fill="none"
                                strokeDasharray={`${Math.ceil((time / 60) * 126)} 126`}
                                strokeLinecap="round"
                            ></circle>
                        </svg>
                    </div>
                    <div className='label'>{time}</div>
                </div>
                <div className='count-title'></div>
            </div>
            <div className='suggest-token' onClick={() => {
                analysisLogEvent(AnalysisEventName.stream_insufficient_recharge_click)
                if (config.needUpdate === true) {
                    updateUpdateVisible(true)(store.dispatch)
                } else {
                    LiveWebAdapter.postMessage({
                        type: NativeMessageType.recharge,
                        content: notifyRecharge.recharge
                    })
                }
            }}>
                <div className='token-section'>
                    <div className='token-icon'></div>
                    <div className='token-label'>{notifyRecharge && notifyRecharge.recharge.token_amount}</div>
                </div>
                <div className='token-price'>${notifyRecharge && notifyRecharge.recharge.price}</div>
            </div>
            <div className='arrow' onClick={() => typeof onMoreClick === 'function' && onMoreClick()}></div>
        </div>
    )
})

const ConversationMessageListView = React.memo((props: {
    translateClick?: (message: CustomRtmMessage) => void
}) => {
    const messages = useSelector(state => (state as any).conversation).messages as [CustomRtmMessage]
    return (
        <div className='list-container'>
            {
                Array.isArray(messages) && messages.slice().reverse().map((item) => {
                    if (item.messagetype === 6) {
                        return (
                            <ConversationMessageGiftItemView item={item} key={item.messageid} />
                        )
                    }
                    return (
                        <ConversationMessageTextItemView item={item} key={item.messageid} translateClick={props.translateClick} />
                    )
                })
            }
        </div>
    )
})

const ConversationMessageTextItemView = React.memo((props: {
    item: CustomRtmMessage,
    translateClick?: (message: CustomRtmMessage) => void
}) => {
    const { t } = useTranslation()
    const { item, translateClick } = props;
    const account = useSelector(state => (state as any).account).value as Account
    const isSelf = `${account.id}` === item.senderid
    return (
        <div className='message-item-view'>
            <div className='text-main'>
                <p className='label'><span className={classNames('nickname', isSelf && 'self')}>{isSelf ? t('conversation.me') : item.sendernickname}: </span>{item.message}</p>
                {!isSelf && <div className='translate-button touch-opacity' onClick={() => translateClick && translateClick(item)}>
                    <div className='icon'></div>
                    <div className='label'>{item.original_text && item.original_text?.length > 0 ? "Show Origin" : "Translate"}</div>
                </div>}
            </div>
        </div>
    )
})

const ConversationMessageGiftItemView = React.memo((props: {
    item: CustomRtmMessage
}) => {
    const { t } = useTranslation()
    const { item } = props;
    const account = useSelector(state => (state as any).account).value as Account
    const isSelf = `${account.id}` === item.senderid
    return (
        <div className='message-item-view'>
            <div className='text-main gift-text'>
                <p className='label'><span className={classNames('nickname', isSelf && 'self')}>{isSelf ? t('conversation.me') : item.sendernickname}: </span>{t('conversation.send.a')}<img className='gift' src={require(`assets/image/gift_${item.giftid}_65x65@3x.png`)} alt=""></img></p>
            </div>
        </div>
    )
})

const ConversationGiftListView = React.memo((props: {
    maskOnClick: () => void,
    itemClick: (gift: Gift) => void
}) => {
    const { t } = useTranslation()
    const gifts = useSelector(state => (state as any).gifts).value as [Gift]
    const state = useSelector(state => (state as any).gifts).state as 'loading' | 'error' | 'clear'
    const account = useSelector(state => (state as any).account).value as Account

    useEffect(() => {
        const status = store.getState().gifts.state as any as 'loading' | 'error' | 'clear'
        if (status === 'error') {
            updateGiftsRemote()(store.dispatch)
        }
    }, [])

    return (
        <div className='gift-container'>
            <div className='gift-mask' onClick={() => props.maskOnClick()}></div>
            <div className='gift-list-view'>
                <div className='list-header'>
                    <div className='title'>{t('conversation.gifts')}</div>
                    <div className='token'>
                        <div className='token-image'></div>
                        <div className='token-label'>{account.balance ?? 0}</div>
                    </div>
                </div>
                <div className='list'>
                    {
                        state === 'error' ?
                            <ListErrorView></ListErrorView>
                            :
                            state === 'loading' ?
                                <ListLoadingView></ListLoadingView>
                                :
                                Array.isArray(gifts) && gifts.map((item) => {
                                    return <ConversationGiftItemView item={item} key={item.id} onClick={(gift) => props.itemClick(gift)} />
                                })
                    }
                </div>
            </div>
        </div>
    )
})

const ConversationGiftItemView = React.memo((props: {
    item: Gift
    onClick: (gift: Gift) => void
}) => {
    const { item, onClick } = props
    return (
        <div className='gift-item-view touch-opacity' onClick={() => onClick(item)}>
            <img className='image' src={require(`assets/image/gift_${item.id}_65x65@3x.png`)} alt=""></img>
            <div className='name'>{item.name}</div>
            <div className='price'>
                <div className='price-token'></div>
                <div className='price-label'>{item.price}</div>
            </div>
        </div>
    )
})

export default ConversationPage;