import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.scss';

const Header = React.memo((props: {
  title?: string;
  children?: React.ReactNode;
}) => {
  const { title, children } = props;
  const navigate = useNavigate()
  return (
    <nav className='header-component'>
      <header className='header-content'>
        <button className='back-button touch-opacity' onClick={() => { navigate(-1) }}>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48" fill="none"><path d="M31 36L19 24L31 12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg> */}
        </button>
        {title && <h1 className='title'>{title}</h1>}
        {children}
      </header>
    </nav>
  )
})

export default Header;