import { createSlice } from '@reduxjs/toolkit';
import { ConversationEnd } from 'models';

const initialState: {
    value?: ConversationEnd
} = {
    value: undefined,
}

// Slice
const slice = createSlice({
    name: 'Rating',
    initialState,
    reducers: {
        updateRating: (state, action) => {
            state.value = action.payload
        },
        clearRating: (state) => {
            state.value = undefined;
        },
    }
});

export default slice.reducer

export const updateRating = (payload: any) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateRating(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const clearRating = () => async (dispatch: (arg0: { payload: any; type: string; }) => any) => {
    try {
        return dispatch(slice.actions.clearRating())
    } catch (e) {
        return console.error((e as any).message);
    }
}