import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useMatch, useNavigate, useParams } from "react-router-dom";
import classNames from 'classnames';
import { Header, Avatar, List } from 'components';
import { PagePath, Rank } from 'models';
import { api } from 'server';
import './RankingPage.scss';

const RankingPage = React.memo(() => {
    const { t } = useTranslation()
    return (
        <div className='ranking-page'>
            <Header title={t('ranking.title')}></Header>
            <RankingSegmentedControl />
            {useMatch(PagePath.anchor_ranking_anchor + "/:range") != null && <RankingListView type={'anchor'} />}
            {useMatch(PagePath.anchor_ranking_gold + "/:range") != null && <RankingListView type={'gold'} />}
            {useMatch(PagePath.anchor_ranking_gift + "/:range") != null && <RankingListView type={'gift'} />}
            {useMatch(PagePath.anchor_ranking_lover + "/:range") != null && <RankingListView type={'lover'} />}
        </div>
    );
})

const RankingSegmentedControl = React.memo(() => {
    const { t } = useTranslation()
    const matchAnchor = useMatch(PagePath.anchor_ranking_anchor + "/:range") != null;
    const matchGold = useMatch(PagePath.anchor_ranking_gold + "/:range") != null;
    const matchGift = useMatch(PagePath.anchor_ranking_gift + "/:range") != null;
    const matchLover = useMatch(PagePath.anchor_ranking_lover + "/:range") != null;
    const params = useParams()
    const range = params.range
    return (
        <div className='segmented-control'>
            <Link className={classNames('item', 'touch-opacity', matchAnchor && 'active')} to={PagePath.anchor_ranking_anchor + "/" + range} replace>
                <span className='label'>{t('ranking.anchor')}</span>
            </Link>
            <Link className={classNames('item', 'touch-opacity', matchGold && 'active')} to={PagePath.anchor_ranking_gold + "/" + range} replace>
                <span className='label'>{t('ranking.gold')}</span>
            </Link>
            <Link className={classNames('item', 'touch-opacity', matchGift && 'active')} to={PagePath.anchor_ranking_gift + "/" + range} replace>
                <span className='label'>{t('ranking.gift')}</span>
            </Link>
            <Link className={classNames('item', 'touch-opacity', matchLover && 'active')} to={PagePath.anchor_ranking_lover + "/" + range} replace>
                <span className='label'>{t('ranking.lover')}</span>
            </Link>
        </div>
    )
})

const RankingListView = React.memo((props: {
    type: 'anchor' | 'gold' | 'gift' | 'lover'
}) => {
    const { t } = useTranslation()
    const { type } = props;
    const listRef = useRef(null)
    const params = useParams()
    const range = params.range
    const onFetch = (page: number, pageSize: number, startFetch: (data: Rank[]) => void, abortFetch: (error?: Error) => void) => {
        api.getRank({
            per_page: pageSize,
            page: page,
            range: range === 'day' ? 1 : (range === 'week' ? 2 : 3),
            type: type === 'anchor' ? 1 : (type === 'gold' ? 2 : type === 'gift' ? 3 : 4)
        }).then((items) => {
            startFetch(items)
        }).catch(() => {
            abortFetch()
        })
    }
    useEffect(() => {
        try { (listRef.current as any).handleInitFetch().handleInitFetch() } catch { }
    }, [range, listRef])
    return (
        <>
            <div className='date-control'>
                <Link className={classNames('item', 'touch-opacity', range === 'day' && 'active')} to={PagePath.anchor_ranking + "/" + type + "/day"} replace>
                    <span className='label'>{t('ranking.day')}</span>
                </Link>
                <Link className={classNames('item', 'touch-opacity', range === 'week' && 'active')} to={PagePath.anchor_ranking + "/" + type + "/week"} replace>
                    <span className='label'>{t('ranking.week')}</span>
                </Link>
                <Link className={classNames('item', 'touch-opacity', range === 'month' && 'active')} to={PagePath.anchor_ranking + "/" + type + "/month"} replace>
                    <span className='label'>{t('ranking.month')}</span>
                </Link>
            </div>
            <div className='list-view'>
                <List
                    ref={listRef}
                    className='list'
                    onFetch={onFetch}
                    renderItem={(item, index) => <RankingListCellItem key={index} item={item} index={index} type={type} />}
                ></List>
            </div>
        </>
    )
})

export const RankingListCellItem = React.memo((props: {
    item: Rank, index: number,
    type: 'anchor' | 'gold' | 'gift' | 'lover'
}) => {
    const { item, index, type } = props
    const navigate = useNavigate()
    return (
        <div className='ranking-list-cell-item'>
            <div className='touch touch-soft-highlight' onClick={() => {
                if (type === 'lover') {
                    item.other_people?.id && navigate(PagePath.anchor_detail + `/${item.other_people?.id}`)
                } else {
                    item.id && navigate(PagePath.anchor_detail + `/${item.id}`)
                }
            }}></div>
            <div className={classNames(
                'topic-number',
                index === 0 && 'top1',
                index === 1 && 'top2',
                index === 2 && 'top3',
            )}>{index > 2 ? (index + 1) : ""}</div>
            {((type !== 'lover') || (type === 'lover' && !item.other_people)) && <Avatar className='avatar' src={item.profile?.head}></Avatar>}
            {type === 'lover' && item.other_people && <Avatar className='avatar-1' src={item.other_people?.profile?.head}></Avatar>}
            {type === 'lover' && item.other_people && <Avatar className='avatar-2' src={item.profile?.head}></Avatar>}
            <div className='nickname-label'>{item.profile?.nickname}</div>
            <div className='hot-wrapper'>
                <div className={classNames(
                    'hot-icon',
                    type === 'anchor' && "anchor",
                    type === 'gold' && "gold",
                    type === 'gift' && "gift",
                    type === 'lover' && "lover",
                )}></div>
                <div className='hot-label'>{item.score}</div>
            </div>
        </div>
    )
})

export default RankingPage;