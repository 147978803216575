import { NativeMessageProps } from 'models'
import config from 'config'
import { network, pathname } from 'server'
import { Toast } from 'components'
import { updateFirstRechargeRight } from 'store/right'
import { updateFirstRechargeVisible, updateRechargeCall } from 'store/app'
import store from 'store'
import i18n from 'i18next'
import { updateConversationNotifyRecharge } from 'store/conversation';

//android终端 
var isAndroid = navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("Linux") > -1;
//ios终端 
var isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

const LiveWebAdapter = {
    /// 发送消息给原生端
    postMessage: (value: NativeMessageProps) => {
        let message = {
            type: value.type,
            content: value.content,
            server: {
                origin: config.server.origin,
                pathname: pathname.user_token,
                token: network.apiToken
            }
        }
        if (isAndroid) {
            // @ts-ignore
            window.android && window.android && window.android.postMessage(JSON.stringify(message))
        } else if (isiOS) {
            // @ts-ignore
            window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.__liveWeb__.postMessage(message)
        }
    },
    keep: () => {
        // @ts-ignore
        window.__liveWeb__ = {
            /// 清除首充权益
            clearFirstRechargeRight: () => {
                updateFirstRechargeVisible(false)(store.dispatch)
                updateFirstRechargeRight(undefined)(store.dispatch)
                updateRechargeCall({ visible: false, userInfo: undefined })(store.dispatch)
            },
            /// 提示充值成功
            showRechargeSuccessToast: () => {
                Toast.show({ content: i18n.t('recharge.success-toast') })
                updateConversationNotifyRecharge(undefined)(store.dispatch)
            },
            /// 设置device
            setDevice: (device: any) => {
                if (device) {
                    config.device = device
                }
                if (device.udid) {
                    config.udid = device.udid
                }
                config.independent = false
            },
            /// 设置server
            setServer: (server: any) => {
                if (server) {
                    config.server = server
                }
            },
            /// 设置平台
            setPlatform: (platform: any) => {
                if (platform) {
                    config.platform = platform
                }
            },
            /// 设置平台
            setOneSignal: (oneSignal: any) => {
                if (oneSignal) {
                    config.oneSignal = oneSignal
                }
            },
        }
    }
}

LiveWebAdapter.keep()

export default LiveWebAdapter