import React from 'react';
import { Avatar } from 'components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useRating } from 'hooks';
import { Calculator } from 'utils';
import { clearRating } from 'store/rating';
import store from 'store';
import { analysisLogEvent, AnalysisEventName } from 'analysis';
import './RatingPage.scss';

const RatingPage = React.memo(() => {
    const { ratings, setRatings, content, setContent, handleSend, conversationEnd } = useRating()
    const { t } = useTranslation()
    return (
        <div className='rating-page'>
            <div className='close-button touch-opacity' onClick={() => {
                analysisLogEvent(AnalysisEventName.rating_close_click)
                clearRating()(store.dispatch)
            }}></div>
            <div className='title-label'>{t('rating.title')}</div>
            <div className='desc-label'>{t('rating.diamonds')}: {conversationEnd && conversationEnd.token} {t('rating.time')}: {Calculator.getDuration(conversationEnd && (conversationEnd.duration ?? 0))}</div>
            <div className='conversation-info'>
                <Avatar className='avatar' src={conversationEnd && conversationEnd.peer.profile.head} />
                <div className='rating-title'>{t('rating.ratings')}: {ratings.toFixed(1)}</div>
                <div className='ratings-wrapper'>
                    <div className={classNames('rate-item', ratings >= 1 && 'fill')} onClick={() => setRatings(1)}></div>
                    <div className={classNames('rate-item', ratings >= 2 && 'fill')} onClick={() => setRatings(2)}></div>
                    <div className={classNames('rate-item', ratings >= 3 && 'fill')} onClick={() => setRatings(3)}></div>
                    <div className={classNames('rate-item', ratings >= 4 && 'fill')} onClick={() => setRatings(4)}></div>
                    <div className={classNames('rate-item', ratings >= 5 && 'fill')} onClick={() => setRatings(5)}></div>
                </div>
                <textarea
                    value={content}
                    onChange={(e) => e.target.value.length <= 1000 && setContent(e.target.value)}
                    className='textarea'
                    placeholder={t('rating.placeholder')}
                ></textarea>
            </div>
            <button className={classNames('next-button', 'touch-highlight')} onClick={() => handleSend()}>{t('rating.send-button')}</button>
        </div>
    );
})

export default RatingPage;