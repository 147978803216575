import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Recharge, NativeMessageType } from 'models';
import './RechargeModal.scss';
import { LiveWebAdapter } from 'utils';
import { Avatar } from 'components';
import lottie from 'lottie-web';
import { analysisLogEvent, AnalysisEventName } from 'analysis';
import { useFirstRecharge } from 'hooks';
import { updateUpdateVisible } from 'store/app';
import store from 'store'
import config from 'config';

const RechargeCallModal = React.memo((props: {
    visible?: boolean;
    onClose?: () => void;
}) => {
    const { t } = useTranslation()
    const { visible, onClose } = props
    const account = useSelector(state => (state as any).account).value
    const list = useSelector(state => (state as any).rechargeList).value as Recharge[]
    const [show, setShow] = React.useState<undefined | boolean>(false)
    const [end, setEnd] = React.useState(false)
    const [showRecharge, setShowRecharge] = React.useState(false)
    const rechargeCall = useSelector(state => (state as any).app).rechargeCall
    const hangupRef = React.useRef(null)
    const answerRef = React.useRef(null)
    const callingRef = React.useRef(null)
    const [currentDate, setCurrentDate] = React.useState<Date>(new Date())
    const expireDate = rechargeCall.expireDate
    let time = Math.max(0, Math.round((expireDate && expireDate.getTime() - currentDate.getTime()) / 1000))

    const [animationStep, setAnimationStep] = React.useState(0)

    const { firstRechargeRight } = useFirstRecharge()
    const right = firstRechargeRight && firstRechargeRight.right

    React.useEffect(() => {
        // 执行每秒定时器
        let timer = setInterval(() => {
            setCurrentDate(new Date())
            if (expireDate && currentDate.getTime() > expireDate.getTime()) {
                setEnd(true)
            }
        }, 1000)
        return () => {
            clearInterval(timer)
        }
    }, [setCurrentDate, expireDate, currentDate, setEnd])

    React.useLayoutEffect(() => {
        setTimeout(() => {
            setShow(visible)
        })
        if (visible) {
            analysisLogEvent(AnalysisEventName.recharge_modal_enter)
            setTimeout(() => {
                setShowRecharge(true)
            }, 3500)
            setAnimationStep(1)
            setTimeout(() => {
                setAnimationStep(2)
            }, 1500)
        }
    }, [visible, setShow, setShowRecharge, setAnimationStep])

    const close = React.useCallback(() => {
        setShow(false)
        setAnimationStep(0)
        setTimeout(() => {
            typeof onClose === 'function' && onClose()
            setEnd(false)
            setShowRecharge(false)
        }, 250)
    }, [onClose, setEnd, setAnimationStep])

    if (!visible) {
        return null
    }
    return (
        <div className={classNames('recharge-modal', !show && 'hide')}>
            {
                !end &&
                <div className='call-page-container'>
                    <div className='call-page-main'>
                        <div className='call-page'>
                            <LottieBGView></LottieBGView>
                            <div className='background'>
                                <div className='image'
                                    style={(rechargeCall && rechargeCall.userInfo && rechargeCall.userInfo.profile && rechargeCall.userInfo.profile.head) ? {
                                        background: `url(${rechargeCall.userInfo.profile.head}) no-repeat center/ cover`
                                    } : undefined}
                                ></div>
                                <div className='mask'></div>
                            </div>
                            <div className='countdown'>{time}s</div>
                            <div className={classNames(
                                "circle-container",
                                animationStep >= 1 && 'animation-move-step-1',
                                animationStep >= 2 && 'animation-rotate'
                            )}>
                                <svg width="137" height="137" style={{ overflow: 'visible', transform: 'rotate(-90deg)' }} className="circle">
                                    <circle
                                        className="path"
                                        cx="68.5"
                                        cy="68.5"
                                        r="68.5"
                                        strokeWidth="4"
                                        stroke="var(--system-pink)"
                                        fill="none"
                                        strokeDasharray={`${Math.ceil((45 / 45) * 430)} 430`}
                                        strokeLinecap="round"
                                    ></circle>
                                </svg>
                                <Avatar className='avatar' src={rechargeCall.userInfo && rechargeCall.userInfo.profile && rechargeCall.userInfo.profile.head}></Avatar>
                            </div>
                            <div className='countdown'>{time}s</div>
                            <div
                                className={classNames(
                                    'content-main',
                                    animationStep >= 1 && 'animation-move-step-1'
                                )}
                            >
                                <p className='nickname'>{rechargeCall.userInfo && rechargeCall.userInfo.profile.nickname}</p>
                                <div className='desc'>
                                    {
                                        time >= 35 ?
                                            t('call.desc1') :
                                            time >= 25 ?
                                                t('call.desc2') :
                                                time >= 15 ?
                                                    t('call.desc3') :
                                                    t('call.desc4')
                                    }
                                </div>
                                <div className='buttons'>
                                    <div className='hangup-button touch-opacity' onClick={() => { close() }}></div>
                                </div>
                                <div className='call-bottom-lottie'></div>
                            </div>
                            <audio src={`${process.env.PUBLIC_URL}/audio/answer.mp3`} loop={false} ref={answerRef}></audio>
                            <audio src={`${process.env.PUBLIC_URL}/audio/hangup.mp3`} loop={false} ref={hangupRef}></audio>
                            <audio src={`${process.env.PUBLIC_URL}/audio/calling.mp3`} loop={true} autoPlay ref={callingRef}></audio>
                        </div>
                    </div>
                </div>
            }
            <div className={classNames('mask', !showRecharge && 'hide')} onClick={() => close()}></div>
            {
                !right ?

                    <div className={classNames('content-view', !showRecharge && 'hide')}>
                        <div className='content-header'>
                            <div className='title'>{t('recharge.title')}</div>
                            <div className='header-token-view'>
                                <div className='token-icon'></div>
                                <div className='token-label'>{account && account.balance ? account.balance : 0}</div>
                            </div>
                        </div>
                        <div className='list'>
                            {
                                list.map((item) => {
                                    return (
                                        <RechargeItemView item={item} key={item.code} />
                                    )
                                })
                            }
                        </div>
                    </div>
                    :
                    <div className={classNames('first-call-recharge-content-view', !showRecharge && 'hide')}
                        onClick={() => {
                            analysisLogEvent(AnalysisEventName.first_call_recharge_button_click)
                            LiveWebAdapter.postMessage({
                                type: NativeMessageType.firstRecharge,
                                content: right.desc
                            })
                        }}
                    >
                        <div className='first-call-recharge-head'></div>
                        <div className='first-call-recharge-title'>{t('call.first-recharge.desc')}</div>
                        <div className='first-call-recharge-button'>{t('call.continue')}</div>
                    </div>
            }
        </div>
    )
})

const RechargeItemView = React.memo((props: {
    item: Recharge
}) => {
    const { item } = props;
    const { t } = useTranslation()
    let level = React.useMemo(() => {
        if (item.token_amount) {
            if (item.token_amount >= 14000) {
                return 7
            }
            if (item.token_amount >= 6900) {
                return 6
            }
            if (item.token_amount >= 3700) {
                return 5
            }
            if (item.token_amount >= 2800) {
                return 4
            }
            if (item.token_amount >= 1200) {
                return 3
            }
            if (item.token_amount >= 600) {
                return 2
            }
        }
        return 1
    }, [item])

    let extraToken = React.useMemo(() => {
        let original_token_amount = Number(item.original_token_amount)
        let token_amount = Number(item.token_amount)
        if (original_token_amount && token_amount) {
            return token_amount - original_token_amount
        }
        return 0
    }, [item])

    return (
        <div className='recharge-item-view touch-without-feedback'
            onClick={() => {
                analysisLogEvent(AnalysisEventName.recharge_modal_item_click)
                if (config.needUpdate === true) {
                    updateUpdateVisible(true)(store.dispatch)
                } else {
                    LiveWebAdapter.postMessage({
                        type: NativeMessageType.recharge,
                        content: item
                    })
                }
            }}
        >
            <div className='content'>
                <div className={classNames('token-icon', `level-${level}`)}></div>
                <div className='token-label'>{(item.token_amount ?? 0) - extraToken}</div>
                <div className='price'>${item.price}</div>
                {extraToken && extraToken > 0 ? <div className='extra-token'>{t('recharge.extra')} {extraToken}</div> : <></>}
            </div>

        </div>
    )
})

const LottieBGView = () => {
    const lottieRef = React.useRef(null)
    const lampRef = React.useRef(null)
    React.useEffect(() => {
        let element = lampRef.current as any
        lottie.loadAnimation({
            container: element,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../../assets/lottie/call/lamp.json'),
            rendererSettings: {
                filterSize: {
                    width: '100%',
                    height: '100%',
                    x: '0%',
                    y: '0%',
                },
                preserveAspectRatio: 'xMaxYMid slice'
            }
        });
    }, [lampRef])

    React.useEffect(() => {
        let element = lottieRef.current as any
        lottie.loadAnimation({
            container: element,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../../assets/lottie/call/bjcall.json'),
            rendererSettings: {
                filterSize: {
                    width: '100%',
                    height: '100%',
                    x: '0%',
                    y: '0%',
                },
                preserveAspectRatio: 'xMaxYMid slice'
            }
        });
    }, [lottieRef])
    return (
        <>
            <div className='lottie-container' ref={lottieRef}></div>
            <div className='lottie-container' ref={lampRef}></div>
        </>
    )
}

export default RechargeCallModal