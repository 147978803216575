import ReconnectingWebSocket from 'reconnecting-websocket';
import config from '../config';
import { WebSocketEvent, WebSocketCode, ConnectStatus, GlobalEvent } from '../models';
import api from './api';
import { Log, NotificationCenter } from '../utils';
import store from 'store';

const wsClient: {
    rws?: ReconnectingWebSocket,
    client_id?: number,
    status: ConnectStatus,
    last_ping_date?: Date,
    hiddenTime?: number,
    connect: () => void,
    disconnect: () => void,
    keep: () => void
} = {
    client_id: undefined,
    status: ConnectStatus.disconnect,
    last_ping_date: undefined,
    connect: () => {
        Log.print('[Websocket]: connect')
        var rws = new ReconnectingWebSocket(config.server.wss)
        wsClient.status = ConnectStatus.connecting
        rws.onmessage = async (e) => {
            let data = JSON.parse(e.data)
            let event = data as WebSocketEvent
            Log.print('[Websocket]: onmessage', event)
            if (event && store.getState().account.value) {
                if (event.event_code === WebSocketCode.connect) {
                    // 绑定客户端id
                    let parameters = {
                        client_id: event.data.client_id
                    }
                    await api.pusherBind(parameters)
                    wsClient.status = ConnectStatus.connected
                } else {
                    // 发布其他事件
                    NotificationCenter.post(GlobalEvent.websocket, data)
                }
            }
        }
        rws.onerror = () => {
            Log.print('[Websocket]: onerror')
            wsClient.status = ConnectStatus.disconnect
        }
        rws.onclose = () => {
            Log.print('[Websocket]: onclose')
            wsClient.status = ConnectStatus.disconnect
        }
        wsClient.rws = rws
    },
    disconnect: () => {
        Log.print('[Websocket]: disconnect')
        wsClient.status = ConnectStatus.disconnect
        wsClient.rws?.close()
        wsClient.client_id = undefined
        wsClient.rws = undefined
    },
    keep: () => {
        setInterval(() => {
            // 判断是否需要发送ping消息
            if (wsClient.status !== ConnectStatus.connected) { return }
            if (wsClient.last_ping_date === undefined || new Date().getTime() - wsClient.last_ping_date.getTime() > 10 * 1000) {
                wsClient.rws?.send('ping')
                wsClient.last_ping_date = new Date()
                Log.print('[Websocket]: 已发送websocket ping心跳')
            }
        }, 1000)

        document.addEventListener('visibilitychange', function () {
            if (store.getState().account.value) {
                if (document.visibilityState === 'hidden') {
                    wsClient.hiddenTime = new Date().getTime()	//记录页面隐藏时间
                } else {
                    let visibleTime = new Date().getTime();
                    if (wsClient.hiddenTime && (visibleTime - wsClient.hiddenTime) / 1000 > 10) {	//页面再次可见的时间-隐藏时间>10S,重连
                        wsClient.disconnect()
                        Log.print('主动关闭连接后重连');
                        setTimeout(function () {
                            wsClient.connect()
                        }, 1500); // 1.5S后重连
                    } else {
                        Log.print('还没有到断开的时间')
                    }
                }
            }
        });
    }
}

wsClient.keep();

export default wsClient