import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { Avatar, List, Loading, Toast } from 'components';
import { api } from 'server';
import { PagePath, User, NetworkError } from 'models';
import '../FollowPage.scss';

const FollowingPage = React.memo(() => {
    const ref = useRef(null)

    const onFetch = (page: number, pageSize: number, startFetch: (data: User[]) => void, abortFetch: (error?: Error) => void) => {
        api.userFollowing({
            per_page: pageSize,
            page: page
        }).then((items) => {
            startFetch(items)
        }).catch(() => {
            abortFetch()
        })
    }

    const handleUnfollow = (item: User) => {
        let loading = Loading.show()
        api.userUnfollow(item.id ?? 0).then(() => {
            let list = (ref as any).current as List<User>
            let dataSource = list.state.dataSource
            dataSource = dataSource.filter(element => element.id !== item.id)
            list.setState({
                dataSource: dataSource,
                pageState: dataSource.length === 0 ? 'empty' : list.state.pageState
            })
            Loading.dismiss(loading)
        }).catch((err) => {
            let error = err as NetworkError
            Loading.dismiss(loading)
            Toast.show({ content: error.message })
        })
    }

    return (
        <div className='segmented-page'>
            <List
                ref={ref}
                className='list'
                onFetch={onFetch}
                renderItem={(item, index) => <FollowingListCellItem key={index} item={item} onClick={(item) => handleUnfollow(item)}></FollowingListCellItem>}
            ></List>
        </div>
    );
})

const FollowingListCellItem = React.memo((props: { item: any, onClick: (item: User) => void }) => {
    const { t } = useTranslation()
    const { item, onClick } = props
    const navigate = useNavigate()
    return (
        <div className='follow-list-cell-item'>
            <div className='touch touch-soft-highlight' onClick={() => navigate(PagePath.anchor_detail + `/${item.id}`)}></div>
            <Avatar className='avatar' src={item.profile?.head}></Avatar>
            <div className='nickname-wrapper'>
                <p className='nickname'>{item.profile?.nickname}</p>
            </div>
            <button className='remove-button touch-highlight' onClick={() => onClick(item)}>{t('follow.unfollow-button')}</button>
        </div>
    )
})

export default FollowingPage;