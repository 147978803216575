import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import lottie from 'lottie-web';
import { Avatar } from 'components';
import { useCall } from 'hooks';
import './CallPage.scss';
import classNames from 'classnames';

const CallPage = React.memo(() => {
    const hangupRef = React.useRef(null)
    const answerRef = React.useRef(null)
    const callingRef = React.useRef(null)
    const { t } = useTranslation()
    const { currentDate, expireDate, handleAnswer, handleHangup, callValue, tipsShow } = useCall();
    const [animationStep, setAnimationStep] = React.useState(0)

    useEffect(() => {
        (callingRef.current as any).play()
    }, [callingRef])

    useEffect(() => {
        setAnimationStep(1)
        setTimeout(() => {
            setAnimationStep(2)
        }, 1500)
    }, [setAnimationStep])
    let time = Math.max(0, Math.round((expireDate && expireDate.getTime() - currentDate.getTime()) / 1000))
    return (
        <div className='call-page'>
            <LottieBGView></LottieBGView>
            <div className='background'>
                <div className='image'
                    style={(callValue && callValue.other_people.profile.head) && {
                        background: `url(${callValue.other_people.profile.head}) no-repeat center/ cover`
                    }}
                ></div>
                <div className='mask'></div>
            </div>
            <div
                className={classNames(
                    "circle-container",
                    animationStep >= 1 && 'animation-move-step-1',
                    animationStep >= 2 && 'animation-rotate'
                )}
            >
                <svg width="137" height="137" style={{ overflow: 'visible', transform: 'rotate(-90deg)' }} className="circle">
                    <circle
                        className="path"
                        cx="68.5"
                        cy="68.5"
                        r="68.5"
                        strokeWidth="4"
                        stroke="var(--system-pink)"
                        fill="none"
                        strokeDasharray={`${Math.ceil((45 / 45) * 430)} 430`}
                        strokeLinecap="round"
                    ></circle>
                </svg>
                <Avatar className='avatar' src={callValue && callValue.other_people.profile.head}></Avatar>
            </div>
            <div className='countdown'>{time}s</div>
            <div
                className={classNames(
                    'content-main',
                    animationStep >= 1 && 'animation-move-step-1'
                )}
            >
                <p className='nickname'>{callValue && callValue.other_people.profile.nickname}</p>
                {
                    tipsShow && false ?
                        <div className='tips'>
                            <div className='content-view'>
                                <div className='title'>{t('call.tips.title').replace('[i]', window.location.host)}</div>
                                <div className='message'></div>
                                <div className='action-wrapper'>
                                    <div className='action-button'>{t('call.tips.notallow')}</div>
                                    <div className='action-button'>{t('call.tips.alllow')}<div className='circle'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='poiner'></div>
                            <div className='tips-desc'>{t('call.tips.desc')}</div>
                        </div>
                        :
                        <div className='desc'>
                            {
                                time >= 35 ?
                                    t('call.desc1') :
                                    time >= 25 ?
                                        t('call.desc2') :
                                        time >= 15 ?
                                            t('call.desc3') :
                                            t('call.desc4')
                            }
                        </div>
                }
                <div className='buttons'>
                    <div className='hangup-button touch-opacity' onClick={() => {
                        (hangupRef.current as any).play()
                        handleHangup()
                    }}></div>
                    {callValue && callValue.direction === 1 && <div className='answer-button touch-opacity' onClick={() => {
                        (answerRef.current as any).play()
                        handleAnswer()
                    }}></div>}
                </div>
            </div>
            <audio src={`${process.env.PUBLIC_URL}/audio/answer.mp3`} loop={false} ref={answerRef}></audio>
            <audio src={`${process.env.PUBLIC_URL}/audio/hangup.mp3`} loop={false} ref={hangupRef}></audio>
            <audio src={`${process.env.PUBLIC_URL}/audio/calling.mp3`} loop={true} autoPlay ref={callingRef}></audio>
        </div>
    );
})

const LottieBGView = () => {
    const lottieRef = React.useRef(null)
    const lampRef = React.useRef(null)
    React.useEffect(() => {
        let element = lampRef.current as any
        lottie.loadAnimation({
            container: element,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../../../assets/lottie/call/lamp.json'),
            rendererSettings: {
                filterSize: {
                    width: '100%',
                    height: '100%',
                    x: '0%',
                    y: '0%',
                },
                preserveAspectRatio: 'xMaxYMid slice'
            }
        });
    }, [lampRef])

    React.useEffect(() => {
        let element = lottieRef.current as any
        lottie.loadAnimation({
            container: element,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../../../assets/lottie/call/bjcall.json'),
            rendererSettings: {
                filterSize: {
                    width: '100%',
                    height: '100%',
                    x: '0%',
                    y: '0%',
                },
                preserveAspectRatio: 'xMaxYMid slice'
            }
        });
    }, [lottieRef])
    return (
        <>
            <div className='lottie-container' ref={lottieRef}></div>
            <div className='lottie-container' ref={lampRef}></div>
        </>
    )
}

export default CallPage;