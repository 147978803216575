import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'components';
import '../LegalPage.scss';

const PrivacyPolicyPage = React.memo(() => {
  const { t } = useTranslation()
  return (
    <div className='legal-page'>
      <Header title={t('legal.privacy-prolicy')}></Header>
      <div className='content'>
        This app is a live video chat mobile application. You can meet cool and interesting people from all over the world, enjoy fun chats, and make new friends on our platform.

        Personal Information about our users is an integral part of our business, so please read this Privacy Policy carefully. By using or accessing our App or our Service, you agree to the privacy policies outlined below.

        What Does this Privacy Policy Cover?

        This Privacy Policy covers This app treatment of Personal Information that we collect while you are using the App and the Service. This policy does not apply to the practices of third parties that This app does not own or control, including but not limited to third party services you access through This app, the App or the Service, or to individuals that This app does not employ or manage. As our Service integrates with third party sites, such as Facebook, and other sites and social networks, we assume that you are already aware of the privacy policies of those other sites and networks when using our App and/or our Service and when you allow us to interact with these sites and networks on your behalf.

        The Service

        The key functionality of This app is a real-time video chat mobile application where users can meet cool and interesting people from all over the world, enjoy fun chats, and make new friends on our platform. It pairs individuals (“Users”) together based on compatibility and other factors, and to enable individuals to chat and communicate online through various media including text, video, linking and accessing third party social network platforms such as but not limited to Snapchat (collectively, the “Service”).

        Information We Collect

        There are three basic ways we collect information:

        • - Information you choose to give us

        • - Information we get when you use our services

        • - Information we get from third parties

        Here’s a little more detail on each of these categories.

        Information You Choose to Give Us

        Registration Data: By visiting and interacting with This app, its App and/or its Service, you provide us with Personal Information which may include, but is not limited to, the following:

        • - Personally Identifiable Information: may include, without limitation, information provided when you register to use the App and/or the Service, including your name, profile picture, date of birth, phone number, phone book content, address, email address, occupation, employer, gender, age, birthday, Facebook account info and friend list, and/or other social network usernames and ‘likes' and preferences, friend lists and number of friends you have via social networks, messages to other users of the App and the Service, use information regarding your use of the Service, profile picture that you upload via the App or the Service, and browser information.

        Of course, you’ll also provide us whatever information you send through the services. Keep in mind that the users you send texts, images, and any other content to can always save that content or copy it outside the app. So, the same common sense that applies to the Internet at large applies to This app as well: Don’t send messages or share content that you wouldn’t want someone to save or share.

        It probably goes without saying, but we’ll say it anyway: When you contact This app Support or communicate with us in any other way, we’ll collect whatever information you volunteer.

        Information We Get When You Use Our Services

        When you use our services, we collect information about which of those services you’ve used and how you’ve used them. We might know, for instance, that you used one of the filter in app and the number of times you have used this filter. Here’s a fuller explanation of the types of information we collect when you use our services:

        1. Account Data.
        We collect information about your activity through our services. We may collect information including but not limited to:

        • - How you interact with the services, such as which filters you view or the number of times apply to discover.

        • - How you communicate with other This app users, such as their names, the time and date of your communications, the number of messages you exchange with your friends, which friends you exchange messages with the most, and your interactions with messages (such as when you open a message or capture a screenshot). We allow you to share text messages, photos, screenshots, videos and other forms of communications on our service with other users, and if you choose to do so your text messages, photos, screenshots, videos and other communications may be stored on our servers. In addition, if you choose to share these items with other users, we may not be able to remove them from our servers or make them unavailable to anyone you have shared them with. Sending and sharing text messages, photos, screenshots, videos or other communications on our service is your decision. By choosing to share that information, you should understand that you may no longer be able to control how that information is used and that it may become publicly available. We are not responsible for any use or misuse of information you share.

        • - Device Information. We collect device-specific information, such as the hardware model, operating system version, advertising identifier, unique application identifiers, unique device identifiers, browser type, language, wireless network, none direct display of content moderation, and mobile network information (including the mobile phone number).

        • - User content e.g. photos, screenshots, comments, and other materials that you create on the service may also be collected. Your photos and videos may be captured by other users on the service, and those content can be stored and used by us or third party services (e.g. Facebook) for content moderation and community safety monitoring purposes. You can change third party sharing options in our product settings or writing to us directly.

        2. During usage of the App
        • - Device Phonebook. Because This app is all about communicating with friends, we may—with your consent—collect information from your device’s phonebook.


        3. Log Data
        • - We also collect log information when you use our website. That information includes, among other things:

        • - Details about how you’ve used our services.

        • - Device information, such as your web browser type and language.

        • - Access times.

        • - Pages viewed.

        • - IP address.

        • - Identifiers associated with cookies or other technologies that may uniquely identify your device or browser.

        • - Pages you visited before or after navigating to our website.

        • - If you contact us by email and other means (Twitter, Facebook etc) we may keep a record of your contact information and correspondence and will use these contact info and correspondence if we respond to you.

        Information We Collect from Third Parties

        • We may collect information that other users provide about you when they use our services. For example, if another user allows us to collect information from their device phonebook—and you’re one of that user’s contacts—we may combine the information we collect from that user’s phonebook with other information we have collected about you. We may also obtain information from other companies that are owned or operated by us, or any other third-party sources, and combine that with the information we collect through our services.

        How We Use Information

        • What do we do with the information we collect? The short answer is: Provide you with an amazing set of products and services that we relentlessly improve. But we do a lot more as well, such as:

        • - Develop, operate, improve, deliver, maintain, and protect our products and services.

        • - Communicate with you.

        • - Monitor and analyze trends and usage.

        • - Personalize the services by, among other things, suggesting friends or profile information, or customizing the content we show you, including ads.

        • - Enhance the safety and security of our products and services.

        • - Verify your identity and prevent fraud or other unauthorized or illegal activity.

        • - Use information we’ve collected from cookies and other technology to enhance the services and your experience with them.

        • - Enforce our Terms of Service and other usage polices.

        We may also store some information locally on your device. For example, we may store information as local cache so that you can open the app and view content faster.
      </div>
    </div>
  );
})

export default PrivacyPolicyPage;