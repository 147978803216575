import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Header, List, Avatar } from 'components';
import { api } from 'server';
import { Expense, BizCode } from 'models';
import './ExpensesPage.scss';
import { Calculator } from 'utils';
import classNames from 'classnames';

const ExpensesPage = React.memo(() => {
    const { t } = useTranslation()

    const onFetch = (page: number, pageSize: number, startFetch: (data: Expense[]) => void, abortFetch: (error?: Error) => void) => {
        api.userExpends({
            per_page: pageSize,
            page: page
        }).then((items) => {
            startFetch(items)
        }).catch(() => {
            abortFetch()
        })
    }

    return (
        <div className='expenses-page'>
            <Header title={t('expenses.title')}></Header>
            <List
                className='list'
                onFetch={onFetch}
                renderItem={(item, index) => <ExpensesListCellItem key={index} item={item}></ExpensesListCellItem>}
            ></List>
        </div>
    );
})

export const ExpensesListCellItem = React.memo((props: { item: Expense }) => {
    const { item } = props;
    return (
        <div className='expenses-list-cell-item'>
            <Avatar className={classNames(
                'avatar',
                item.biz_code === BizCode.message && 'message',
                item.biz_code === BizCode.recharge && 'recharge',
                item.biz_code !== BizCode.call && item.biz_code !== BizCode.message && item.biz_code !== BizCode.recharge && 'other'
            )} src={item.biz_code === BizCode.call ? item.biz?.other_people.profile.head : undefined}></Avatar>
            <div className='content-wrapper'>
                <p className='title-label'>{item.biz_code === BizCode.call ? item.biz?.other_people.profile.nickname : item.biz_name}</p>
                <div className='time-label'>
                    {(item.biz_code === BizCode.call || item.biz_code === BizCode.message)
                        && <span className={classNames(
                            'label-icon',
                            item.biz_code === BizCode.call && 'call',
                            item.biz_code === BizCode.message && 'message')}
                        />}
                    <p className='label-text'>{dayjs((item.occurred_on ?? 0) * 1000).format(Calculator.defaultDateFormat)}</p></div>
            </div>
            <div className='token-wrapper'>
                <div className='token-img'></div>
                <p className='token-label' style={{ color: item.amount && item.amount >= 0 ? 'var(--system-green)' : 'var(--system-yellow)' }}>{item.amount && item.amount > 0 ? "+" : ""}{item.amount ?? 0}</p>
            </div>
        </div>
    )
})

export default ExpensesPage;