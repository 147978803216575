import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Header, select } from 'components';
import { useEdit } from 'hooks';
import '../EditPage.scss';

const EditHairPage = React.memo(() => {
    const { t } = useTranslation()
    const { handleSave } = useEdit()
    const [hair, setHair] = useState("");
    const account = useSelector(state => (state as any).account).value
    useEffect(() => {
        setHair(account.profile.attrs.hair_color)
    }, [account])
    const hairOptions = select.hairOptions
    return (
        <div className='edit-other-page'>
            <Header title={t('personal.hair')}><button className='save-button touch-opacity' onClick={() => handleSave({ attrs: { hair_color: hair } })}>{t('common.save')}</button></Header>
            <Select
                styles={select.styleConfig}
                className='select-wrapper'
                value={hairOptions.find((item) => { return typeof (hair) === 'string' ? item.value.toLowerCase() === hair.toLowerCase() : false }) ?? hairOptions[0]}
                options={hairOptions}
                onChange={(value) => {
                    let obj = value as any
                    setHair(obj.value as string)
                }}
            />
        </div>
    );
})

export default EditHairPage;