import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link, useMatch } from "react-router-dom";
import { Header } from 'components';
import { Account, PagePath } from 'models';
import './FollowPage.scss';
import FollowerPage from './page/FollowerPage';
import FollowingPage from './page/FollowingPage';

const FollowPage = React.memo(() => {
    const { t } = useTranslation()
    const matchFollowing = useMatch(PagePath.accounts_following) != null;
    const matchFollower = useMatch(PagePath.accounts_follower) != null;
    const account = useSelector(state => (state as any).account).value as Account
    return (
        <div className='follow-page'>
            <Header title={account.profile?.nickname}></Header>
            <div className='segmented-control'>
                <Link className={classNames('item', 'touch-opacity', matchFollowing && 'active')} to={PagePath.accounts_following} replace>
                    <span className='label'>{t('follow.following.title')}</span>
                </Link>
                <Link className={classNames('item', 'touch-opacity', matchFollower && 'active')} to={PagePath.accounts_follower} replace>
                    <span className='label'>{t('follow.follower.title')}</span>
                </Link>
            </div>
            {matchFollowing && <FollowingPage></FollowingPage>}
            {matchFollower && <FollowerPage></FollowerPage>}
        </div>
    );
})

export default FollowPage;