import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'components';
import '../LegalPage.scss';

const TermsOfServicePage = React.memo(() => {
  const { t } = useTranslation()
  return (
    <div className='legal-page'>
      <Header title={t('legal.terms-of-service')}></Header>
      <div className='content'>
        Welcome to This app!

        We've drafted these Terms of Service (which we simply call the "Terms") so that you'll know the rules that govern our relationship with you. Although we have tried our best to strip the legalese from the Terms, there are places where these Terms may still read like a traditional contract. There's a good reason for that: These Terms do indeed form a legally binding contract between you and This app Inc Limited. So please read them carefully.

        By using the Services, you agree to the Terms. Of course, if you don't agree with them, then don't use the Services.

        The following Terms of Service outline your obligations when using our mobile application (“App”) or Service (as defined herein), or any of the information, text, graphics, videos, or other files, materials, data or content of any kind whatsoever created or provided by or through the App or the Service or through your ability to sell products on the App and generate User Contributed Content (as defined herein). Please also review our Privacy Policy, which is a part of these Terms of Service and which outlines our practices towards handling any personal information that you may provide to us.

        The App and the Service are owned and operated by This app Inc Limited. (“This app,” “we,” or “us”) and are accessed by you under the Terms of Service described herein (“Terms of Service” or “Agreement”). Please read these Terms of Service carefully before using the App or the Service. By accessing the App or using any part of the Service, you agree to become bound by these terms and conditions. If you do not agree to all these terms and conditions, then you may not access the App or use the Service. Nothing in this Agreement shall be deemed to confer any third party rights or benefits.

        1. Who Can Use the Services

        No one under 18 is allowed to create an account or use the Services. We may offer additional Services with additional terms that may require you to be even older to use them. So please read all terms carefully.

        -You can form a binding contract with This app;

        -You will comply with these Terms and all applicable local, state, national, and international laws, rules, and regulations.

        No one under 18 is allowed to create an account or use the Services.

        You hereby warrant that you are at least 18 years old. In the event that the information you provide in this regard is not truthful, This app shall not be liable as it cannot verify the age of its users. If you are under 18 years old, do not attempt to register the App or the Service. If you are under the age of 18, you may use the Service, only with the approval of your parent or guardian. In addition, you should review these terms with your parent or guardian to make sure that you and your parent or guardian understand and agree with these terms.

        2. Rights We Grant You

        This app grants you a personal, worldwide, royalty-free, non-assignable, nonexclusive, revocable, and non-sublicensable license to access and use the Services. This license is for the sole purpose of letting you use and enjoy the Service's benefits in a way that these Terms and our usage policies.

        Any software that we provide you may automatically download and install upgrades, updates, or other new features. You may be able to adjust these automatic downloads through your device's settings.

        You may not copy, modify, distribute, sell, or lease any part of our Services, nor may you reverse engineer or attempt to extract the source code of that software, unless applicable laws prohibit these restrictions or you have our written permission to do so.

        3. Rights You Grant Us

        Many of our Services let you create, upload, post, send, receive, and store content. When you do that, you retain whatever ownership rights in that content you had to begin with. But you grant us a license to use that content. How broad that license is depends on which Services you use and the Settings you have selected.

        For all Services, you grant This app a worldwide, royalty-free, sublicensable, and transferable license to host, store, use, display, reproduce, modify, adapt, edit, publish, and distribute that content. This license is for the limited purpose of operating, developing, providing, promoting, and improving the Services and researching and developing new ones.

        Because Local is inherently public and chronicle matters of public interest, the license you grant us for content submitted to those Services is broader. In addition to the rights you grant us in connection with other Services, you also grant us a perpetual license to create derivative works from, promote, exhibit, broadcast, syndicate, publicly perform, and publicly display content submitted to Local or any other crowd-sourced Services in any form and in any and all media or distribution methods (now known or later developed). To the extent it's necessary, you also grant This app and our business partners the unrestricted, worldwide, perpetual right and license to use your name, likeness, Local content that you upload or send. This means, among other things, that you will not be entitled to any compensation from This app or our business partners if your name, likeness or Local.

        While we're not required to do so, we may access, review, screen, and delete your content at any time and for any reason, including if we think your content violates these Terms. You alone though remain responsible for the content you create, upload, post, send, or store through the Service.

        The Services may contain advertisements. In consideration for This app letting you access and use the Services, you agree that This app, its affiliates, and third-party partners may place advertising on the Services.

        We always love to hear from our users. But if you volunteer feedback or suggestions, just know that we can use your ideas without compensating you.

        4. The Content of Others

        Much of the content on our Services is produced by users, publishers, and other third parties. Whether that content is posted publicly or sent privately, the content is the sole responsibility of the person or organization that submitted it. Although This app reserves the right to review all content that appears on the Services and to remove any content that violates these Terms, we do not necessarily review all of it. So we cannot—and do not—take responsibility for any content that others provide through the Services.

        Through these Terms, we make clear that we do not want the Services put to bad uses. But because we do not review all content, we cannot guarantee that content on the Services will always conform to our Terms.

        We do not endorse, support, represent or guarantee the truthfulness, accuracy, or reliability of any content contributed by users or endorse any of the opinions expressed therein. You agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against us with respect thereto. You acknowledge that any reliance on User Contributed Content of any This app user will be at your own risk.

        5. Privacy

        Your privacy matters to us. You can learn how we handle your information when you use our Services by reading our privacy policy. We encourage you to give the privacy policy a careful look because, by using our Services, you agree that This app can collect, use, and transfer your information consistent with that policy.

        6. Respecting Other People's Rights

        This app respects the rights of others. And so should you. You therefore may not upload, send, or store content that:

        - violates or infringes someone else's rights of publicity, privacy, copyright, trademark, or other intellectual-property right;

        - bullies, harasses, or intimidates;

        - defames; or spams or solicits This app's users;

        - is inappropriate to other users or illegal, including, but not limited to, anything that is defamatory, inaccurate, unlawful, harmful, threatening, abusive, harassing, vulgar, offensive, obscene, pornographic, hateful, or promotes violence, discrimination, bigotry, racism, or hatred, as determined by This app in its sole discretion;

        - introduces viruses, time-bombs, worms, cancelbots, Trojan horses and/or other harmful code, - is reverse look-up or trace any information of any other User or visitor or otherwise use the Services for the purpose of obtaining information of any User or visitor;

        - attempts to gain unauthorized access to any portion of the Services, or any systems or networks by hacking, password "mining" or any other illegitimate means;

        - uses any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any manual process to access, acquire, copy or monitor any portion of the Services or any data or materials contained therein or obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the System;

        - asks or offers sexually explicit images or engage in any activity harmful to minors or otherwise violates these terms.

        You must also respect This app's rights. These Terms do not grant you any right to:

        - use branding, logos, designs, photographs, videos, or any other materials used in our Services;

        - copy, archive, download, upload, distribute, syndicate, broadcast, perform, display, make available, or otherwise use any portion of the Services or the content on the Services except as set forth in these Terms;

        - use the Services or any content on the Services for any commercial purposes without our consent.

        In short: You may not use the Services or the content on the Services in ways that are not authorized by these Terms. Nor may you help or enable anyone else in doing so.

        We reserve the right at all times (but will not have an obligation) to remove or refuse to distribute any content contributed by This app users, such as content which violates these Terms of Service. We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to :

        - satisfy any applicable law, regulation, legal process or governmental request;

        - enforce these Terms of Service, including investigation of potential violations hereof;

        - detect, prevent, or otherwise address fraud, security or technical issues;

        - respond to member support requests, or protect our rights, property or safety, and that of our users and the public.

        We will not be responsible or liable for the exercise or non-exercise of this right under these Terms of Service.

        You understand that by using or accessing the App or the Service you may be exposed to content that might be offensive, harmful, inaccurate or otherwise inappropriate material, or in some cases, postings that have been mislabeled or are otherwise deceptive. Under no circumstances will we be liable in any way for any content or communications, including, but not limited to, any errors or omissions in any form of User Contributed Content, or any loss or damage of any kind incurred as a result of the use of any This app content or content posted by users which are posted, emailed, transmitted or otherwise made available on the App or through the Service.

        8. Safety

        We try hard to keep our Services a safe place for all users. But we can't guarantee it. That's where you come in. By using the Services, you agree that:

        - You will not use the Services for any purpose that is illegal or prohibited in these Terms.

        - You will not use or develop any third-party applications that interact with the Services or other users' content or information without our written consent.

        - You will not use the Services in a way that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the Services, or that could damage, disable, overburden, or impair the functioning of the Services.

        - You will not use or attempt to use another user's account, username, or password without their permission.

        - You will not solicit login credentials from another user.

        - You will not post content that contains pornography, graphic violence, threats, hate speech, guns or other weaponry, or incitements to violence.

        - You will not upload viruses or other malicious code or otherwise compromise the security of the Services.

        - You will not attempt to circumvent any content-filtering techniques we employ, or attempt to access areas or features of the Services that you are not authorized to access.

        - You will not probe, scan, or test the vulnerability of our Services or any system or network.

        - You will not encourage or promote any activity that violates these Terms.

        We have the right to investigate and prosecute violations of any of the above, including intellectual property rights infringement and App security issues, to the fullest extent of the law. We may involve and cooperate with law enforcement authorities in prosecuting This app users who violate these Terms of Service. You acknowledge that we have the right to monitor your access to or use of the App and/or the Service for operating purposes, to ensure your compliance with these Terms of Service, or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body.

        We also care about your safety while using our Services. So do not use our Services in a way that would distract you from obeying traffic or safety laws. And never put yourself or others in harm's way.

        9. Your Account

        You are responsible for any activity that occurs in your account. So it's important that you keep your account secure. One way to do that is to select a strong password that you don't use for any other account.

        By using the Services, you agree that, in addition to exercising common sense:

        - You will not create more than one account for yourself.

        - You will not create another account if we have already disabled your account, unless you have our written permission to do so.

        - You will not buy, sell, rent, or lease access to your This app account without our written permission.

        - You will not share your password.

        - You will not log in or attempt to access the Services through unauthorized third-party applications or clients.
      </div>
    </div>
  );
})

export default TermsOfServicePage;