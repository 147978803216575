import './Loading.scss';
import i18n from 'i18next';

type LoadingProps = {
    content?: string,
}

class Loading {
    static show(params?: LoadingProps) {
        // 检测是否包含未消失loading
        let oldLoadings = document.getElementsByClassName("loading-component")
        for (let i in oldLoadings) {
            oldLoadings[i].parentNode?.removeChild(oldLoadings[i])
        }
        // 创建loading
        let body = document.body;
        let loading = document.createElement('div')
        loading.className = "loading-component"
        let content = document.createElement('div')
        content.className = "content"
        let text = params?.content ?? `${i18n.t('server.loading')}...`
        content.innerHTML = `
        <div class='spinner-component'>
            <div style="font-size: 32px" class="loading"><svg style="fill: #ffffff" viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg></div>
            <div style='margin-top: 16px; max-width: 288px;word-break: break-all;text-align: center; font-size: var(--footnote);'>${text}</div>
       </div>
        `
        loading.appendChild(content)
        // 添加到body
        body.appendChild(loading);
        return loading
    }

    static dismiss(loading: HTMLDivElement) {
        let body = document.body;
        if (body.contains(loading)) {
            let content = loading.querySelector(".content")
            content?.classList.add("hide")
            content?.addEventListener('transitionend', function () {
                body.removeChild(loading)
            })
        }
    }

    static dismissAll() {
        let body = document.body;
        let oldLoadings = document.getElementsByClassName("loading-component")
        for (let i in oldLoadings) {
            let loading = oldLoadings[i]
            if (body.contains(loading)) {
                let content = loading.querySelector(".content")
                content?.classList.add("hide")
                content?.addEventListener('transitionend', function () {
                    body.removeChild(loading)
                })
            }
        }
    }
}

export default Loading