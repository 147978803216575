import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Header, Avatar, Spinner, Viewer, ListLoadingView } from 'components';
import './DirectPage.scss';
import { useDirect } from 'hooks';
import { messages, call } from 'server';
import { Role, Message, MessageType, MessageState, MessageUser, PagePath } from 'models';
import { Calculator } from 'utils';

const DirectPage = React.memo(() => {
    const { user, list, text, setText, handleSendTextMessage, handleReSendMessage, handleSendImageMessage, fileRef, loading, handleTranslate } = useDirect()
    const [image, setImage] = useState<string | undefined>(undefined)
    const { t } = useTranslation()
    const account = useSelector(state => (state as any).account).value
    const samples = [t('direct.sample.1'), t('direct.sample.2'), t('direct.sample.3'), t('direct.sample.4'), t('direct.sample.5')]
    return (
        <div className='direct-page'>
            <Header>
                {user?.role !== Role.support && <div className='call-button touch-opacity' onClick={() => user?.id && call.handleRechargeCall(user?.id, user)}></div>}
                <div className='custom-content'>
                    <div className='custom-title'>{`${user?.profile.nickname ?? ''}`}</div>
                    {user?.role !== Role.support && <div className='token-price'>
                        <div className='token-icon'></div>
                        <div className='token-label'>20/{t('inbox.message')}</div>
                    </div>}
                </div>
            </Header>
            {
                loading &&
                <ListLoadingView></ListLoadingView>
            }
            <>
                <DirectListView
                    list={list}
                    user={user}
                    resendClick={(item: Message) => {
                        handleReSendMessage(item)
                    }}
                    translateClick={(item: Message) => {
                        handleTranslate(item)
                    }}
                    imageClick={(item: Message) => {
                        setImage(item.content?.url)
                    }}
                />
                {
                    Array.isArray(list) && list.length > 0 ?
                        <></>
                        :
                        <div className='proposale-view'>
                            {
                                samples.map((item, index) => {
                                    return (
                                        <div className='proposale-item'>
                                            <div className='proposale-item-label'>{item}</div>
                                            <div className='proposale-item-button touch-opacity' onClick={() => {
                                                handleSendTextMessage(item)
                                            }}></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                }
                <div className='footer-view'>
                    <input
                        type="text"
                        className='input'
                        enterKeyHint="send"
                        placeholder={t('direct.footer.placeholder')}
                        value={text}
                        onChange={(e) => e.target.value.length < 1000 && setText(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSendTextMessage()
                            }
                        }}
                    />
                    <button className='image-button'>
                        <input
                            ref={fileRef}
                            type="file"
                            name="file"
                            id="image-file"
                            accept="image/*"
                            className="picker"
                            onChange={() => { handleSendImageMessage() }}
                        />
                    </button>
                </div>
            </>
            {image && <Viewer src={image} onClose={() => setImage(undefined)}></Viewer>}
        </div>
    );
})

// 聊天列表组件
const DirectListView = React.memo((props: {
    list: Message[],
    user?: MessageUser | undefined,
    resendClick?: (message: Message) => void,
    imageClick?: (message: Message) => void,
    translateClick?: (message: Message) => void
}) => {
    return (
        <div className='list-view'>
            {
                props.list.map((item) => {
                    return <DirectItemView item={item} key={item.msg_id} user={props.user} resendClick={props.resendClick} imageClick={props.imageClick} translateClick={props.translateClick} />
                })
            }
        </div>
    );
})

// 聊天列表文本Item组件
const DirectItemView = React.memo((props: {
    item: Message,
    user?: MessageUser | undefined,
    resendClick?: (message: Message) => void,
    imageClick?: (message: Message) => void,
    translateClick?: (message: Message) => void,
}) => {
    const { item, user, resendClick, imageClick, translateClick } = props;
    const account = useSelector(state => (state as any).account).value
    const isPeer = item.sender_id !== account.id
    return (
        <div className='list-item-view'>
            {(item.sent_at && item.sent_at > 0) ? <p className='time-label'>{Calculator.getMessageDateString(item.sent_at)}</p> : <></>}
            <div className={classNames('content', isPeer && 'peer')}>
                {isPeer &&
                    (
                        item.sender_id !== 100000
                            ?
                            <Link to={PagePath.anchor_detail + "/" + item.sender_id}><Avatar className='avatar-left' src={user?.profile.head} /></Link>
                            :
                            <Avatar className='avatar-left' src={user?.profile.head} />
                    )
                }
                {!isPeer && messages.sendingIds.has(item.msg_id) && <div className='spinner'><Spinner /></div>}
                {!isPeer && !messages.sendingIds.has(item.msg_id) && item.state === MessageState.error && <div className='resend-button touch-opacity' onClick={() => resendClick && resendClick(item)}></div>}
                {
                    item.content?.type === MessageType.image ?
                        // 图片
                        <div className='image-main-container touch-opacity' onClick={() => { imageClick && imageClick(item) }}>
                            <img src={item.content.url} alt="" className='image' />
                        </div> :
                        (item.content?.type === MessageType.voice ?
                            // 音频
                            <div className='voice-main-container'>
                                <audio controls>
                                    <source src={item.content.url} />
                                </audio>
                            </div> :
                            // 文本
                            <div className='text-main-container'>
                                <div className='text-main'>
                                    {item.content?.type === MessageType.call && <div className='call-miss'></div>}
                                    <div className='text-label'>{item.content?.text}</div>
                                </div>
                                {
                                    item.content?.type === MessageType.text && isPeer ?
                                        item.state === MessageState.translating ?
                                            <div className='spinner'><Spinner fontSize={16}></Spinner></div>
                                            :
                                            <div className='translate-button touch-opacity' onClick={() => translateClick && translateClick(item)}>
                                                <div className='icon'></div>
                                                <div className='label'>{item.content.original_text && item.content.original_text?.length > 0 ? "Show Origin" : "Translate"}</div>
                                            </div>
                                        :
                                        null
                                }
                            </div>
                        )
                }
                {!isPeer && <Avatar className='avatar-right' src={account?.profile.head} />}
            </div>
        </div>
    );
})

export default DirectPage;