import './Notification.scss';
import { Message, MessageUser, PagePath } from 'models';
import { Calculator } from 'utils';
import { history } from 'pages/App'

class Notification {
    static show(message: Message, user: MessageUser) {
        // 判断是否含有消息弹窗
        let oldElements = document.getElementsByClassName("notification-component")
        if (oldElements.length > 0 || history.location.pathname === PagePath.live_call || history.location.pathname === PagePath.live_conversation) { return }
        let element = document.createElement('div')
        element.className = 'notification-component hide'
        element.innerHTML =
            ` <div class='avatar'>
                <div class='img' style={${user.profile.head ? `background-image: url(${user.profile.head})` : ``}} ></div>
            </div>
            <audio src="${process.env.PUBLIC_URL}/audio/message.mp3" autoplay></audio>
      <div class='content'>
        <div class='nickname-label'>${user.profile.nickname}</div>
        <div class='message-label'>${Calculator.getMessageDesc(message)}</div>
        <div class='arrow'></div>
      </div>`
        document.body.append(element)
        element.onclick = () => {
            history.push(PagePath.message_direct + `/${message.sender_id}`)
            element.classList.add("hide")
            element.addEventListener('transitionend', function () {
                document.body.removeChild(element)
            })
        }
        setTimeout(() => {
            element.classList.remove('hide')
        }, 100)
        setTimeout(() => {
            if (document.body.contains(element)) {
                element.classList.add("hide")
                element.addEventListener('transitionend', function () {
                    if (document.body.contains(element)) {
                        document.body.removeChild(element)
                    }
                })
            }
        }, 3000)
    }
}



export default Notification