

import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StorageKey, Account } from 'models';
import store from 'store';
import { login, updateAccountRemote } from 'store/account';
import { resetUsers } from 'store/users';
import { ws, network, rtc, rtm, messages, webDB, app, api } from 'server';
import { Log, Calculator, Storage } from 'utils';
import { updateGiftsRemote, clearGifts } from 'store/gifts';
import { updateRechargeListRemote, clearRechargeList } from 'store/rechargeList';
import { updateRightRemote, clearRights } from 'store/right';
import { updateCheckinRemote, clearCheckin } from 'store/checkin';
import { updateInitCheck, updateUpdateVisible } from 'store/app';
import config from 'config';

function useApp() {
    // 认证模块 
    const dispatch = useDispatch()
    const [authing, setAuthing] = useState(true);
    const [isLogin, setIsLogin] = useState(false);
    const handleAuth = useCallback(async () => {
        let result = Storage.getItem(StorageKey.account)
        if (result) {
            try {
                // 认证通过
                let account = JSON.parse(result) as Account
                login(account)(dispatch)
                // 打开数据库
                await webDB.open(`${account.id}`, 1)
                setTimeout(() => {
                    setAuthing(false)
                }, 0)
            } catch {
                setAuthing(false)
            }
        }
        setTimeout(() => {
            setAuthing(false)
        }, 0)
    }, [setAuthing, dispatch]);
    const account = useSelector(state => (state as any).account).value
    // 登录模块
    useEffect(() => {
        if (account && !isLogin) {
            Log.print("登录账户")
            setIsLogin(true)
            // 设置持久化数据
            Storage.setItem(StorageKey.account, JSON.stringify(account));
            // 设置apitoken
            network.apiToken = account.token
            // 设置Calculator
            Calculator.userId = account.id
            // 连接ws
            ws.connect()
            // 设置rtc
            rtc.uid = account.id
            // 更新用户
            updateAccountRemote()(store.dispatch)
            // 更新礼物
            updateGiftsRemote()(store.dispatch)
            // 更新充值项
            updateRechargeListRemote()(store.dispatch)
            // 设置rtm
            rtm.uid = account.id
            rtm.isEnabled = true
            rtm.login()
            // 更新权益
            updateRightRemote()(store.dispatch)
            // 更新签到
            updateCheckinRemote()(store.dispatch)
            // 绑定推送
            if (config.oneSignal && config.oneSignal.userId && config.oneSignal.pushToken) {
                api.pusherBind({
                    onesignal_id: config.oneSignal.userId,
                    push_token: config.oneSignal.pushToken
                })
            }
            // 打开数据库
            webDB.open(`${account.id}`, 1).then(async () => {
                // 查询user数据库
                webDB.queryUsersAll().then((users) => {
                    resetUsers(users)(store.dispatch)
                    // 更新消息
                    messages.updateFromRemote()
                }).catch(() => {
                    // 更新消息
                    messages.updateFromRemote()
                })
                // 更新角标
                messages.updateBadge()
                // 检查客服
                messages.checkSupport()
            }).catch(() => {
                // 更新消息
                messages.updateFromRemote()
                // 更新角标
                messages.updateBadge()
                // 检查客服
                messages.checkSupport()
            })
        } else if (!account && isLogin) {
            Log.print("注销账户")
            setIsLogin(false)
            let result = Storage.getItem(StorageKey.account)
            if (result) {
                try {
                    let account = JSON.parse(result) as Account
                    // 清理数据库
                    webDB.deleteDB(`${account.id}`)
                } catch { }
            }
            // 清理持久化数据
            Storage.removeItem(StorageKey.account);
            Storage.removeItem(StorageKey.message_startat);
            // 清理apitoken
            network.apiToken = ''
            // 设置Calculator
            Calculator.userId = 0
            // 断开ws
            ws.disconnect()
            // 设置rtc
            rtc.uid = undefined
            // 设置rtm
            rtm.uid = undefined
            rtm.isEnabled = false
            rtm.logout()
            // 清理充值项
            clearRechargeList()(store.dispatch)
            // 清理礼物
            clearGifts()(store.dispatch)
            // 清理权益
            clearRights()(store.dispatch)
            // 清理签到
            clearCheckin()(store.dispatch)
        } else if (account && isLogin) {
            Log.print("更新账户")
            Storage.setItem(StorageKey.account, JSON.stringify(account));
        }
    }, [account, isLogin, setIsLogin])

    useEffect(() => {
        const initCheck = () => {
            api.init_check().then(() => {
                updateInitCheck(true)(store.dispatch)
            }).catch(() => {
                setTimeout(() => {
                    initCheck()
                }, 5000)
            })
        }
        initCheck()
    }, [])

    useEffect(() => {
        app.keep()
    }, [])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        let value = urlParams.get('u');
        if (value && typeof value === 'string') {
            config.needUpdate = true
            config.updateUrlString = value
            updateUpdateVisible(true)(store.dispatch)
        }
    }, [])

    return { authing, setAuthing, handleAuth }
}

export default useApp