/// 路径
export enum PagePath {
    root = "/",
    accounts_welcome = "/accounts/welcome",
    accounts_login = "/accounts/login",
    accounts_signup = "/accounts/signup",
    accounts_profile = "/accounts/profile",
    accounts_edit = "/accounts/edit",
    accounts_edit_nickname = "/accounts/edit/nickname",
    accounts_edit_age = "/accounts/edit/age",
    accounts_edit_about = "/accounts/edit/about",
    accounts_edit_height = "/accounts/edit/height",
    accounts_edit_weight = "/accounts/edit/weight",
    accounts_edit_hair = "/accounts/edit/hair",
    accounts_edit_body = "/accounts/edit/body",
    accounts_edit_belong = "/accounts/edit/belong",
    accounts_edit_constellation = "/accounts/edit/constellation",
    accounts_blacklist = "/accounts/blacklist",
    accounts_following = "/accounts/following",
    accounts_follower = "/accounts/follower",
    accounts_view_history = "/accounts/history/view",
    anchor_explore = "/anchor/explore",
    anchor_hot_following = "/anchor/hot/following",
    anchor_hot_new = "/anchor/hot/new",
    anchor_hot_top = "/anchor/hot/top",
    anchor_ranking = "/anchor/ranking",
    anchor_ranking_anchor = "/anchor/ranking/anchor",
    anchor_ranking_gold = "/anchor/ranking/gold",
    anchor_ranking_gift = "/anchor/ranking/gift",
    anchor_ranking_lover = "/anchor/ranking/lover",
    anchor_detail = "/anchor/detail",
    anchor_lover = "/anchor/lover",
    message_inbox = "/message/inbox",
    message_call_history = "/message/history/call",
    message_direct = "/message/direct",
    legal_privacy_policy = "/legal/privacy-policy",
    legal_terms_of_service = "/legal/terms-of-service",
    system_settings = "/system/settings",
    system_feedback = "/system/feedback",
    system_report = "/system/report",
    finance_expenses = "/finance/expenses",
    finance_expenses_token = "/finance/expenses-token",
    finance_recharge = "/finance/recharge",
    live_call = "/live/call",
    live_conversation = "/live/conversation",
    live_rating = "/live/rating"
}

/// 存储key
export enum StorageKey {
    account = "account",
    message_startat = "message_startat",
    call_tips_time = "call_tips_time",
    call_tips_frequency = "call_tips_frequency"
}

/// 性别
export enum Gender {
    male = 1,
    female = 2
}

/// 角色类型
export enum Role {
    /// 游客
    guest = 0,
    /// 用户
    user = 1,
    /// 主播
    anchor = 2,
    /// 审核人员
    reviewer = 7,
    /// 客服
    support = 9,
}

/// 发色
export enum HairColor {
    black = "Black",
    blond = "Blond",
    brown = "Brown",
    red = "Red"
}

/// 体型
export enum BodyType {
    petite = "Petite",
    athletic = "Athletic",
    curvy = "CURVY",
    bbw = "BBW"
}

/// 人种
export enum Belong {
    asian = "Asian",
    caucasian = "Caucasian",
    ebony = "Ebony",
    latina = "Latina"
}

/// 星座
export enum Constellation {
    aries = "Aries",
    taurus = "Taurus",
    gemini = "Gemini",
    cancer = "Cancer",
    leo = "Leo",
    virgo = "Virgo",
    libra = "Libra",
    scorpio = "Scorpio",
    sagittarius = "Sagittarius",
    capricorn = "Capricorn",
    aquarius = "Aquarius",
    pisces = "Pisces"
}

export enum CallEndReason {
    /// 无
    none = 0,
    /// 主叫方取消呼叫 11
    caller_canceled_call = 11,
    /// 被叫方未接 12
    called_party_missed = 12,
    /// 被叫方拒接 13
    called_party_refused = 13,
    /// 观众挂断 21
    audience_hangup = 21,
    /// 主播挂断 22
    anchor_hangup = 22,
    /// 检测到通话已结束 31
    call_ended_detected = 31,
    /// 观众余额不足 32
    insufficient_balance = 32,
    /// Hunting时间1分钟到(成功Hunting) 33
    hunting_success = 33
}

export enum WebSocketCode {
    /// 已经连接
    connect = 0,
    /// 收到来电
    call = 101,
    /// 收到应答
    answer = 102,
    /// 主动挂断
    hangup = 103,
    /// 用户送礼
    gift = 104,
    /// 命令事件
    cmd = 105,
    /// 更新通话信息
    callUpdate = 106,
    /// 新消息
    message = 111,
    /// 余额变动
    balance = 200,
    /// 信息或状态更新
    userUpdate = 301,
    /// 匹配到用户
    matched = 311,
    /// 匹配到用户同意
    match_confirmed = 312,
    /// 匹配到用户拒绝
    match_refused = 313,
    /// 权益变动
    right = 600
}

/// 连接状态
export enum ConnectStatus {
    /// 正在连接中
    connecting,
    /// 已连接
    connected,
    /// 已断开
    disconnect
}

/// 全局事件
export enum GlobalEvent {
    websocket = "websocket",
    remoteMutedChanged = "remoteMutedChanged",
    messageDidUpdate = "messageDidUpdate",
    messageDidDelete = "messageDidDelete"
}

/// 通话状态
export enum CallState {
    /// 应答中
    answering,
    /// 通话中
    calling,
    /// 闲置
    idle
}

/// 消息类型
export enum MessageType {
    /// 文本
    text = 101,
    /// 图片
    image = 201,
    /// 语音
    voice = 202,
    /// 视频
    video = 203,
    /// 礼物
    gift = 301,
    /// 未接来电
    call = 302
}

/// 消息状态
export enum MessageState {
    /// 发送中
    sending,
    /// 发送成功
    success,
    /// 失败
    error,
    /// 翻译中
    translating
}

/// 原生消息类型
export enum NativeMessageType {
    log = "log",
    recharge = "recharge",
    firstRecharge = "firstRecharge",
    token = "token",
    permission = "permission"
}