import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Header, select } from 'components';
import { useEdit } from 'hooks';
import '../EditPage.scss';

const EditBelongPage = React.memo(() => {
    const { t } = useTranslation()
    const { handleSave } = useEdit()
    const [belong, setBelong] = useState("");
    const account = useSelector(state => (state as any).account).value
    useEffect(() => {
        setBelong(account.profile.attrs.belong)
    }, [account])
    const belongOptions = select.belongOptions
    return (
        <div className='edit-other-page'>
            <Header title={t('personal.belong')}><button className='save-button touch-opacity' onClick={() => handleSave({ attrs: { belong: belong } })}>{t('common.save')}</button></Header>
            <Select
                styles={select.styleConfig}
                className='select-wrapper'
                value={belongOptions.find((item) => { return typeof (belong) === 'string' ? item.value.toLowerCase() === belong.toLowerCase() : undefined }) ?? belongOptions[0]}
                options={belongOptions}
                onChange={(value) => {
                    let obj = value as any
                    setBelong(obj.value as string)
                }}
            />
        </div>
    );
})

export default EditBelongPage;