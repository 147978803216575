import { createSlice } from '@reduxjs/toolkit';
import { MessageUser } from 'models';

const initialState: {
    value: MessageUser[]
} = {
    value: [],
}

// Slice
const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        updateUsers: (state, action) => {
            let newState: MessageUser[] = [...state.value];
            action.payload.forEach((item: MessageUser) => {
                let findIndex = newState.findIndex((arg0) => arg0.id === item.id)
                if (findIndex >= 0) {
                    newState[findIndex] = item
                } else if (findIndex < 0) {
                    newState.push(item)
                }
            })
            state.value = newState
        },
        resetUsers: (state, action) => {
            state.value = [...action.payload];
        },
        clearUsers: (state) => {
            state.value = [];
        },
    },
});

export default slice.reducer

export const updateUsers = (payload: MessageUser[]) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateUsers(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const resetUsers = (payload: MessageUser[]) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.resetUsers(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const clearUsers = () => async (dispatch: (arg0: { payload: any; type: string; }) => any) => {
    try {
        return dispatch(slice.actions.clearUsers())
    } catch (e) {
        return console.error((e as any).message);
    }
}