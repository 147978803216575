import { createSlice } from '@reduxjs/toolkit';
import { api } from 'server';
import { CheckIns } from 'models';

const initialState: {
    value: CheckIns | undefined
} = {
    value: undefined,
}

const slice = createSlice({
    name: 'checkin',
    initialState,
    reducers: {
        updateCheckin: (state, action) => {
            state.value = action.payload;
        },
        clearCheckin: (state) => {
            state.value = undefined
        },
    },
});

export default slice.reducer

export const updateCheckinRemote = () => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        const checkin = await api.getCheckin()
        dispatch(slice.actions.updateCheckin(checkin));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const clearCheckin = () => async (dispatch: (arg0: { payload: any; type: string; }) => any) => {
    try {
        return dispatch(slice.actions.clearCheckin())
    } catch (e) {
        return console.error((e as any).message);
    }
}