
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NativeMessageType, WSCall, NetworkError, CallState, StorageKey } from 'models';
import { api, call } from 'server';
import { Log, LiveWebAdapter, Calculator, Storage } from 'utils';
import i18n from 'i18next';
import { Loading, Toast } from 'components';
import { updateRechargeVisible } from 'store/app';
import store from 'store';
import { analysisLogEvent, AnalysisEventName } from 'analysis';

function useCall() {
    const navigate = useNavigate()
    const [currentDate, setCurrentDate] = useState<Date>(new Date())
    const [tipsShow, setTipsShow] = useState<boolean>(false)
    const callValue = useSelector(state => (state as any).call).value as WSCall
    const expireDate = useSelector(state => (state as any).call).expireDate as Date

    useEffect(() => {
        // 执行每秒定时器
        let timer = setInterval(() => {
            setCurrentDate(new Date())
            if (expireDate && currentDate.getTime() > expireDate.getTime()) {
                call.close()
            }
        }, 1000)
        return () => {
            clearInterval(timer)
        }
    }, [expireDate, currentDate, setCurrentDate, navigate])

    const handleAnswer = useCallback(async () => {
        LiveWebAdapter.postMessage({
            type: NativeMessageType.permission,
            content: undefined
        })
        analysisLogEvent(AnalysisEventName.answer_accept)
        let call_id = callValue.call_id
        if (call_id) {
            Log.print("真电话接听")
            let loading = Loading.show()
            try {
                // 主动接听成功
                let answer = await api.callAnswer(call_id)
                Loading.dismiss(loading)
                call.state = CallState.idle
                call.handleReceivedAnswerActively(callValue, answer)
            } catch (err) {
                let error = err as NetworkError
                Loading.dismiss(loading)
                Toast.show({ content: error.message })
            }
        } else {
            Log.print("假电话接听")
            let loading = Loading.show()
            setTimeout(() => {
                Loading.dismiss(loading)
                call.close()
                Toast.show({ content: i18n.t('call.insufficient.toast') })
                updateRechargeVisible(true)(store.dispatch)
            }, 500)
        }
    }, [callValue])

    const handleHangup = useCallback(async () => {
        analysisLogEvent(AnalysisEventName.answer_cancel)
        let call_id = callValue.call_id
        if (call_id) {
            Log.print("真电话挂断")
            let loading = Loading.show()
            try {
                // 主动挂断成功
                await api.callHangup(call_id)
                Loading.dismiss(loading)
                call.close()
            } catch (err) {
                Loading.dismiss(loading)
                call.close()
            }
        } else {
            Log.print("假电话挂断")
            let loading = Loading.show()
            setTimeout(() => {
                Loading.dismiss(loading)
                call.close()
            }, 500)
        }
    }, [callValue])

    useEffect(() => {
        if (!callValue) {
            navigate(-1)
        }
    }, [callValue, navigate])

    useEffect(() => {
        let call_tips_time = Number(Storage.getItem(StorageKey.call_tips_time))
        let call_tips_frequency = Number(Storage.getItem(StorageKey.call_tips_frequency))
        if (!Number.isNaN(call_tips_time) && call_tips_time) {
            // 拥有展示时间
            if (new Date().getTime() - call_tips_time >= 1000 * 86400) {
                // 时间超过了一天
                setTipsShow(true)
                Storage.setItem(StorageKey.call_tips_frequency, `1`)
            } else {
                // 时间未超过了一天
                if (!Number.isNaN(call_tips_frequency) && call_tips_frequency) {
                    // 拥有展示次数
                    if (call_tips_frequency < 2) {
                        // 小于2次展示
                        setTipsShow(true)
                        Storage.setItem(StorageKey.call_tips_frequency, `${call_tips_frequency + 1}`)
                    } else {
                        // 大于2次展示
                        setTipsShow(false)
                        Storage.setItem(StorageKey.call_tips_frequency, `${call_tips_frequency + 1}`)
                    }
                } else {
                    // 未拥有展示次数
                    setTipsShow(true)
                    Storage.setItem(StorageKey.call_tips_frequency, `1`)
                }
            }
        } else {
            // 未拥有展示时间
            setTipsShow(true)
            Storage.setItem(StorageKey.call_tips_frequency, `1`)
        }
        Storage.setItem(StorageKey.call_tips_time, `${new Date().getTime()}`)
    }, [setTipsShow])

    return { currentDate, expireDate, navigate, handleAnswer, handleHangup, callValue, tipsShow }
}

export default useCall
