import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './Action.scss';

const ActionSheet = React.memo((props: {
    title?: string;
    message?: string;
    visible?: boolean;
    actions?: { title: string, handler: () => void, style?: CSSProperties }[],
    onClose?: () => void;
}) => {
    const { actions, visible, onClose } = props
    const { t } = useTranslation()

    return (
        <div className={classNames('action-sheet-components', !visible && 'hide')} >
            <div className='mask' onClick={() => onClose && onClose()}></div>
            <div className='content-view'>
                <div className='action-wrapper'>
                    <div className='main'>
                        {
                            actions?.map((item) => {
                                return (
                                    <div key={item.title} style={item.style} className='action-button touch-soft-highlight' onClick={() => {
                                        onClose && onClose()
                                        item.handler()
                                    }}>{item.title}</div>
                                )
                            })
                        }
                    </div>
                    <div className='action-button action-button-cancel touch-soft-highlight' onClick={() => onClose && onClose()}>{t('common.cancel')}</div>
                </div>
            </div>
        </div>
    )
})

export default ActionSheet