import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
    name: 'call',
    initialState: {
        value: null,
        expireDate: undefined,
    },
    reducers: {
        updateCall: (state, action) => {
            state.value = { ...action.payload.call };
            state.expireDate = action.payload.expireDate
        },
        clearCall: (state) => {
            state.value = null;
            state.expireDate = undefined;
        },
    },
});

export default slice.reducer

export const updateCall = (payload: any) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateCall(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const clearCall = () => async (dispatch: (arg0: { payload: any; type: string; }) => any) => {
    try {
        return dispatch(slice.actions.clearCall())
    } catch (e) {
        return console.error((e as any).message);
    }
}