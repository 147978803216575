import React from 'react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'components';
import { useFeedback } from 'hooks';
import './FeedbackPage.scss';
// @ts-ignore
import autosize from "autosize";

const FeedbackPage = React.memo(() => {
    const textarea = useRef(null)
    const { t } = useTranslation()
    const { text, setText, handleFeedback } = useFeedback()
    useEffect(() => {
        autosize(textarea.current)
    }, [textarea])
    return (
        <div className='feedback-page'>
            <Header title={t('feedback.title')}></Header>
            <p className='sub-title'>{t('feedback.sub-title')}</p>
            <p className='large-title'>{t('feedback.large-title')}</p>
            <textarea
                ref={textarea}
                className='textarea'
                placeholder={t('feedback.placeholder')}
                value={text}
                onChange={(e) => e.target.value.length <= 1000 && setText(e.target.value)}
            ></textarea>
            <p className='limit'>{1000 - text.length}{t('characters min.')}</p>
            <button className={'next-button touch-highlight'} onClick={() => handleFeedback()}>{t('feedback.next-button')}</button>
        </div>
    );
})

export default FeedbackPage;