
import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { network, api } from 'server';
import { Loading } from 'components';
import { Toast } from 'components';
import { PagePath, NetworkError } from 'models';
import config from 'config';
import { login } from 'store/account';
import { analysisLogEvent, AnalysisEventName } from 'analysis';

function useWelcome() {
    const initCheck = useSelector(state => (state as any).app).initCheck
    const [guest, setGuest] = useState<boolean | undefined>(undefined)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleSiginWithGuest = useCallback(async () => {
        let loading = Loading.show()
        analysisLogEvent(AnalysisEventName.account_start_guest_click)
        try {
            // 注册用户   
            let apiToken = await api.guestSignup()
            network.apiToken = apiToken
            // 开始登录用户数据
            const account = await api.getAccount()
            await login(account)(dispatch)
            navigate(PagePath.root, { replace: true })
            Loading.dismiss(loading)
            analysisLogEvent(AnalysisEventName.account_start_guest_success)
        } catch (err) {
            network.apiToken = ""
            let error = err as NetworkError
            Loading.dismiss(loading)
            Toast.show({ content: error.message })
            analysisLogEvent(AnalysisEventName.account_start_guest_failure)
        }
    }, [dispatch, navigate])

    useEffect(() => {
        if (initCheck === true) {
            if (config.independent === true) {
                setGuest(false)
            } else {
                api.loginPre().then((guest) => {
                    setGuest(guest)
                }).catch(() => {
                    setGuest(false)
                })
            }
        }
    }, [initCheck, setGuest])

    return { guest, initCheck, handleSiginWithGuest }
}

export default useWelcome