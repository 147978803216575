const Storage: {
    getItem(key: string): string | null;
    removeItem(key: string): void;
    setItem(key: string, value: string): void;
} = {
    getItem(key: string) {
        return localStorage.getItem(key)
    },
    removeItem(key: string) {
        localStorage.removeItem(key)
    },
    setItem(key: string, value: string) {
        localStorage.setItem(key, value)
    }
}

export default Storage;