import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, List } from 'components';
import { api } from 'server';
import { PagePath, User } from 'models';
import '../FollowPage.scss';

const FollowerPage = React.memo(() => {

    const onFetch = (page: number, pageSize: number, startFetch: (data: User[]) => void, abortFetch: (error?: Error) => void) => {
        api.userFollower({
            per_page: pageSize,
            page: page
        }).then((items) => {
            startFetch(items)
        }).catch(() => {
            abortFetch()
        })
    }

    return (
        <div className='segmented-page'>
            <List
                className='list'
                onFetch={onFetch}
                renderItem={(item, index) => <FollowerListCellItem key={index} item={item} ></FollowerListCellItem>}
            ></List>
        </div>
    );
})

const FollowerListCellItem = React.memo((props: { item: any }) => {
    const { item } = props
    const navigate = useNavigate()
    return (
        <div className='follow-list-cell-item'>
            <div className='touch touch-soft-highlight' onClick={() => navigate(PagePath.anchor_detail + `/${item.id}`)}></div>
            <Avatar className='avatar' src={item.profile?.head}></Avatar>
            <div className='nickname-wrapper'>
                <p className='nickname'>{item.profile?.nickname}</p>
            </div>
        </div>
    )
})

export default FollowerPage;