
import { Account, NativeMessageType, GlobalEvent, WebSocketEvent, WebSocketCode, CallState, WSCall, Answer, WSAnswer, PagePath, Channel, NetworkError, User, Role } from 'models';
import { history } from '../pages/App';
import { NotificationCenter, Log } from 'utils';
import store from 'store';
import { updateCall, clearCall } from 'store/call';
import { updateConversation } from 'store/conversation';
import { Loading, Toast } from 'components';
import { api } from 'server';
import { updateRechargeVisible, updateRechargeCall, updateHotGirlsRemote, updateDeveceVisible } from 'store/app';
import { LiveWebAdapter } from 'utils';

const call = {
    state: CallState.idle,
    handleCall: async (call_id: number, completion?: ((error?: any) => void)) => {
        // 权限获取成功
        let loading = Loading.show()
        LiveWebAdapter.postMessage({
            type: NativeMessageType.permission,
            content: undefined
        })
        updateDeveceVisible(true)(store.dispatch)
        call.deviceRequest()
            .then(async stream => {
                console.log("stream", stream)
                try {
                    await api.call({ callee_id: call_id, type: 1 })
                    Loading.dismiss(loading)
                    typeof completion === 'function' && completion()
                } catch (err) {
                    let error = err as NetworkError
                    Loading.dismiss(loading)
                    Toast.show({ content: error.message })
                    if (error.code === 2001) {
                        updateRechargeVisible(true)(store.dispatch)
                    }
                    typeof completion === 'function' && completion(err)
                }
            }).catch(() => {
                setTimeout(() => {
                    Loading.dismiss(loading)
                    // 权限获取失败
                    updateDeveceVisible(true)(store.dispatch)
                })
            })
    },
    handleRechargeCall: async (call_id: number, user?: User, completion?: ((error?: any) => void)) => {
        let loading = Loading.show()
        LiveWebAdapter.postMessage({
            type: NativeMessageType.permission,
            content: undefined
        })
        call.deviceRequest().then(async () => {
            try {
                await api.call({ callee_id: call_id, type: 1 })
                Loading.dismiss(loading)
                typeof completion === 'function' && completion()
            } catch (err) {
                let error = err as NetworkError
                Loading.dismiss(loading)
                if (error.code === 2001) {
                    updateRechargeCall({ visible: true, userInfo: user, expireDate: new Date(new Date().getTime() + 1000 * 45) })(store.dispatch)
                    setTimeout(() => {
                        Toast.show({ content: error.message })
                    }, 3500)
                } else {
                    Toast.show({ content: error.message })
                }
                typeof completion === 'function' && completion(err)
            }
        }).catch((err) => {
            setTimeout(() => {
                Loading.dismiss(loading)
                // 权限获取失败
                updateDeveceVisible(true)(store.dispatch)
            })
        })
    },
    /// 收到呼叫
    handleReceivedCall: (value: WSCall) => {
        if (call.state !== CallState.idle) { return }
        call.state = CallState.answering
        updateCall({
            call: value,
            expireDate: new Date(new Date().getTime() + 1000 * 45)
        })(store.dispatch)
        history.push(PagePath.live_call)
    },
    /// 收到来电方式（主动）
    handleReceivedAnswerActively(value: WSCall, answer: Answer) {
        if (call.state !== CallState.idle) { return }
        Log.print("收到来电方式（主动）")
        let channel = {
            call_type: value.type,
            channel_id: answer.agora.channel_id,
            channel_token: answer.agora.channel_token,
            call_id: value.call_id,
            peer: value.other_people
        } as Channel
        updateConversation(channel)(store.dispatch)
        if (history.location.pathname === PagePath.live_call) {
            history.replace(PagePath.live_conversation)
        } else {
            history.push(PagePath.live_conversation)
        }
        // 修复连续push问题
        setTimeout(() => { call.close() }, 1000)
    },
    /// 收到来电方式（被动）
    handleReceivedAnswerPassively(answer: WSAnswer) {
        if (call.state !== CallState.idle) { return }
        Log.print("收到来电方式（被动）")
        let channel = {
            call_type: answer.type,
            channel_id: answer.agora.channel_id,
            channel_token: answer.agora.channel_token,
            call_id: answer.call_id,
            peer: answer.callee
        } as Channel
        updateConversation(channel)(store.dispatch)
        if (history.location.pathname === PagePath.live_call) {
            history.replace(PagePath.live_conversation)
        } else {
            history.push(PagePath.live_conversation)
        }
        // 修复连续push问题
        setTimeout(() => { call.close() }, 1000)
    },
    close: () => {
        clearCall()(store.dispatch)
        call.state = CallState.idle
    },
    keep: () => {
        // 监听ws事件
        NotificationCenter.addObserver(GlobalEvent.websocket, (value) => {
            let event = (value as any).detail as WebSocketEvent
            if (event.event_code === WebSocketCode.call) {
                // 收到呼叫事件
                call.handleReceivedCall(event.data as WSCall)
            }
            if (event.event_code === WebSocketCode.hangup) {
                let end_reason = event.data && event.data.end_reason
                let callValue = store.getState().call.value as unknown as WSCall
                if (callValue && callValue.direction === 0 && (end_reason == 22 || end_reason == 13 || end_reason == 12) && callValue.other_people.role === Role.anchor) {
                    updateHotGirlsRemote()(store.dispatch)
                }
                // 收到对方挂断
                call.close()
            }
            if (event.event_code === WebSocketCode.answer) {
                // 收到对方应答
                call.state = CallState.idle
                call.handleReceivedAnswerPassively(event.data as WSAnswer)
            }
        })
        // 超时定时器
        setInterval(() => {
            const expireDate: Date | undefined = store.getState().call.expireDate as any as Date
            const currentDate = new Date()
            // 如果超时，都还在呼叫中，则改为闲置
            if (expireDate && currentDate.getTime() > expireDate?.getTime()) {
                if (call.state === CallState.answering) {
                    call.state = CallState.idle
                    call.close()
                }
            }
        }, 1000)
    },
    deviceRequest: () => {
        return new Promise<void>((resolve, reject) => {
            let account = store.getState().account.value as any
            if (!Number.isNaN(account.balance) && account.balance > 350) {
                const constrains = {
                    video: true,
                    audio: true
                }
                navigator.mediaDevices.getUserMedia(constrains)
                    .then(async stream => {
                        Log.print(stream, "----stream")
                        setTimeout(() => {
                            var mediaStreamTracks = stream.getTracks();
                            mediaStreamTracks.forEach((track) => {
                                track.stop();
                            })
                        }, 500)
                        resolve()
                    }).catch((err) => {
                        // 权限获取失败
                        reject(err)
                    })
            } else {
                resolve()
            }
        })
    }
}

call.keep()

export default call