import React from 'react';
import { useTranslation } from 'react-i18next';
import { NativeMessageType } from 'models';
import { useFirstRecharge } from 'hooks';
import classNames from 'classnames';
import './FirstRechargeModal.scss';
import { LiveWebAdapter } from 'utils';
import { analysisLogEvent, AnalysisEventName } from 'analysis';

const FirstRechargeModal = React.memo((props: {
    visible?: boolean;
    onClose?: () => void;
}) => {
    const { t } = useTranslation()
    const { visible, onClose } = props
    const { firstRechargeRight } = useFirstRecharge()
    const right = firstRechargeRight && firstRechargeRight.right
    const [show, setShow] = React.useState<undefined | boolean>(false)

    React.useLayoutEffect(() => {
        setTimeout(() => {
            setShow(visible)
        })
        if (visible) {
            analysisLogEvent(AnalysisEventName.first_recharge_enter)
        }
    }, [visible, setShow])

    const close = React.useCallback(() => {
        setShow(false)
        setTimeout(() => {
            typeof onClose === 'function' && onClose()
        }, 250)
    }, [onClose])

    if (!visible || !right) {
        return <></>
    }
    return (
        <div className={classNames('first-recharge-modal', !show && 'hide')}>
            <div className='mask' onClick={() => close()}></div>
            <div className='content-view'>
                <div className='main-container'>
                    <div className='content'>
                        <div className='title'>
                            <div className='label'>{t('firstRecharge.title')}</div>
                        </div>
                        <div className='close-button touch-opacity' onClick={() => close()}></div>
                        <div className='medal'></div>
                        <div className='gift-view'></div>
                        <div className='token-label'>+{right.desc.token_amount}<span className='diamonds'>{t('firstRecharge.diamonds')}</span></div>
                        <div className='offer-container'>
                            <div className='price-label'>$0.99</div>
                            <div className='offer-view'>
                                <div className='offer-label'>First time offer</div>
                            </div>
                        </div>
                        <div className='buy-button touch-opacity' onClick={() => {
                            analysisLogEvent(AnalysisEventName.first_recharge_button_click)
                            LiveWebAdapter.postMessage({
                                type: NativeMessageType.firstRecharge,
                                content: right.desc
                            })
                        }
                        }>{t('firstRecharge.continue')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default FirstRechargeModal