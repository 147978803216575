import { useMemo } from 'react';
import { useMatch } from "react-router-dom";
import { useSelector } from 'react-redux';
import { PagePath } from "models";

function usePage() {
    const account = useSelector(state => (state as any).account).value
    const matchRoot = useMatch("/") != null
    const matchExplore = useMatch(PagePath.anchor_explore) != null;
    const matchInbox = useMatch(PagePath.message_inbox) != null;
    const matchCallHistory = useMatch(PagePath.message_call_history) != null;
    const matchRecharge = useMatch(PagePath.finance_recharge) != null;
    const matchProfile = useMatch(PagePath.accounts_profile) != null;
    const matchHotFollowing = useMatch(PagePath.anchor_hot_following)
    const matchHotNew = useMatch(PagePath.anchor_hot_new)
    const matchHotTop = useMatch(PagePath.anchor_hot_top)
    const matchHot = matchHotFollowing || matchHotNew || matchHotTop

    const matchTab = useMemo(() => {
        return account && (matchRoot || matchExplore || matchInbox || matchCallHistory || matchRecharge || matchProfile || matchHot)
    }, [account, matchExplore, matchInbox, matchProfile, matchRecharge, matchRoot, matchHot])

    return { matchTab }
}

export default usePage