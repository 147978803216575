import { createSlice } from '@reduxjs/toolkit';
import { api } from 'server';
import { Right } from 'models'
import { Calculator } from 'utils'

const initialState: {
    firstRechargeRight?: Right,
    bindEmailRight?: any,
} = {
    firstRechargeRight: undefined,
    bindEmailRight: undefined
}

// Slice
const slice = createSlice({
    name: 'right',
    initialState,
    reducers: {
        updateFirstRechargeRight: (state, action) => {
            state.firstRechargeRight = action.payload;
        },
        updateBindEmailRight: (state, action) => {
            state.bindEmailRight = action.payload;
        },
        clearRights: (state) => {
            state.firstRechargeRight = undefined
            state.bindEmailRight = undefined
        },
    },
});

const preferred = (rights: Right[]) => {
    let findIndex1 = rights.findIndex((item) => item.is_used === false && Calculator.checkAvailabled(item))
    if (findIndex1 >= 0) {
        return rights[findIndex1]
    }
    let findIndex2 = rights.findIndex((item) => item.is_used !== false && Calculator.checkAvailabled(item))
    if (findIndex2 >= 0) {
        return rights[findIndex2]
    }
    if (rights.length > 0) {
        return rights[0]
    }
    return undefined
}

export default slice.reducer

export const updateRightRemote = () => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        let rights = await api.getRight()
        let firstRecharge = preferred(rights.filter((item) => item.right.code === 'first_token'))
        let emailBindToken = preferred(rights.filter((item) => item.right.code === 'email_bind_token'))
        dispatch(slice.actions.updateFirstRechargeRight(firstRecharge));
        dispatch(slice.actions.updateBindEmailRight(emailBindToken));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateFirstRechargeRight = (payload: Right | undefined) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateFirstRechargeRight(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateBindEmailRight = (payload: any | undefined) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateBindEmailRight(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const clearRights = () => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.clearRights());
    } catch (e) {
        return console.error((e as any).message);
    }
}

