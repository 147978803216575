import LiveWebAdapter from "./LiveWebAdapter"
import { NativeMessageType } from 'models';
import config from 'config';

const Log = {
    print(message?: any, ...optionalParams: any[]) {
        if (config.debug) {
            console.log(message, ...optionalParams)
        }
        LiveWebAdapter.postMessage({ type: NativeMessageType.log, content: message + optionalParams })
    }
}

export default Log