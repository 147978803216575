import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ConversationEnd, NetworkError } from 'models';
import { api } from 'server';
import { Loading, Toast } from 'components';
import { clearRating } from 'store/rating';
import { analysisLogEvent, AnalysisEventName } from 'analysis';

function useRating() {

    const [ratings, setRatings] = useState<number>(4)
    const [content, setContent] = useState<string>('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const conversationEnd = useSelector(state => (state as any).rating).value as ConversationEnd
    const handleSend = useCallback(async () => {
        if (conversationEnd) {
            let loading = Loading.show()
            analysisLogEvent(AnalysisEventName.rating_send_click)
            try {
                await api.callComment({
                    call_id: conversationEnd.callId,
                    anchor: {
                        rating: ratings,
                        content: content.length > 0 ? content : undefined
                    }
                })
                Loading.dismiss(loading)
                clearRating()(dispatch)
            } catch (err) {
                let error = err as NetworkError
                Loading.dismiss(loading)
                Toast.show({ content: error.message })
                clearRating()(dispatch)
            }
        }
    }, [ratings, content, conversationEnd, dispatch])

    useEffect(() => {
        if (!conversationEnd) {
            navigate(-1)
        }
    }, [conversationEnd, navigate])

    return { ratings, setRatings, content, setContent, handleSend, conversationEnd }
}

export default useRating