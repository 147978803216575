
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { PagePath } from 'models';
import { Header, Spinner } from 'components';
import { useLogin } from 'hooks';
import './LoginPage.scss';

const LoginPage = React.memo(() => {
  const { t } = useTranslation()
  const { email, setEmail, password, setPassword, handleLogin, loading, isEnabled } = useLogin()
  return (
    <div className='login-page'>
      <Header title={t('login.title')}></Header>
      <form className='login-form'>
        <div className='input-wrapper email'>
          <input className='input' placeholder={t('login.email-placholder')} value={email} onChange={(value) => setEmail(value.target.value)}></input>
          <div className='icon email'></div>
        </div>
        <div className='input-wrapper password'>
          <input className='input' placeholder={t('login.password-placholder')} type='password' value={password} onChange={(value) => setPassword(value.target.value)} autoComplete="true"></input>
          <div className='icon password'></div>
        </div>
      </form>
      <button className={classNames('login-button', 'touch-highlight', loading && 'disabled', !isEnabled && 'disabled-opacity')} onClick={() => handleLogin()}>
        {loading ? <Spinner color="var(--system-white)" /> : t('login.login-button')}
      </button>
      <div className='toggle-label'>{t('login.toggle-description')} <Link to={PagePath.accounts_signup} className='underline touch-opacity' replace>{t('login.toggle-signup')}</Link></div>
    </div>
  );
})

export default LoginPage;