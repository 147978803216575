import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './DeviceModal.scss';

const CheckinModal = React.memo((props: {
    visible?: boolean;
    onClose?: () => void;
}) => {
    const { t } = useTranslation()
    const { visible, onClose } = props
    const [show, setShow] = React.useState<undefined | boolean>(false)

    React.useLayoutEffect(() => {
        setTimeout(() => {
            setShow(visible)
        })
    }, [visible, setShow])

    const close = React.useCallback(() => {
        setShow(false)
        setTimeout(() => {
            typeof onClose === 'function' && onClose()
        }, 250)
    }, [onClose])

    if (!visible) {
        return null
    }

    return (
        <div className={classNames('device-modal', !show && 'hide')}>
            <div className='mask' onClick={() => close()}></div>
            <div className='content-view'>
                {/* <div className='close-button' onClick={() => close()}></div> */}
                <div className='head'>
                    <div className='image-logo'></div>
                    <div className='desc'>
                        <div className='headline-1'>{t('device.desc1')}</div>
                        {/* <div className='headline-2'>{t('device.desc2')}</div> */}
                    </div>
                </div>
                <div className='title'>{t('device.title')}</div>
                {/* <div className='next-button' onClick={() => {
                    window.location.reload()
                }}>{t('device.next')}</div> */}
            </div>
        </div>
    )
})

export default CheckinModal