const config: {
    version: string,
    server: any,
    udid: string,
    independent: boolean,
    debug: boolean,
    platform: "web" | "ios" | "android",
    device: any,
    oneSignal?: {
        userId: string,
        pushToken: string
    },
    needUpdate: Boolean,
    updateUrlString?: string
} = {
    version: "1.0.0",
    server: {
        origin: "https://qa-a.live4fun.xyz",
        wss: "wss://qa-m.live4fun.xyz",
        app_id: "5ca6b94e946b8c4d819a0c5783036d25",
        agora_id: "7350c173cd584d208a5617a37657e7f0"
    },
    udid: 'cf119fd9b0714011ab198edeece4e142azfb9',
    independent: true,
    platform: "web",
    debug: false,
    device: {
        "udid": 'cf119fd9b0714011ab198edeece4e142azfb9',
        "name": "Web Browser",
        "system": {
            "name": "All",
            "version": "1.0.0"
        },
        "network": {
            "vpn": false,
            "sim": true
        },
        "source": { "media": "organic" }
    },
    oneSignal: undefined,
    needUpdate: false,
    updateUrlString: undefined
}

export default config;