import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { Header, Avatar, List, Loading, Toast } from 'components';
import { api } from 'server';
import { PagePath, Black, NetworkError } from 'models';
import './BlackListPage.scss';

const BlackListPage = React.memo(() => {
  const { t } = useTranslation()
  const ref = useRef(null)

  const onFetch = (page: number, pageSize: number, startFetch: (data: Black[]) => void, abortFetch: (error?: Error) => void) => {
    api.userBlackList({
      per_page: pageSize,
      page: page
    }).then((items) => {
      startFetch(items)
    }).catch(() => {
      abortFetch()
    })
  }

  const handleUnblock = (item: Black) => {
    let loading = Loading.show()
    api.userUnblock(item.id).then(() => {
      let list = (ref as any).current as List<Black>
      let dataSource = list.state.dataSource
      dataSource = dataSource.filter(element => element.id !== item.id)
      list.setState({
        dataSource: dataSource,
        pageState: dataSource.length === 0 ? 'empty' : list.state.pageState
      })
      Loading.dismiss(loading)
    }).catch((err) => {
      let error = err as NetworkError
      Loading.dismiss(loading)
      Toast.show({ content: error.message })
    })
  }

  return (
    <div className='black-list-page'>
      <Header title={t('black.title')}></Header>
      <List
        ref={ref}
        className='list'
        onFetch={onFetch}
        renderItem={(item, index) => <BlackListCellItem key={index} item={item} onClick={(item) => { handleUnblock(item) }}></BlackListCellItem>}
      ></List>
    </div>
  );
})

const BlackListCellItem = React.memo((props: { item: Black, onClick: (item: Black) => void }) => {
  const { t } = useTranslation()
  const { item, onClick } = props
  const navigate = useNavigate()
  return (
    <div className='black-list-cell-item'>
      <div className='touch touch-soft-highlight' onClick={() => navigate(PagePath.anchor_detail + `/${item.id}`)}></div>
      <Avatar className='avatar' src={item.profile?.head}></Avatar>
      <div className='nickname-wrapper'>
        <p className='nickname'>{item.profile?.nickname}</p>
      </div>
      <button className='remove-button touch-highlight' onClick={() => onClick(item)}>{t('black.remove-button')}</button>
    </div>
  )
})

export default BlackListPage;