import i18n from 'i18next';
import pathname from "./pathname";
import config from "../config";
import { NetworkRequest, NetworkError, PagePath } from '../models';
import store from '../store';
import { logout } from '../store/account';
import { Toast } from '../components';
import { history } from '../pages/App';
import { Log } from '../utils';
import ws from './ws'

const serialize = function (obj?: { [name: string]: any }) {
    var str = [];
    for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
            if (Object.prototype.toString.call(obj[p]) === '[object Object]') {
                str.push(
                    encodeURIComponent(p) +
                    '=' +
                    encodeURIComponent(JSON.stringify(obj[p])),
                );
            } else {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }
        }
    }
    if (str.length > 0) { return '?' + str.join('&'); }
    return '';
};

const network = {
    apiToken: "",
    fetch: (params: NetworkRequest) => {
        return new Promise<any>((resolve, reject) => {
            try {
                let ts = Math.round(new Date().getTime() / 1000)
                let parameters = {
                    'ts': `${ts}`,
                    ...params.parameters
                }
                let queryString: string = params.method === 'GET' ? serialize(parameters) : '';
                let url = config.server.origin + params.pathname + queryString
                Log.print(`⚪️ 开始请求: ${config.server.origin + params.pathname}`, JSON.stringify(params.parameters))
                let headers: any = {
                    ...params.headers,
                }
                if (network.apiToken) {
                    headers["Authorization"] = "Bearer " + network.apiToken
                }
                if (params.pathname !== pathname.upload) {
                    headers["Content-Type"] = 'application/json'
                }
                fetchRequest(url, {
                    method: params.method,
                    headers: headers,
                    body: params.pathname === pathname.upload ? params.parameters as FormData : (params.method !== 'GET' ? JSON.stringify(parameters) : undefined)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (typeof responseJson == 'object' && responseJson["error_code"] === 0) {
                            Log.print(`🟢 响应成功: ${config.server.origin + params.pathname}`, responseJson['data'])
                            resolve(responseJson['data'])
                        } else {
                            let error: NetworkError = { message: i18n.t('server.error') }
                            if (typeof responseJson == 'object') {
                                error = {
                                    code: responseJson["error_code"],
                                    message: responseJson["message"]
                                }
                                Log.print(`🟢 响应失败: ${config.server.origin + params.pathname}`, JSON.stringify(error))
                            } else {
                                Log.print(`🔴 响应失败: ${config.server.origin + params.pathname}`)
                            }
                            if (responseJson["error_code"] === 401) {
                                logout()(store.dispatch)
                                history.replace(PagePath.root)
                                Toast.show({ content: i18n.t('server.unauthorized') })
                            }
                            if (responseJson["error_code"] === 1011) {
                                ws.disconnect()
                                setTimeout(function () {
                                    ws.connect()
                                }, 1500);
                            }
                            reject(error)
                        }
                    }).catch(() => {
                        Log.print(`🔴 响应失败: ${config.server.origin + params.pathname}`)
                        reject({ message: i18n.t('server.error') })
                    })
            } catch {
                Log.print(`🔴 响应失败: ${config.server.origin + params.pathname}`)
                reject({ message: i18n.t('server.error') })
            }
        })
    }
}

const fetchRequest = (url: string, params = {}, timeout = 15 * 1000) => {
    let isTimeout = false;
    return new Promise<Response>(function (resolve, reject) {
        const TO = setTimeout(function () {
            isTimeout = true;
            reject(new Error('Fetch timeout'));
        }, timeout);
        fetch(url, params)
            .then(res => {
                clearTimeout(TO)
                if (!isTimeout) {
                    resolve(res)
                }
            }).catch(e => {
                if (isTimeout) {
                    return
                }
                reject(e)
            })
    })
}

export default network;