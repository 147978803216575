import './Toast.scss';

type ToastProps = {
    content?: string,
    duration?: number,
}

class Toast {
    static show(params: ToastProps) {
        // 检测是否包含未消失toast
        let oldToasts = document.getElementsByClassName("toast-component")
        for (let i in oldToasts) {
            oldToasts[i].parentNode?.removeChild(oldToasts[i])
        }
        // 创建toast
        let body = document.body;
        let toast = document.createElement('div')
        toast.className = "toast-component"
        let content = document.createElement('div')
        content.className = "content"
        content.innerHTML = params.content ?? ""
        toast.appendChild(content)
        // 添加到body
        body.appendChild(toast);
        setTimeout(() => {
            if (body.contains(toast)) {
                setTimeout(() => {
                    if (body.contains(toast)) {
                        content.classList.add("hide")
                        content.addEventListener('transitionend', function () {
                            body.removeChild(toast)
                        })
                    }
                }, params.duration ?? 3000)
            }
        })
    }
}

export default Toast