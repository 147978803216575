
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Loading } from 'components';
import { api } from 'server';
import { PagePath } from 'models';
import { logout } from 'store/account';

function useSettings() {
    const [alertVisible, setAlertVisible] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleLogout = useCallback(async () => {
        let loading = Loading.show()
        try {
            await api.logout()
            logout()(dispatch)
            Loading.dismiss(loading)
            navigate(PagePath.accounts_welcome, { replace: true })
        } catch (err) {
            logout()(dispatch)
            Loading.dismiss(loading)
            navigate(PagePath.accounts_welcome, { replace: true })
        }
    }, [dispatch, navigate])
    return { alertVisible, setAlertVisible, handleLogout }
}

export default useSettings