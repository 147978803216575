import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, List } from 'components';
import { api } from 'server';
import { Expense } from 'models';
import './ExpensesPage.scss';
import { ExpensesListCellItem } from '../expenses/ExpensesPage';

const ExpensesTokenPage = React.memo(() => {
    const { t } = useTranslation()

    const onFetch = (page: number, pageSize: number, startFetch: (data: Expense[]) => void, abortFetch: (error?: Error) => void) => {
        api.userExpends({
            per_page: pageSize,
            page: page,
            "filter[quick]": "token"
        }).then((items) => {
            startFetch(items)
        }).catch(() => {
            abortFetch()
        })
    }

    return (
        <div className='expenses-page'>
            <Header title={t('recharge.record')}></Header>
            <List
                className='list'
                onFetch={onFetch}
                renderItem={(item, index) => <ExpensesListCellItem key={index} item={item}></ExpensesListCellItem>}
            ></List>
        </div>
    );
})

export default ExpensesTokenPage;