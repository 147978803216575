import { GlobalEvent } from 'models';

const NotificationCenter = {
    addObserver: (event: GlobalEvent, listener: EventListenerOrEventListenerObject) => {
        window.addEventListener(event, listener)
    },
    removeObserver: (event: GlobalEvent, listener: EventListenerOrEventListenerObject) => {
        window.removeEventListener(event, listener)
    },
    post: (event: GlobalEvent, detail?: any) => {
        window.dispatchEvent(new CustomEvent(event, {
            detail: detail
        }))
    }

}

export default NotificationCenter