export enum BizCode {
    /// 其他(0)
    other = 0,
    /// 充值(1)
    recharge = 1,
    /// 通话(2)
    call = 2,
    /// 消息(3
    message = 3,
    /// 活动(4)
    activity = 4,
    /// 任务(5)
    task = 5,
    /// 未知(9)
    rights = 8,
    /// 提现(9)
    withdraw = 9,
    /// 礼物(203)
    gift = 203,
    /// 冻结(207)
    frozen = 207
}

export type Biz = {
    /// 呼叫方向, 0:呼出, 1:呼入
    direction?: number,
    /// 【通话】通话总金币
    token?: number,
    /// 【通话】通话金币
    call_token?: number,
    /// 【通话】礼物金币
    gift_token?: number,
    /// 【通话】评级: 1,2,3,4,5, 无此字段代表未评
    comment_rating?: number,
    /// 【通话】评价金币, 0或无此字段代表没有奖惩, 大于0代表奖励金币数，小于0代表惩罚金币数
    comment_token?: number,
    /// 对方用户信息,
    other_people?: any,
    /// 【提现】提现状态, 1:已提交, 3:已兑现
    status?: number,
    /// 【权益】权益名称
    name?: String,
}

type Expense = {
    /// 收入(+)或支出(-)代币数
    amount?: number,
    /// 0: 其他 1:充值 2:通话 3:消息 9:提现 4: 活动 5:任务
    biz_code?: BizCode,
    /// 业务名称
    biz_name?: string,
    /// 发生时间
    occurred_on?: number,
    /// 相关业务信息
    biz?: Biz,
}


export type WSBalance = {
    /// 余额
    balance: number
    /// 余额所属时刻
    balanced_at: number
    /// 0: 其他 1:充值 2:通话 3:消息
    biz_code: number
    /// 具体业务内容
    biz: Biz
}

export default Expense