import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Right } from 'models';

function useFirstRecharge() {
    const firstRechargeRight = useSelector(state => (state as any).right).firstRechargeRight as Right
    let [now, setNow] = useState<Date>(new Date())

    useEffect(() => {
        let timer = setInterval(() => {
            setNow(new Date())
        }, 1000)
        return () => {
            timer && clearInterval(timer)
        }
    }, [setNow]);

    const timeObj = useMemo(() => {
        if (!firstRechargeRight) {
            return { hour: 0, minute: 0, second: 0 }
        }
        const time = (firstRechargeRight.expired_at ?? 0) - now.getTime() / 1000
        let hour = Math.floor(time / 3600)
        let minute = Math.floor((time - 3600 * hour) / 60)
        let second = Math.floor(time - hour * 3600 - minute * 60)
        hour = hour >= 0 ? hour : 0
        minute = minute >= 0 ? minute : 0
        second = second >= 0 ? second : 0
        const hourStr = hour <= 9 ? `0${hour}` : `${hour}`
        const minuteStr = minute <= 9 ? `0${minute}` : `${minute}`
        const secondStr = second <= 9 ? `0${second}` : `${second}`
        return { hour: hourStr, minute: minuteStr, second: secondStr }
    }, [firstRechargeRight, now])

    const off = useMemo(() => {
        if (!firstRechargeRight) {
            return undefined
        }
        let right = firstRechargeRight.right
        return ((right.desc.original_price - right.desc.price) / right.desc.original_price) * 100
    }, [firstRechargeRight])

    return { timeObj, off, firstRechargeRight }
}

export default useFirstRecharge