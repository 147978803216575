
import { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { api, network } from 'server';
import { Toast, Loading } from 'components';
import { Gender, NetworkError } from 'models';
import { useDispatch } from 'react-redux';
import { PagePath } from 'models';
import { login, updateAccountRemote } from 'store/account';
import { analysisLogEvent, AnalysisEventName } from 'analysis';
import { updateRightRemote } from 'store/right';
import store from 'store';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import md5 from 'md5';

function useSignup() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const account = useSelector(state => (state as any).account).value
  // 步骤
  const [step, setStep] = useState(0);
  // 邮箱   
  const [email, setEmail] = useState("");
  // 密码
  const [password, setPassword] = useState("");
  // 昵称
  const [nickname, setNickname] = useState("");
  // 性别
  const [gender, setGender] = useState(Gender.male);
  // 岁数
  const [age, setAge] = useState(24);
  // 头像
  const [avatar, setAvatar] = useState("");
  // 验证邮箱状态
  const [emailLoading, setEmailLoading] = useState(false);
  // 邮箱步骤是否可用
  const isEmailStepEnabled = useMemo(() => {
    return email.length > 0 && password.length > 0
  }, [email, password])
  // 点击验证密码邮箱
  const handleVerifyEmail = useCallback(() => {
    setEmailLoading(true)
    // 请求服务器
    api.email({ email })
      .then(() => {
        setEmailLoading(false)
        setStep(1)
      }).catch((err) => {
        setEmailLoading(false)
        Toast.show({ content: err.message })
      })
  }, [email, setEmailLoading, setStep]);
  // 点击添加头像
  const handleAddAvatar = useCallback(() => {
    var file = document.getElementById("avatar-file") as any;
    var imgUrl = window.URL.createObjectURL(file.files[0]);
    setAvatar(imgUrl)
  }, [setAvatar]);

  // 点击注册
  const handleSignup = useCallback(async () => {
    let loading = Loading.show()
    try {
      if (account) {
        let head: string | undefined = undefined
        let parameters: any = {
          email: email.toLocaleLowerCase(),
          password: md5(password),
          nickname: nickname,
          gender: gender,
          age: age
        }
        // 开始上传头像
        let inp = document.getElementById("avatar-file") as HTMLInputElement
        if (inp.files !== null && inp.files.length > 0) {
          let uploadResponse = await api.upload({
            value: inp.files[0],
            fileName: uuidv4()
          }) as { "url": string }
          head = uploadResponse["url"]
        }
        // 更新头像(如果用户有上传)
        if (head) {
          parameters["photos"] = [head]
          parameters["head"] = head
        }
        // 更新用户
        await api.updateUser(parameters)
        await updateAccountRemote()(dispatch)
        await updateRightRemote()(store.dispatch)
        navigate(PagePath.root, { replace: true })
        Loading.dismiss(loading)
        analysisLogEvent(AnalysisEventName.account_supplement_success)
      } else {
        // 注册用户   
        let apiToken = await api.signup({
          email: email,
          password: password,
          nickname: nickname,
          gender: gender,
          age: age
        })
        let head: string | undefined = undefined
        network.apiToken = apiToken
        // 开始上传头像
        let inp = document.getElementById("avatar-file") as HTMLInputElement
        console.log(inp, inp && inp.files, inp && inp.files && inp.files.length, "----gggg")
        if (inp && inp.files !== null && inp.files.length > 0) {
          let uploadResponse = await api.upload({
            value: inp.files[0],
            fileName: uuidv4()
          }) as { "url": string }
          head = uploadResponse["url"]
        }
        // 更新头像(如果用户有上传)
        if (head) {
          await api.updateUser({ head: head, photos: [head] })
          await updateAccountRemote()(dispatch)
        }
        // 开始登录用户数据
        const account = await api.getAccount()
        analysisLogEvent(AnalysisEventName.account_signup_success)
        await login(account)(dispatch)
        navigate(PagePath.root, { replace: true })
        Loading.dismiss(loading)
      }
    } catch (err) {
      if (!account) {
        network.apiToken = ""
        analysisLogEvent(AnalysisEventName.account_signup_failure)
      } else {
        analysisLogEvent(AnalysisEventName.account_supplement_failure)
      }
      let error = err as NetworkError
      Loading.dismiss(loading)
      Toast.show({ content: error.message })
    }
  }, [account, email, password, nickname, gender, age, navigate, dispatch]);

  return { account, step, setStep, email, setEmail, password, setPassword, nickname, setNickname, gender, setGender, age, setAge, avatar, setAvatar, emailLoading, isEmailStepEnabled, handleVerifyEmail, handleAddAvatar, handleSignup }
}

export default useSignup