import { createSlice } from '@reduxjs/toolkit';
import { api } from 'server';

// Slice
const slice = createSlice({
  name: 'account',
  initialState: {
    value: null
  },
  reducers: {
    login: (state, action) => {
      state.value = { ...action.payload };
    },
    logout: (state) => {
      state.value = null;
    },
  },
});
export default slice.reducer

export const login = (payload: any) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
  try {
    dispatch(slice.actions.login(payload));
  } catch (e) {
    return console.error((e as any).message);
  }
}

export const logout = () => async (dispatch: (arg0: { payload: any; type: string; }) => any) => {
  try {
    return dispatch(slice.actions.logout())
  } catch (e) {
    return console.error((e as any).message);
  }
}

export const updateAccountRemote = () => async (dispatch: (arg0: { payload: any; type: string; }) => any) => {
  try {
    const account = await api.getAccount()
    dispatch(slice.actions.login(account));
  } catch (e) {
    return console.error((e as any).message);
  }
}

export const updateAccount = (payload: any) => async (dispatch: (arg0: { payload: any; type: string; }) => any) => {
  try {
    dispatch(slice.actions.login(payload));
  } catch (e) {
    return console.error((e as any).message);
  }
}