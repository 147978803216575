import { createSlice } from '@reduxjs/toolkit';
import { User } from 'models';
import { api } from 'server';

const initialState: {
    badge: number,
    rechargeVisible: boolean,
    rechargeCall: {
        visible: boolean,
        userInfo: any,
        expireDate?: Date
    },
    firstRechargeVisible: boolean,
    checkinVisible: boolean,
    initCheck: boolean,
    hotGirls?: User[],
    deveceVisible: boolean,
    updateVisible: boolean
} = {
    badge: 0,
    rechargeVisible: false,
    rechargeCall: {
        visible: false,
        userInfo: undefined,
        expireDate: undefined
    },
    firstRechargeVisible: false,
    checkinVisible: false,
    initCheck: false,
    hotGirls: undefined,
    deveceVisible: false,
    updateVisible: false
}

// Slice
const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        updateBadge: (state, action) => {
            state.badge = action.payload;
        },
        updateRechargeVisible: (state, action) => {
            state.rechargeVisible = action.payload;
        },
        updateRechargeCall: (state, action) => {
            state.rechargeCall = action.payload;
        },
        updateFirstRechargeVisible: (state, action) => {
            state.firstRechargeVisible = action.payload;
        },
        updateCheckinVisible: (state, action) => {
            state.checkinVisible = action.payload;
        },
        updateInitCheck: (state, action) => {
            state.initCheck = action.payload;
        },
        updateHotGirls: (state, action) => {
            state.hotGirls = action.payload;
        },
        updateDeveceVisible: (state, action) => {
            state.deveceVisible = action.payload;
        },
        updateUpdateVisible: (state, action) => {
            state.updateVisible = action.payload;
        },

    },
});

export default slice.reducer

export const updateBadge = (payload: number) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateBadge(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateRechargeVisible = (payload: boolean) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateRechargeVisible(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateRechargeCall = (payload: {
    visible: boolean,
    userInfo: any,
    expireDate?: Date
}) => async (dispatch: (arg0: {
    payload: {
        visible: boolean,
        userInfo: any,
        expireDate?: Date
    }; type: string;
}) => void) => {
        try {
            dispatch(slice.actions.updateRechargeCall(payload));
        } catch (e) {
            return console.error((e as any).message);
        }
    }

export const updateFirstRechargeVisible = (payload: boolean) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateFirstRechargeVisible(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateCheckinVisible = (payload: boolean) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateCheckinVisible(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateInitCheck = (payload: boolean) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateInitCheck(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}
export const updateHotGirlsRemote = () => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        let list = await api.getAnchorList({
            page: 1,
            per_page: 4,
            "filter[quick]": "hot"
        })
        dispatch(slice.actions.updateHotGirls(list));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateHotGirls = (payload: any) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateHotGirls(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateDeveceVisible = (payload: any) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateDeveceVisible(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}

export const updateUpdateVisible = (payload: any) => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(slice.actions.updateUpdateVisible(payload));
    } catch (e) {
        return console.error((e as any).message);
    }
}
