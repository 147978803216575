import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, List } from 'components';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { api } from 'server';
import { PagePath, CallHistory, CallEndReason } from 'models';
import { call } from 'server';
import { Calculator } from 'utils';
import './CallHistoryPage.scss';

const CallHistoryPage = React.memo(() => {
    const onFetch = (page: number, pageSize: number, startFetch: (data: CallHistory[]) => void, abortFetch: (error?: Error) => void) => {
        api.callList({
            per_page: pageSize,
            page: page
        }).then((items) => {
            startFetch(items)
        }).catch(() => {
            abortFetch()
        })
    }
    return (
        <List
            className='list'
            onFetch={onFetch}
            renderItem={(item, index) => <CallHistoryListCellItem key={index} item={item} ></CallHistoryListCellItem>}
        ></List>
    );
})

const CallHistoryListCellItem = React.memo((props: { item: CallHistory }) => {
    const { item } = props
    const hangup = item.end_reason === CallEndReason.caller_canceled_call || item.end_reason === CallEndReason.called_party_missed || item.end_reason === CallEndReason.called_party_refused
    const navigate = useNavigate()
    return (
        <div className='history-list-cell-item'>
            <div className='touch touch-soft-highlight' onClick={() => navigate(PagePath.anchor_detail + `/${item.other_people.id}`)}></div>
            <Avatar className='avatar' src={item.other_people.profile.head}></Avatar>
            <div className='main-wrapper'>
                <p className='nickname'>{item.other_people.profile.nickname}</p>
                <div className='time'>
                    <div className={classNames('call-icon', hangup === true ? 'hangup' : 'dial')}></div>
                    <p className='time-label'>{dayjs((item.started_at ?? 0) * 1000).format(Calculator.defaultDateFormat)}</p>
                </div>
            </div>
            <div className='call-button touch-opacity' onClick={() => item.id && call.handleCall(item.other_people.id)}></div>
        </div >
    )
})

export default CallHistoryPage;