import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Header, select, Avatar } from 'components';
import { PagePath } from 'models';
import { useEdit } from 'hooks';
import './EditPage.scss';

const EditPage = React.memo(() => {
    const { t } = useTranslation()
    const { handleAddAvatar } = useEdit()
    const account = useSelector(state => (state as any).account).value
    const hair_color = account.profile.attrs.hair_color
    const body_type = account.profile.attrs.body_type
    const belong = account.profile.attrs.belong
    const constellation = account.profile.attrs.constellation
    const hairLabel = typeof hair_color === 'string' ? select.hairOptions.find((item) => { return hair_color && item.value.toLowerCase() === hair_color.toLowerCase() })?.label : undefined
    const bodyLabel = typeof body_type === 'string' ? select.bodyOptions.find((item) => { return body_type && item.value.toLowerCase() === body_type.toLowerCase() })?.label : undefined
    const belongLabel = typeof belong === 'string' ? select.belongOptions.find((item) => { return belong && item.value.toLowerCase() === belong.toLowerCase() })?.label : undefined
    const constellationLabel = typeof constellation === 'string' ? select.constellationOptions.find((item) => { return item.value.toLowerCase() === constellation.toLowerCase() })?.label : undefined
    return (
        <div className='edit-page'>
            <Header title={t('edit.title')}></Header>
            <div className='avatar-wrapper'>
                <input type="file" name="file" id="avatar-file" accept="image/*" className='picker' onChange={() => handleAddAvatar()} />
                <div className='avatar-main'>
                    <Avatar className='selected-avatar' src={account.profile.head}></Avatar>
                    <div className='avatar-camera'></div>
                </div>
                <div className='avatar-change-button'>{t('edit.avatar.change-button')}</div>
            </div>
            <div className='section-label'>{t('edit.section-basic')}</div>
            <EditCellItem to={PagePath.accounts_edit_nickname} classType='nickname' title={t('personal.nickname')} placeholder={t('edit.personal.nickname.placeholder')} content={account.profile.nickname}></EditCellItem>
            <EditCellItem to={PagePath.accounts_edit_age} classType='age' title={t('personal.age')} placeholder={t('edit.personal.age.placeholder')} content={`${account.profile.age >= 18 ? account.profile.age : ''}`}></EditCellItem>
            <EditCellItem to={PagePath.accounts_edit_about} classType='about' title={t('personal.about')} placeholder={t('edit.personal.about.placeholder')} content={account.profile.mood}></EditCellItem>
            <div className='section-label'>{t('edit.section-personal')}</div>
            <EditCellItem to={PagePath.accounts_edit_height} classType='height' title={t('personal.height')} placeholder={t('edit.personal.height.placeholder')} content={`${account.profile.attrs.height && account.profile.attrs.height !== undefined ? account.profile.attrs.height + "cm" : ''}`}></EditCellItem>
            <EditCellItem to={PagePath.accounts_edit_weight} classType='weight' title={t('personal.weight')} placeholder={t('edit.personal.weight.placeholder')} content={`${account.profile.attrs.weight && account.profile.attrs.weight !== undefined ? account.profile.attrs.weight + "kg" : ''}`}></EditCellItem>
            <EditCellItem to={PagePath.accounts_edit_hair} classType='hair' title={t('personal.hair')} placeholder={t('edit.personal.hair.placeholder')} content={hairLabel}></EditCellItem>
            <EditCellItem to={PagePath.accounts_edit_body} classType='body' title={t('personal.body')} placeholder={t('edit.personal.body.placeholder')} content={bodyLabel}></EditCellItem>
            <EditCellItem to={PagePath.accounts_edit_belong} classType='belong' title={t('personal.belong')} placeholder={t('edit.personal.belong.placeholder')} content={belongLabel}></EditCellItem>
            <EditCellItem to={PagePath.accounts_edit_constellation} classType='constellation' title={t('personal.constellation')} placeholder={t('edit.personal.constellation.placeholder')} content={constellationLabel}></EditCellItem>
        </div>
    );
})


const EditCellItem = React.memo((props: {
    title?: string;
    placeholder?: string;
    content?: string;
    classType?: string;
    to: string;
}) => {
    const hasValue = props.content && props.content.length > 0
    return (
        <Link to={props.to} className='edit-cell-item touch-soft-highlight'>
            <div className={classNames('icon', props.classType)}></div>
            <div className='title-wrapper'>
                <div className='title-label'>{props.title}</div>
                <div className={classNames('content-label', !hasValue && 'placeholder')}>{hasValue ? props.content : props.placeholder}</div>
            </div>
            <div className='arrow'></div>
        </Link>
    )
})

export default EditPage;