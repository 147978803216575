import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Recharge, PagePath } from 'models';
import { LiveWebAdapter } from 'utils';
import { ListErrorView, ListLoadingView } from 'components';
import { NativeMessageType } from 'models'
import { updateRechargeListRemote } from 'store/rechargeList'
import { analysisLogEvent, AnalysisEventName } from 'analysis';
import { updateUpdateVisible } from 'store/app';
import store from 'store'
import './RechargePage.scss';
import classNames from 'classnames';
import config from 'config';

const RechargePage = React.memo(() => {
    const { t } = useTranslation()
    const account = useSelector(state => (state as any).account).value
    const list = useSelector(state => (state as any).rechargeList).value as Recharge[]
    const status = useSelector(state => (state as any).rechargeList).state as 'loading' | 'error' | 'clear'
    const bindEmailRight = useSelector(state => (state as any).right).bindEmailRight
    const navigate = useNavigate()

    useEffect(() => {
        const status = store.getState().rechargeList.state as any as 'loading' | 'error' | 'clear'
        if (status === 'error') {
            updateRechargeListRemote()(store.dispatch)
        }
        analysisLogEvent(AnalysisEventName.recharge_list_enter)
    }, [])

    return (
        <div className='recharge-page'>
            <div className='header'>
                <div className='title'>{t('recharge.title')}</div>
                <div className='header-token-view'>
                    <div className='token-icon'></div>
                    <div className='token-label'>{account.balance ?? 0}</div>
                </div>
            </div>
            {
                status === 'loading' ?
                    <ListLoadingView />
                    :
                    (
                        status === 'error' ?
                            <ListErrorView></ListErrorView>
                            :
                            <>
                                <div className='banner' id='abs'>
                                    <div className='banner-image'></div>
                                    <div className='banner-header'>
                                        <div className='shop'></div>
                                        <div className='banner-title'>{t('recharge.headline')}</div>
                                    </div>
                                    <Link className='record touch-opacity' to={PagePath.finance_expenses_token} onClick={() => analysisLogEvent(AnalysisEventName.recharge_list_record_click)}>{t('recharge.record')}</Link>
                                </div>
                                <div className='list'>
                                    {
                                        list.map((item, index) => {
                                            return (
                                                <RechargeItemView item={item} key={item.code} index={index} />
                                            )
                                        })
                                    }
                                </div>
                            </>
                    )
            }
            {
                status === 'clear' && bindEmailRight && bindEmailRight.is_used === false &&
                <div className='earn-container'>
                    <div className='earn-title'>
                        <div className='line'></div>
                        <div className='label'>{t('recharge.earn-title')}</div>
                        <div className='line'></div>
                    </div>
                    <div className='bind-email-item-view touch-without-feedback' onClick={async () => {
                        navigate(PagePath.accounts_signup)
                    }}>
                        <div className='content'>
                            <div className={'icon-view'}></div>
                            <div className='token-view'>
                                <div className='token-icon'></div>
                                <div className='token-label'>{bindEmailRight.right.desc.token_amount}</div>
                            </div>
                            <div className='bind-title'>{t('recharge.bind-title')}</div>
                            <div className='go-button'>{t('recharge.bind-button')}</div>
                        </div>
                    </div>
                </div>
            }
            <div style={{ height: '64px' }}></div>
        </div>
    );
})


const RechargeItemView = React.memo((props: {
    item: Recharge,
    index: number
}) => {
    const { t } = useTranslation()
    const { item } = props
    let extraToken = useMemo(() => {
        let original_token_amount = Number(item.original_token_amount)
        let token_amount = Number(item.token_amount)
        if (original_token_amount && token_amount) {
            return token_amount - original_token_amount
        }
        return 0
    }, [item])
    let level = useMemo(() => {
        if (item.token_amount) {
            if (item.token_amount >= 14000) {
                return 7
            }
            if (item.token_amount >= 6900) {
                return 6
            }
            if (item.token_amount >= 3700) {
                return 5
            }
            if (item.token_amount >= 2800) {
                return 4
            }
            if (item.token_amount >= 1200) {
                return 3
            }
            if (item.token_amount >= 600) {
                return 2
            }
        }
        return 1
    }, [item])

    return (
        <div className='recharge-item-view touch-without-feedback' onClick={() => {
            analysisLogEvent(AnalysisEventName.recharge_list_item_click)
            if (config.needUpdate === true) {
                updateUpdateVisible(true)(store.dispatch)
            } else {
                LiveWebAdapter.postMessage({
                    type: NativeMessageType.recharge,
                    content: item
                })
            }
        }}>
            <div className='content'>
                <div className={classNames('token-icon', `level-${level}`)}></div>
                <div className='token-label'>{(item.token_amount ?? 0) - extraToken}</div>
                <div className='price'>${item.price}</div>
                {extraToken && extraToken > 0 ? <div className='extra-token'>{t('recharge.extra')} {extraToken}</div> : <></>}
            </div>
        </div>
    )
})

export default RechargePage;