import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useMatch } from "react-router-dom";
import { PagePath } from 'models';
import classNames from 'classnames';
import { analysisLogEvent, AnalysisEventName } from 'analysis';
import './Tab.scss';

const Tab = React.memo(() => {
    const app = useSelector(state => (state as any).app)
    const account = useSelector(state => (state as any).account.value)
    const { t } = useTranslation()

    const matchExplore = useMatch(PagePath.anchor_explore)
    const matchRoot = useMatch(PagePath.root)
    const matchHotFollowing = useMatch(PagePath.anchor_hot_following)
    const matchHotNew = useMatch(PagePath.anchor_hot_new)
    const matchHotTop = useMatch(PagePath.anchor_hot_top)
    const matchHot = matchHotFollowing || matchHotNew || matchHotTop
    const matchInbox = useMatch(PagePath.message_inbox)
    const matchCallHistory = useMatch(PagePath.message_call_history) != null;
    const matchRecharge = useMatch(PagePath.finance_recharge)
    const matchProfile = useMatch(PagePath.accounts_profile)

    useEffect(() => {
        if (matchExplore || matchRoot) {
            analysisLogEvent(AnalysisEventName.tabbar_explore_click)
        } else if (matchRecharge) {
            analysisLogEvent(AnalysisEventName.tabbar_shop_click)
        } else if (matchInbox || matchCallHistory) {
            analysisLogEvent(AnalysisEventName.tabbar_inbox_click)
        } else if (matchProfile) {
            analysisLogEvent(AnalysisEventName.tabbar_profile_click)
        } else if (matchHot) {
            analysisLogEvent(AnalysisEventName.tabbar_hot_click)
        }
    }, [matchExplore, matchInbox, matchProfile, matchRecharge, matchRoot, matchHot])

    return (
        <div className='tab-component'>
            <Link to={PagePath.root} className={classNames('tab', 'touch-without-feedback', (matchExplore || matchRoot) && 'active')}>
                <div className='icon explore'></div>
                <span className='text'>{t('tab.explore')}</span>
            </Link>
            <Link to={PagePath.anchor_hot_following} className={classNames('tab', 'touch-without-feedback', matchHot && 'active')}>
                <div className='icon hot'></div>
                <span className='text'>{t('tab.hot')}</span>
            </Link>
            <Link to={PagePath.finance_recharge} className={classNames('tab', 'touch-without-feedback', matchRecharge && 'active')}>
                <div className='icon recharge'></div>
                <span className='text'>{t('tab.recharge')}</span>
            </Link>
            <Link to={PagePath.message_inbox} className={classNames('tab', 'touch-without-feedback', (matchInbox || matchCallHistory) && 'active')}>
                <div className='icon inbox'></div>
                <span className='text'>{t('tab.inbox')}</span>
                {app.badge > 0 ? <div className='badge'>{app.badge}</div> : <></>}
            </Link>
            <Link to={PagePath.accounts_profile} className={classNames('tab', 'touch-without-feedback', matchProfile && 'active')}>
                <div className='icon profile'></div>
                <span className='text'>{t('tab.profile')}</span>
                {account && account.email ? <></> : <div className='badge-cirlce'></div>}
            </Link>
        </div>
    )
})

export default Tab;