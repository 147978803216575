enum pathname {
    // 系统入口检查
    init_check = "/api/init",
    // 登录
    login = "/api/login",
    // 邮箱
    email = "/api/email",
    // 上传
    upload = "/api/upload",
    // 用户
    user = "/api/user",
    // 预登陆
    login_pre = "/api/login/pre",
    // 用户详情
    user_detail = "/api/user/detail",
    // 批量获取用户
    user_summary_list = "/api/user/summary/list",
    // 注销
    logout = "/api/logout",
    // 黑名单
    user_black_list = "/api/user/black/list",
    // 取消拉黑
    user_unblock = "/api/user/unblock",
    // 拉黑
    user_block = "/api/user/block",
    // 消费记录
    user_expends = "/api/user/expends",
    // 谁关注我
    user_followers = "/api/user/followers",
    // 我关注谁
    user_followings = "/api/user/followings",
    // 取消关注
    user_unfollow = "/api/user/unfollow",
    // 关注
    user_follow = "/api/user/follow",
    /// 我看过谁
    user_views = "/api/user/views",
    /// 用户充值
    user_token = "/api/user/token",
    // 通话记录
    call_list = "/api/call/list",
    // 反馈
    feedback = "/api/feedback",
    // 举报
    user_report = "/api/user/report",
    /// 推送绑定
    pusher_bind = "/api/pusher/bind",
    /// 挂断
    call_hangup = "/api/call/hangup",
    /// 接听
    call_answer = "/api/call/answer",
    /// 评价通话
    call_comment = "/api/call/comment",
    /// 通话
    call = "/api/call",
    /// 礼物列表
    gift_list = "/api/gift/list",
    /// 赠送
    call_gift = "/api/call/gift",
    /// 获取聊天室凭证
    chat_token = "/api/chat/token",
    /// 获取消息列表
    chat_message_list = "/api/chat/message/list",
    /// 消息
    chat_message = "/api/chat/message",
    /// 客服
    support = "/api/support",
    /// 主播列表
    anchor_list = "/api/anchor/list",
    /// 充值列表
    token_list = "/api/token/list",
    /// 权益
    right = "/api/right",
    /// 享受权益
    right_apply = "/api/right/apply",
    /// 排行榜
    rank = "/api/rank",
    /// 签到
    checkin = "/api/checkin",
    /// 广告列表
    ad_list = "/api/ad/list",
    /// 翻译
    trans = "/api/trans",
    /// 全局视频列表
    video_list = "/api/video/list"
}

export default pathname;