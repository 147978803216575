import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import { PagePath } from 'models';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HotFollowingPage from './page/HotFollowingPage';
import HotNewPage from './page/HotNewPage';
import HotTopPage from './page/HotTopPage';
import { analysisLogEvent, AnalysisEventName } from 'analysis';
import './HotPage.scss';

const HotPage = React.memo(() => {
    const { t } = useTranslation()
    const account = useSelector(state => (state as any).account).value
    const matchHotFollowing = useMatch(PagePath.anchor_hot_following)
    const matchHotNew = useMatch(PagePath.anchor_hot_new)
    const matchHotTop = useMatch(PagePath.anchor_hot_top)

    React.useEffect(() => {
        if (matchHotFollowing) {
            analysisLogEvent(AnalysisEventName.hot_following_click)
        } else if (matchHotNew) {
            analysisLogEvent(AnalysisEventName.hot_new_click)
        } else if (matchHotTop) {
            analysisLogEvent(AnalysisEventName.hot_top_click)
        }
    }, [matchHotFollowing, matchHotNew, matchHotTop])

    return (
        <div className='hot-page'>
            <div className='hot-header'>
                <div className='hot-header-content'>
                    <div className='segmented-control'>
                        <Link className={classNames('item', 'touch-opacity', matchHotFollowing && 'active')} to={PagePath.anchor_hot_following} replace>
                            <span className='label'>{t('hot.following')}</span>
                        </Link>
                        <Link className={classNames('item', 'touch-opacity', matchHotNew && 'active')} to={PagePath.anchor_hot_new} replace >
                            <span className='label'>{t('hot.new')}</span>
                        </Link>
                        <Link className={classNames('item', 'touch-opacity', matchHotTop && 'active')} to={PagePath.anchor_hot_top} replace >
                            <span className='label'>{t('hot.top')}</span>
                        </Link>
                    </div>
                    <Link className='header-token-view touch-without-feedback' to={PagePath.finance_recharge}>
                        <div className='token-icon'></div>
                        <div className='token-label'>{account.balance ?? 0}</div>
                        <div className='add-icon'></div>
                    </Link>
                    <Link className='rank-button touch-opacity' to={PagePath.anchor_ranking_anchor + "/week"}></Link>
                </div>
            </div>
            {matchHotFollowing && <HotFollowingPage></HotFollowingPage>}
            {matchHotNew && <HotNewPage></HotNewPage>}
            {matchHotTop && <HotTopPage></HotTopPage>}
        </div>
    )
})

export default HotPage;