

import { GlobalEvent, WebSocketEvent, WebSocketCode, Account } from 'models';
import { NotificationCenter } from 'utils';
import store from 'store';
import { updateAccountRemote, updateAccount } from 'store/account';

const app = {
    keep: () => {
        // 监听ws事件
        NotificationCenter.addObserver(GlobalEvent.websocket, (value) => {
            let event = (value as any).detail as WebSocketEvent
            // 监听用户信息变化
            if (event.event_code === WebSocketCode.userUpdate) {
                updateAccountRemote()(store.dispatch)
            }
            // 监听余额变更
            if (event.event_code === WebSocketCode.balance && event.data && event.data["balance"]) {
                let balance = event.data["balance"] as number
                let account = (store.getState().account.value as any) as Account
                let newAccount = { ...account }
                newAccount.balance = balance
                updateAccount(newAccount)(store.dispatch)
            }
        })
        let media = window.matchMedia('(prefers-color-scheme: dark)');
        let callback = (e: any) => {
            let prefersDarkMode = e.matches;
            document.documentElement.setAttribute("data-theme", prefersDarkMode ? "dark" : "light")
        };
        document.documentElement.setAttribute("data-theme", media.matches ? "dark" : "light")
        if (typeof media.addEventListener === 'function') {
            media.addEventListener('change', callback);
        } else if (typeof media.addListener === 'function') {
            media.addListener(callback);
        }
    }
}

export default app;