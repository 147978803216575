import React, { useEffect, useRef } from 'react';
import { usePage, useFirstRecharge } from "hooks";
import { useSelector } from 'react-redux';
import lottie from 'lottie-web';
import { updateFirstRechargeVisible } from 'store/app';
import { Calculator } from 'utils';
import store from 'store';
import './FirstRechargeButton.scss'

const FristRechargeButton = React.memo(() => {
    const { matchTab } = usePage()
    const firstRechargeRight = useSelector(state => (state as any).right).firstRechargeRight
    if (!firstRechargeRight || !matchTab || !Calculator.checkAvailabled(firstRechargeRight)) {
        return <></>
    }
    return (
        <>
            <FristRechargeContentButton></FristRechargeContentButton>
        </>
    )
})

const FristRechargeContentButton = React.memo(() => {
    const lottieRef = useRef(null)
    const { timeObj, firstRechargeRight } = useFirstRecharge()
    const right = firstRechargeRight && firstRechargeRight.right

    useEffect(() => {
        let element = lottieRef.current as any
        lottie.loadAnimation({
            container: element,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../../assets/lottie/first-recharge/data.json'),
        });
    }, [lottieRef])

    if (!right) {
        return <></>
    }

    return (
        <div className="first-recharge-button touch-without-feedback" ref={lottieRef} onClick={() => updateFirstRechargeVisible(true)(store.dispatch)}>
            {/* <div className='time-label-container'>
                <div className='time-label'>{timeObj.hour}:{timeObj.minute}:{timeObj.second}</div>
            </div> */}
        </div>
    )
})

export default FristRechargeButton