import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Spinner } from 'components';
import { PagePath } from 'models';
import { useWelcome } from 'hooks';
import { analysisLogEvent, AnalysisEventName } from 'analysis/index';
import './WelcomePage.scss';

const WelcomePage = React.memo(() => {
  const { t } = useTranslation()
  const lottieRef = useRef(null)
  const { guest, initCheck, handleSiginWithGuest } = useWelcome()

  useEffect(() => {
    let element = lottieRef.current as any
    lottie.loadAnimation({
      container: element,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../../assets/lottie/welcome/video.json'),
      rendererSettings: {
        filterSize: {
          width: '100%',
          height: '100%',
          x: '0%',
          y: '0%',
        },
        preserveAspectRatio: 'xMaxYMid slice'
      }
    });
  }, [lottieRef])

  return (
    <div className='welcome-page'>
      <div className='lottie-container' ref={lottieRef}></div>
      <div className='title-label'>{t('welcome.title')}</div>
      <div className='title-message'>{t('welcome.message')}</div>
      {
        (initCheck !== true || guest === undefined) ?
          <div className='signup-button'><Spinner color='#fff'></Spinner></div>
          :
          (
            guest === true ?
              <div className='signup-button touch-highlight' onClick={() => { handleSiginWithGuest() }}>{t('welcome.start-button')}</div>
              :
              <Link to={PagePath.accounts_signup} className='signup-button touch-highlight' onClick={() => { analysisLogEvent(AnalysisEventName.account_start_signup_click) }}>{t('welcome.signup-button')}</Link>
          )
      }
      <Link to={PagePath.accounts_login} className='login-button touch-highlight' onClick={() => { analysisLogEvent(AnalysisEventName.account_start_signin_click) }}>{t('welcome.login-button')}</Link>
      <div className='legal-label'>{t('welcome.legal')} <Link to={PagePath.legal_terms_of_service} className='underline touch-opacity'>{t('welcome.legal-terms-of-service')}</Link> & <Link to={PagePath.legal_privacy_policy} className='touch-opacity underline'>{t('welcome.legal-privacy-policy')}</Link></div>
    </div>
  );
})

export default WelcomePage;